import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./languages/en.json";
import it from "./languages/it.json";

export type Languages = "it" | "en";
export const defaultNS = "default";

export const resources = {
	en,
	it,
} satisfies Record<Languages, unknown>;

export async function initI18n(): Promise<void> {
	await i18n
		.use(initReactI18next) // passes i18n down to react-i18next
		.init({
			lng: "en",
			supportedLngs: ["it", "en"],
			fallbackLng: ["it", "en"],
			resources,
			interpolation: {
				escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
			},
		});
}

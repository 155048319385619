import type { InstrumentInsightsItem } from "$root/api/api-gen";
import { CommentaryTemplateControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { LeavePromptBase } from "$root/components/LeavePrompt";
import RoundedButton from "$root/components/RoundedButton";
import { SideBarContent } from "$root/pages/PortfolioStudioSettings/ReportEditor/report-components";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { FormController } from "$root/third-party-integrations/react-hook-form";
import { FormFields } from "$root/ui-lib/form/FormFields";
import { customObjectValuesFn } from "$root/utils/experimental";
import { useQueryNoRefetch } from "$root/utils/react-query";
import {
	Badge,
	Button,
	Controller,
	Dialog,
	DialogFooter,
	DialogHeader,
	Icon,
	Slider,
	Text,
	TextArea,
} from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { nullary } from "@mdotm/mdotui/utils";
import type { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { AllowedModules, StableConfiguration } from "./common";

type CommonModuleProps<K extends AllowedModules> = {
	onCancel(): void;
	module: StableConfiguration[K];
	onChange(module: StableConfiguration[K]): void;
};
const activerRoundedButtonClass = `!bg-[${themeCSSVars.palette_P100}] !text-[${themeCSSVars.palette_P600}]`;
export function ModuleStylingBlock(props: {
	description: string | undefined;
	onChange(description?: string): void;
}): JSX.Element {
	const commentaryTemplateApi = useApiGen(CommentaryTemplateControllerApiFactory);
	const { isFetching, data } = useQueryNoRefetch(["loadModuleStyling"], {
		queryFn: () => axiosExtract(commentaryTemplateApi.getModuleStyling()),
	});
	return (
		<>
			<div className="flex gap-2 items-center mb-2">
				<Text type="Body/L/Bold" as="p">
					Module Styling
				</Text>
				<Badge size="x-small" color="white" backgroundColor={themeCSSVars.palette_P500}>
					Beta
				</Badge>
			</div>

			<div className="flex space-x-2 mb-2">
				<RoundedButton
					size="x-small"
					disabled={isFetching || data === undefined}
					onClick={() => props.onChange(props.description === data?.max40Words ? undefined : data?.max40Words)}
					classList={{ [activerRoundedButtonClass]: props.description === data?.max40Words }}
				>
					Summarize
				</RoundedButton>
				<RoundedButton
					size="x-small"
					disabled={isFetching || data === undefined}
					onClick={() => props.onChange(props.description === data?.max80Words ? undefined : data?.max80Words)}
					classList={{ [activerRoundedButtonClass]: props.description === data?.max80Words }}
				>
					Extended
				</RoundedButton>
				<RoundedButton
					size="x-small"
					disabled={isFetching || data === undefined}
					onClick={() => props.onChange(props.description === data?.tableFormat ? undefined : data?.tableFormat)}
					classList={{ [activerRoundedButtonClass]: props.description === data?.tableFormat }}
				>
					Table format
				</RoundedButton>
				<RoundedButton
					size="x-small"
					onClick={() => props.onChange(props.description === "" ? undefined : "")}
					classList={{
						[activerRoundedButtonClass]:
							props.description === undefined || data === undefined
								? false
								: props.description === "" || customObjectValuesFn(data).every((x) => x !== props.description),
					}}
				>
					Custom prompt
				</RoundedButton>
			</div>
			<Controller value={props.description} onChange={props.onChange} key={props.description}>
				{({ onChange, onCommit, value }) => (
					<TextArea
						rows={4}
						classList={{
							"[&>textarea]:resize-none overflow-y-auto": true,
						}}
						value={value}
						onChangeText={onChange}
						onBlur={nullary(onCommit)}
					/>
				)}
			</Controller>
		</>
	);
}

function LeavePromptModule(props: { isDirty: boolean; children: ReactNode; onCancel?(): void }) {
	const { t } = useTranslation();
	return (
		<>
			<LeavePromptBase when={props.isDirty} pathToNotBlock={["/login"]}>
				{({ onCancelNavigation, onContinueNavigation, showPrompt }) => (
					<Dialog
						show={showPrompt}
						onClose={onCancelNavigation}
						header={
							<DialogHeader
								icon={<Icon icon="Icon-full-alert" color={themeCSSVars.MessageSeverity_warning} size={22} />}
							>
								You are about to leave the module customization
							</DialogHeader>
						}
						footer={
							<DialogFooter
								primaryAction={
									<Button
										onClick={() => {
											onContinueNavigation();
											props.onCancel?.();
										}}
										palette="primary"
									>
										{t("LEAVE")}
									</Button>
								}
								neutralAction={
									<Button onClick={onCancelNavigation} palette="tertiary">
										{t("STAY")}
									</Button>
								}
							/>
						}
					>
						Are your sure want to leave the module? All unsaved changes will be lost.
					</Dialog>
				)}
			</LeavePromptBase>
			{props.children}
		</>
	);
}

export function MarketOulookModule(props: CommonModuleProps<"marketOutlook">): JSX.Element {
	const { handleSubmit, formState, watch, setValue } = useForm({
		defaultValues: props.module,
	});

	return (
		<LeavePromptModule isDirty={formState.isDirty} onCancel={props.onCancel}>
			<SideBarContent
				title="#Market outlook"
				onSubmit={handleSubmit((params) =>
					props.onChange({
						...params,
						enabled: true,
					}),
				)}
				onCancel={props.onCancel}
			>
				<ModuleStylingBlock
					description={watch("moduleStyling")}
					onChange={(modules) => setValue("moduleStyling", modules, { shouldDirty: true })}
				/>
			</SideBarContent>
		</LeavePromptModule>
	);
}

export function PortfolioDynamicModule(props: CommonModuleProps<"dynamicsBlock">): JSX.Element {
	const { control, handleSubmit, formState, watch, setValue } = useForm({
		defaultValues: props.module,
	});

	return (
		<LeavePromptModule isDirty={formState.isDirty} onCancel={props.onCancel}>
			<SideBarContent
				title="#Portfolio dynamics"
				onSubmit={handleSubmit((params) =>
					props.onChange({
						...params,
						enabled: true,
					}),
				)}
				onCancel={props.onCancel}
			>
				<Text type="Body/M/Bold" as="p">
					Relevance threshold for for delta Micro Asset Class
				</Text>
				{/* <FormFields.Checkbox control={control} formState={formState} name="mainInstrument" classList="mb-1">
					Relevance threshold for for delta Micro Asset Class
				</FormFields.Checkbox> */}
				<FormController
					name="relevanceThreshold"
					control={control}
					render={({ field: { ref, ...controllerProps } }) => (
						<Slider
							min={0}
							max={100}
							step={0.01}
							disabled={!watch("mainInstrument")}
							{...controllerProps}
							input={{
								icon: "Percentile",
								innerRef: ref,
							}}
						/>
					)}
				/>
				<div className={`mt-4 pt-4 -mx-4 border-t border-solid border-t-[color:${themeCSSVars.palette_N100}]`}>
					<div className="px-4">
						<ModuleStylingBlock
							description={watch("moduleStyling")}
							onChange={(modules) => setValue("moduleStyling", modules, { shouldDirty: true })}
						/>
					</div>
				</div>
			</SideBarContent>
		</LeavePromptModule>
	);
}

export function PortfolioCompositionModule(props: CommonModuleProps<"portfolioCompositionBlock">): JSX.Element {
	const { control, handleSubmit, formState, watch, setValue } = useForm({
		defaultValues: props.module,
	});

	return (
		<LeavePromptModule isDirty={formState.isDirty} onCancel={props.onCancel}>
			<SideBarContent
				title="#Portfolio Composition"
				onSubmit={handleSubmit((params) =>
					props.onChange({
						...params,
						enabled: true,
					}),
				)}
				onCancel={props.onCancel}
			>
				<Text type="Body/M/Bold" classList="mb-4" as="p">
					Content customization
				</Text>
				<FormFields.Checkbox control={control} formState={formState} name="commentOnBenchmark" classList="mb-1">
					Comment the benchmark
				</FormFields.Checkbox>
				<FormFields.Checkbox control={control} formState={formState} name="commentConstraint" classList="mb-1">
					Comment Asset Class constraints
				</FormFields.Checkbox>
				<FormFields.Checkbox
					control={control}
					formState={formState}
					name="showMainInstrumentForMacroAc"
					classList="mb-1"
				>
					Highlight main instrument for macro Asset Class
				</FormFields.Checkbox>

				<Text type="Body/M/Bold" classList="mt-4" color={themeCSSVars.palette_N500} as="p">
					Relevance threshold for Micro Asset Class commented
				</Text>
				<FormController
					name="relevanceThresholdForMicroAcCommented"
					control={control}
					render={({ field: { ref, ...controllerProps } }) => (
						<Slider
							min={0}
							max={100}
							step={0.01}
							{...controllerProps}
							input={{
								icon: "Percentile",
								innerRef: ref,
							}}
						/>
					)}
				/>

				<div className={`mt-4 pt-4 -mx-4 border-t border-solid border-t-[color:${themeCSSVars.palette_N100}]`}>
					<div className="px-4">
						<ModuleStylingBlock
							description={watch("moduleStyling")}
							onChange={(modules) => setValue("moduleStyling", modules, { shouldDirty: true })}
						/>
					</div>
				</div>
			</SideBarContent>
		</LeavePromptModule>
	);
}

function makeInstrumentToupleRequired(data: InstrumentInsightsItem): Required<InstrumentInsightsItem> {
	return { enabled: data.enabled ?? false, threshold: data.threshold ?? 0 };
}

export function InstumentInsightsModule(props: CommonModuleProps<"instrumentInsightsBlock">): JSX.Element {
	const { control, handleSubmit, formState, watch, setValue } = useForm({
		defaultValues: {
			commentedInstrument: makeInstrumentToupleRequired(props.module.commentedInstrument),
			newInstrument: makeInstrumentToupleRequired(props.module.newInstrument),
			deletedInstrument: makeInstrumentToupleRequired(props.module.deletedInstrument),
			instrumentWithNegativeDeltaWeight: makeInstrumentToupleRequired(props.module.instrumentWithNegativeDeltaWeight),
			instrumentWithPositiveDeltaWeight: makeInstrumentToupleRequired(props.module.instrumentWithPositiveDeltaWeight),
			enabled: props.module.enabled,
			moduleStyling: props.module.moduleStyling,
		} satisfies Record<keyof typeof props.module, unknown>,
	});

	return (
		<LeavePromptModule isDirty={formState.isDirty} onCancel={props.onCancel}>
			<SideBarContent
				title="#Instrument insights"
				onSubmit={handleSubmit((params) =>
					props.onChange({
						...params,
						enabled: true,
					}),
				)}
				onCancel={props.onCancel}
			>
				<div className="flex items-center space-x-2 mb-1">
					<FormFields.Checkbox control={control} formState={formState} name="newInstrument.enabled">
						Max n. of added instruments
					</FormFields.Checkbox>
					<FormFields.NullableNumber
						name="newInstrument.threshold"
						control={control}
						label=""
						formState={formState}
						size="x-small"
						classList="w-[38px]"
					/>
				</div>

				<div className="flex items-center space-x-2 mb-1">
					<FormFields.Checkbox control={control} formState={formState} name="deletedInstrument.enabled">
						Max n. of deleted instruments
					</FormFields.Checkbox>
					<FormFields.NullableNumber
						name="deletedInstrument.threshold"
						control={control}
						label=""
						formState={formState}
						size="x-small"
						classList="w-[38px]"
					/>
				</div>

				<div className="flex items-center space-x-2 mb-1">
					<FormFields.Checkbox control={control} formState={formState} name="instrumentWithPositiveDeltaWeight.enabled">
						Max n. of instruments with positive delta weights
					</FormFields.Checkbox>
					<FormFields.NullableNumber
						name="instrumentWithPositiveDeltaWeight.threshold"
						control={control}
						label=""
						formState={formState}
						size="x-small"
						classList="w-[38px]"
					/>
				</div>

				<div className="flex items-center space-x-2 mb-1">
					<FormFields.Checkbox control={control} formState={formState} name="instrumentWithNegativeDeltaWeight.enabled">
						Max n. of instruments with negative delta weights
					</FormFields.Checkbox>
					<FormFields.NullableNumber
						name="instrumentWithNegativeDeltaWeight.threshold"
						control={control}
						label=""
						formState={formState}
						size="x-small"
						classList="w-[38px]"
					/>
				</div>

				<div className={`mt-4 pt-4 -mx-4 border-t border-solid border-t-[color:${themeCSSVars.palette_N100}]`}>
					<div className="px-4">
						<ModuleStylingBlock
							description={watch("moduleStyling")}
							onChange={(modules) => setValue("moduleStyling", modules, { shouldDirty: true })}
						/>
					</div>
				</div>
			</SideBarContent>
		</LeavePromptModule>
	);
}

export function PortfolioMetricsModule(props: CommonModuleProps<"metricsBlock">): JSX.Element {
	const { control, handleSubmit, formState, watch, setValue } = useForm({
		defaultValues: props.module,
	});
	return (
		<LeavePromptModule isDirty={formState.isDirty} onCancel={props.onCancel}>
			<SideBarContent
				title="#Portfolio metrics"
				onSubmit={handleSubmit((params) =>
					props.onChange({
						...params,
						enabled: true,
					}),
				)}
				onCancel={props.onCancel}
			>
				<Text type="Body/L/Bold" classList="mb-2" as="p">
					Content customization
				</Text>
				<FormFields.Checkbox control={control} formState={formState} name="exAnteVar" classList="mb-1">
					Ex-ante VaR 95%
				</FormFields.Checkbox>
				<FormFields.Checkbox control={control} formState={formState} name="exAnteAnnualizedVolatility" classList="mb-1">
					Ex-ante volatility 1Y
				</FormFields.Checkbox>
				<FormFields.Checkbox control={control} formState={formState} name="exAnteDiversificationRatio" classList="mb-1">
					Ex-ante diversification ratio
				</FormFields.Checkbox>

				<FormFields.Checkbox
					control={control}
					formState={formState}
					name="exAnteVolatilityContribution"
					classList="mb-1"
				>
					Ex-ante volatility contribution
				</FormFields.Checkbox>

				<FormFields.Checkbox control={control} formState={formState} name="riskBudgetOnVarVolatility" classList="mb-1">
					Risk budget on Var Volatility
				</FormFields.Checkbox>

				<FormFields.Checkbox control={control} formState={formState} name="riskBudgetTrackingError" classList="mb-1">
					Risk budget on Tracking Error
				</FormFields.Checkbox>

				{/* <FormFields.Checkbox control={control} formState={formState} name="riskConstraint" classList="mb-1">
					Risk constraint
				</FormFields.Checkbox> */}

				<FormFields.Checkbox control={control} formState={formState} name="riskFactors" classList="mb-1">
					Risk factors
				</FormFields.Checkbox>
				<FormFields.Checkbox control={control} formState={formState} name="trackingError" classList="mb-1">
					Tracking errors
				</FormFields.Checkbox>

				<FormFields.Checkbox
					control={control}
					formState={formState}
					name="varVolatilityRiskConstraint"
					classList="mb-1"
				>
					Var volatility risk constraint
				</FormFields.Checkbox>

				<div className={`mt-4 pt-4 -mx-4 border-t border-solid border-t-[color:${themeCSSVars.palette_N100}]`}>
					<div className="px-4">
						<ModuleStylingBlock
							description={watch("moduleStyling")}
							onChange={(modules) => setValue("moduleStyling", modules, { shouldDirty: true })}
						/>
					</div>
				</div>
			</SideBarContent>
		</LeavePromptModule>
	);
}

import { useTranslation } from "react-i18next";
import type { FormatDate, FormatNumber } from "$root/localization/formatters";
import { formatDate as unhookedFormatDate, formatNumber as unhookedFormatNumber } from "$root/localization/formatters";
import { useMemo } from "react";

export function useLocaleFormatters(): { locale: string; formatDate: FormatDate; formatNumber: FormatNumber } {
	const {
		i18n: { resolvedLanguage },
	} = useTranslation();
	const formatters = useMemo(() => {
		const formatDate: FormatDate = (date, opts) => {
			return unhookedFormatDate(date, { locale: resolvedLanguage, ...opts });
		};

		const formatNumber: FormatNumber = (n, decimalPlacesOrOptions) => {
			if (typeof decimalPlacesOrOptions === "number") {
				return unhookedFormatNumber(n, {
					locale: resolvedLanguage!,
					minDecimalPlaces: decimalPlacesOrOptions,
					maxDecimalPlaces: decimalPlacesOrOptions,
				});
			}
			return unhookedFormatNumber(n, { locale: resolvedLanguage, ...decimalPlacesOrOptions });
		};
		return { locale: resolvedLanguage!, formatDate, formatNumber };
	}, [resolvedLanguage]);

	return formatters;
}

import type {
	CustomMarketViewSettings,
	DefaultMarketViewSettings,
	MarketScenarioMinInfo,
	MarketViewAssetClassAlias,
	MarketViewAssetClassAliases,
	MarketViewSettingsActiveMarketViewTypesEnum,
} from "$root/api/api-gen";
import { MarketViewMicroAssetClasses, MarketViewType, PortfolioStudioPreferencesApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { LeavePrompt } from "$root/components/LeavePrompt";
import { useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import ReactQueryWrapper, { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { FormFields } from "$root/ui-lib/form/FormFields";
import { CommonItemActions } from "$root/ui-lib/interactive-collections/common-item-actions";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import type { TableColumn } from "@mdotm/mdotui/components";
import {
	AsyncButton,
	Banner,
	Button,
	Checkbox,
	CircularProgressBar,
	Dialog,
	DialogFooter,
	Form,
	FormField,
	Icon,
	Searchable,
	SubmitButton,
	Table,
	Text,
	TextInput,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { useMultiSelect, useSearchable } from "@mdotm/mdotui/headless";
import { useUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { UseQueryResult } from "@tanstack/react-query";
import { Map } from "immutable";
import { useEffect, useMemo, useState } from "react";
import { Controller as FormController, useForm } from "react-hook-form";
import { UseTranslationResponse, useTranslation } from "react-i18next";
import { z } from "zod";
import { PageCtxConsumer } from "$root/components/PageCtx";
import { createPortal } from "react-dom";

const marketViewTypeOptions = [
	{
		label: "Expected returns",
		value: MarketViewType.ExpectedReturnsVolatility,
	},
	{ label: "Under/over weight", value: MarketViewType.PositioningIndicators },
];

function fallbackMarketViewActive(
	fallback: MarketViewSettingsActiveMarketViewTypesEnum,
	enable?: boolean,
): MarketViewSettingsActiveMarketViewTypesEnum | undefined {
	return enable ? fallback : undefined;
}

function MarketViewSetting(props: { active: boolean }): JSX.Element {
	const portfolioStudioPreferenceApi = useApiGen(PortfolioStudioPreferencesApiFactory);

	const query = useQueryNoRefetch({
		queryKey: ["marketViewStudioSettings"],
		queryFn: async () => {
			/* <-- market view conf --> */
			const expectedReturnsMarketViewSetting = await axiosExtract(
				portfolioStudioPreferenceApi.getDefaultMarketViewSettings("EXPECTED_RETURNS_VOLATILITY"),
			);
			const underOverWeightViewSetting = await axiosExtract(
				portfolioStudioPreferenceApi.getDefaultMarketViewSettings("POSITIONING_INDICATORS"),
			);
			const customMaketViewSettings = await axiosExtract(portfolioStudioPreferenceApi.getCustomMarketViewSettings());
			/* <-- market view conf --> */

			/* <-- asset class alias --> */
			const marketViewAssetClassAliases = await axiosExtract(
				portfolioStudioPreferenceApi.getMarketViewAssetClassAliases(),
			);
			/* <-- asset class alias --> */

			return {
				// activeMarketViewTypes,
				underOverWeightMarketEnabled: underOverWeightViewSetting.enabled,
				expectedReturnsMarketEnabled: expectedReturnsMarketViewSetting.enabled,
				customMarketViewEnabled: customMaketViewSettings.enabled,
				customMarketViewName: customMaketViewSettings.customMarketViewName,
				customMarketViewType: customMaketViewSettings.type ?? "EXPECTED_RETURNS_VOLATILITY",
				customMarketViewScenarioIds: customMaketViewSettings.customMarketViewScenarios,
				customMarketViewMicroAssetClasses: customMaketViewSettings.assetClasses,
				expectedReturnsMarketViewMicroAssetClasses: expectedReturnsMarketViewSetting.assetClasses,
				underOverWeightMarketViewMicroAssetClasses: underOverWeightViewSetting.assetClasses,
				marketViewsAliases: marketViewAssetClassAliases.assetClassAliases,
			};
		},
	});
	return (
		<ReactQueryWrapperBase query={query}>
			{(data, query) => <MarketViewSettingInner {...data} {...props} queryUtils={query} />}
			{/* TODO: reset form after submit+refetch? */}
		</ReactQueryWrapperBase>
	);
}

type MarketViewSettingsInnerProps = {
	// activeMarketViewTypes: MarketViewSettingsActiveMarketViewTypesEnum[] | undefined;
	underOverWeightMarketEnabled: boolean | undefined;
	expectedReturnsMarketEnabled: boolean | undefined;
	customMarketViewEnabled: boolean | undefined;
	customMarketViewName: string | undefined;
	customMarketViewType: MarketViewType;
	customMarketViewScenarioIds: MarketScenarioMinInfo[] | undefined;
	customMarketViewMicroAssetClasses: MarketViewMicroAssetClasses[] | undefined;
	expectedReturnsMarketViewMicroAssetClasses: MarketViewMicroAssetClasses[] | undefined;
	underOverWeightMarketViewMicroAssetClasses: MarketViewMicroAssetClasses[] | undefined;
	marketViewsAliases: Array<MarketViewAssetClassAlias> | undefined;
};

const MarketViewSettingInner = (
	props: MarketViewSettingsInnerProps & { queryUtils: UseQueryResult<MarketViewSettingsInnerProps>; active: boolean },
): JSX.Element => {
	const { t } = useTranslation();
	const { push } = useTypedNavigation();

	const initialAliases = useMemo(
		() =>
			Map</* microAssetClass */ MarketViewMicroAssetClasses, /* alias */ string>(
				props.marketViewsAliases?.map((assetClass) => [assetClass.microAssetClass!, assetClass.alias!]),
			),
		[props.marketViewsAliases],
	);

	const portfolioStudioPreferenceApi = useApiGen(PortfolioStudioPreferencesApiFactory);

	const { control, setValue, formState, handleSubmit, watch, reset } = useForm({
		defaultValues: {
			underOverWeightMarketEnabled: props.underOverWeightMarketEnabled ?? false,
			expectedReturnsMarketEnabled: props.expectedReturnsMarketEnabled ?? false,
			customMarketViewEnabled: props.customMarketViewEnabled ?? false,
			customMarketViewName: props.customMarketViewName ?? "",
			customMarketViewType: props.customMarketViewType,
			customMarketViewScenarioIds: props.customMarketViewScenarioIds ?? [],
			customMarketViewMicroAssetClasses: props.customMarketViewMicroAssetClasses ?? [],
			expectedReturnsMarketViewMicroAssetClasses: props.expectedReturnsMarketViewMicroAssetClasses ?? [],
			underOverWeightMarketViewMicroAssetClasses: props.underOverWeightMarketViewMicroAssetClasses ?? [],
			aliases: initialAliases,
		},
		resolver: zodResolver(
			z
				.object({
					underOverWeightMarketEnabled: z.boolean(),
					expectedReturnsMarketEnabled: z.boolean(),
					customMarketViewEnabled: z.boolean(),
					customMarketViewName: z.string(),
					customMarketViewType: z.nativeEnum(MarketViewType),
					customMarketViewScenarioIds: z.array(
						z.object({
							customMarketView: z.boolean().optional(),
							forecastHorizon: z.any().optional(),
							id: z.string().optional(),
							label: z.string().optional(),
							marketViewType: z.nativeEnum(MarketViewType).optional(),
							userCustomScenario: z.boolean().optional(),
						} satisfies Record<keyof MarketScenarioMinInfo, unknown>),
					),
					customMarketViewMicroAssetClasses: z.array(z.nativeEnum(MarketViewMicroAssetClasses)),
					expectedReturnsMarketViewMicroAssetClasses: z.array(z.nativeEnum(MarketViewMicroAssetClasses)),
					underOverWeightMarketViewMicroAssetClasses: z.array(z.nativeEnum(MarketViewMicroAssetClasses)),
					aliases: z.any(),
				})
				.superRefine((settings, ctx) => {
					if (!settings.customMarketViewEnabled) {
						return;
					}
					if (!settings.customMarketViewName) {
						ctx.addIssue({
							message: "Insert a valid market view name",
							path: ["customMarketViewName"],
							code: "custom",
						});
					}
					if (settings.customMarketViewScenarioIds.length === 0) {
						ctx.addIssue({
							message: "Select a scenario",
							path: ["customMarketViewScenarioIds"],
							code: "custom",
						});
					}
					if (settings.customMarketViewMicroAssetClasses.length === 0) {
						ctx.addIssue({
							message: "Select a micro asset class",
							path: ["customMarketViewMicroAssetClasses"],
							code: "custom",
						});
					}
				}),
		),
	});

	function onSubmit() {
		return handleSubmit(async (data) => {
			if (
				formState.dirtyFields.expectedReturnsMarketViewMicroAssetClasses ||
				formState.dirtyFields.expectedReturnsMarketEnabled
			) {
				const expectedReturnsWeightPayload = {
					assetClasses: data.expectedReturnsMarketViewMicroAssetClasses,
					enabled: data.expectedReturnsMarketEnabled,
					type: MarketViewType.ExpectedReturnsVolatility,
				} satisfies DefaultMarketViewSettings;
				await portfolioStudioPreferenceApi.setDefaultMarketViewSettings(expectedReturnsWeightPayload);
			}

			if (
				formState.dirtyFields.underOverWeightMarketViewMicroAssetClasses ||
				formState.dirtyFields.underOverWeightMarketEnabled
			) {
				const underOverWeightPayload = {
					assetClasses: data.underOverWeightMarketViewMicroAssetClasses,
					enabled: data.underOverWeightMarketEnabled,
					type: MarketViewType.PositioningIndicators,
				} satisfies DefaultMarketViewSettings;
				await portfolioStudioPreferenceApi.setDefaultMarketViewSettings(underOverWeightPayload);
			}

			if (
				formState.dirtyFields.customMarketViewMicroAssetClasses ||
				formState.dirtyFields.customMarketViewName ||
				formState.dirtyFields.customMarketViewScenarioIds ||
				formState.dirtyFields.customMarketViewEnabled
			) {
				const customViewPayload = {
					assetClasses: data.customMarketViewMicroAssetClasses,
					customMarketViewName: data.customMarketViewName,
					customMarketViewScenarios: data.customMarketViewScenarioIds,
					enabled: data.customMarketViewEnabled,
					type: data.customMarketViewType,
				} satisfies CustomMarketViewSettings;
				await portfolioStudioPreferenceApi.setCustomMarketViewSettings(customViewPayload);
			}

			if (formState.dirtyFields.aliases) {
				const marketViewAssetClassAliasesPayload = {
					assetClassAliases: data.aliases.toArray().map(([microAssetClass, alias]) => ({ microAssetClass, alias })),
				} satisfies MarketViewAssetClassAliases;
				await portfolioStudioPreferenceApi.setMarketViewAssetClassAliases(marketViewAssetClassAliasesPayload);
			}

			await props.queryUtils.refetch();
			reset(data);
		}, console.log)();
	}

	return (
		<>
			<Form onSubmitAsync={() => onSubmit()}>
				<div className="grid gap-8 mb-6">
					<div className="flex gap-4">
						<div>
							<img src="/img/market-view-settings/custom.png" className="w-[136px] h-auto" alt="custom" />
						</div>
						<div className="grow">
							<div className="mb-4">
								<FormController
									control={control}
									name="expectedReturnsMarketEnabled"
									render={({ field }) => (
										<Checkbox checked={field.value} onChange={field.onChange}>
											<Text type="Body/XL/Bold" classList="mt-0.5">
												Exp. returns market view settings
											</Text>
										</Checkbox>
									)}
								/>
							</div>
							<div className="space-y-4 pl-6">
								<div className="w-full">
									<MicroAssetClassTable
										aliases={watch("aliases")}
										onBatchRename={(newAliases) => setValue("aliases", newAliases, { shouldDirty: true })}
										onSelectionChange={(newMicro) =>
											setValue("expectedReturnsMarketViewMicroAssetClasses", newMicro, { shouldDirty: true })
										}
										rows={watch("expectedReturnsMarketViewMicroAssetClasses")}
										assetClassType="EXPECTED_RETURNS_VOLATILITY"
									/>
								</div>
							</div>
						</div>
					</div>
					<hr className="h-px" style={{ backgroundColor: themeCSSVars.palette_N300 }} />
					<div className="flex gap-4">
						<div>
							<img src="/img/market-view-settings/custom.png" className="w-[136px] h-auto" alt="custom" />
						</div>
						<div className="grow">
							<div className="mb-4">
								<FormController
									control={control}
									name="underOverWeightMarketEnabled"
									render={({ field }) => (
										<Checkbox checked={field.value} onChange={field.onChange}>
											<Text type="Body/XL/Bold" classList="mt-0.5">
												Under/Over weight market view settings
											</Text>
										</Checkbox>
									)}
								/>
							</div>
							<div className="space-y-4 pl-6">
								<div className="w-full">
									<MicroAssetClassTable
										aliases={watch("aliases")}
										onBatchRename={(newAliases) => setValue("aliases", newAliases, { shouldDirty: true })}
										onSelectionChange={(newMicro) =>
											setValue("underOverWeightMarketViewMicroAssetClasses", newMicro, { shouldDirty: true })
										}
										rows={watch("underOverWeightMarketViewMicroAssetClasses")}
										assetClassType="POSITIONING_INDICATORS"
									/>
								</div>
							</div>
						</div>
					</div>
					<hr className="h-px" style={{ backgroundColor: themeCSSVars.palette_N300 }} />
					<div className="flex gap-4">
						<div>
							<img src="/img/market-view-settings/custom.png" className="w-[136px] h-auto" alt="custom" />
						</div>
						<div className="grow">
							<div className="mb-4">
								<FormController
									control={control}
									name="customMarketViewEnabled"
									render={({ field }) => (
										<Checkbox checked={field.value} onChange={field.onChange}>
											<Text type="Body/XL/Bold" classList="mt-0.5">
												Custom market view settings
											</Text>
										</Checkbox>
									)}
								/>
							</div>
							<div className="space-y-4 pl-6">
								<div className="flex flex-col space-y-4 max-w-[336px]">
									<FormFields.Text
										formState={formState}
										control={control}
										label="Custom name"
										name="customMarketViewName"
									/>
									<FormFields.Select
										formState={formState}
										control={control}
										label="Market view type"
										name="customMarketViewType"
										multi={false}
										options={marketViewTypeOptions}
										i18n={{ triggerPlaceholder: () => "Market view type" }}
										onChange={(marketViewType: MarketViewType) => {
											setValue("customMarketViewType", marketViewType);
											setValue("customMarketViewMicroAssetClasses", [], { shouldDirty: true });
											setValue("customMarketViewScenarioIds", [], { shouldDirty: true });
										}}
									/>
									<div>
										<ReactQueryWrapper
											queryKey={["getCustomMarketViewScenarioIds", watch("customMarketViewType")]}
											queryFn={() =>
												axiosExtract(
													portfolioStudioPreferenceApi.getSelectableScenarioIds(watch("customMarketViewType")),
												)
											}
										>
											{(scenarios) => {
												const options =
													scenarios.marketScenarioIds?.flatMap((scenario) =>
														!scenario.id || !scenario.label
															? []
															: [
																	{
																		value: scenario,
																		label: scenario.label,
																	},
															  ],
													) ?? [];
												return (
													<FormFields.Select
														formState={formState}
														control={control}
														label="Market view scenarios"
														name="customMarketViewScenarioIds"
														options={options}
														i18n={{ triggerPlaceholder: () => "Market view scenarios" }}
														multi
													/>
												);
											}}
										</ReactQueryWrapper>
										<p className={`text-[${themeCSSVars.palette_N200}]`}>
											Choose as many horizons as you want to be available
										</p>
									</div>
								</div>
								<div className="w-full">
									<MicroAssetClassTable
										aliases={watch("aliases")}
										onBatchRename={(newAliases) => setValue("aliases", newAliases, { shouldDirty: true })}
										onSelectionChange={(newMicro) =>
											setValue("customMarketViewMicroAssetClasses", newMicro, { shouldDirty: true })
										}
										rows={watch("customMarketViewMicroAssetClasses")}
										assetClassType={watch("customMarketViewType")}
										custom
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				{props.active && (
					<PageCtxConsumer>
						{(ctx) =>
							ctx.titleActionPortalTarget &&
							createPortal(
								<div className="flex items-center gap-2">
									<Button size="small" palette="tertiary" onClick={() => push("PortfoliosStudio", {})}>
										Cancel
									</Button>
									<AsyncButton size="small" palette="primary" onClickAsync={onSubmit}>
										Save
									</AsyncButton>
								</div>,
								ctx.titleActionPortalTarget,
							)
						}
					</PageCtxConsumer>
				)}
			</Form>
			<LeavePrompt
				title={t("NOTIFICATION_SETTINGS.LEAVE_PROMPT.TITLE")}
				when={formState.isDirty}
				pathToNotBlock={["/portfolio_studio_settings?tab=Market+view+settings"]}
				showCancelIcon={false}
			>
				{t("NOTIFICATION_SETTINGS.LEAVE_PROMPT.MESSAGE")}
			</LeavePrompt>
		</>
	);
};

const RenameMicroAssetClassDialog = (props: {
	show: boolean;
	onSubmitAsync(
		aliases: Map</* microAssetClass */ MarketViewMicroAssetClasses, /* alias */ string>,
	): MaybePromise<void>;
	aliases: Map</* microAssetClass */ MarketViewMicroAssetClasses, /* alias */ string>;
	onClose(): void;
	rows: Array<MarketViewMicroAssetClasses>;
}) => {
	const { t } = useTranslation();

	const [aliases, setAliases] = useState(props.aliases);

	useEffect(() => {
		if (props.show) {
			setAliases(props.aliases); // male resync alias on open
		}
	}, [props.aliases, props.show]);

	const {
		query: searchedMicroAssetClasses,
		setQuery: setSearchedMicroAssetClasses,
		filtered,
	} = useSearchable({
		collection: props.rows,
		matchFn: (item, query) =>
			microAssetClassMatchFn(t(`MARKET_STUDIO_SETTINGS.${item}` as any), aliases.get(item!), query),
	});

	const columns = useMemo<Array<TableColumn<MarketViewMicroAssetClasses>>>(
		() => [
			microAssetClassColumns.microAssetClass(t),
			{
				header: t("TABLE.HEADERS.ALIAS"),
				relativeWidth: 1,
				name: "alias",
				sortFn: (a, b) => ((aliases.get(a!) ?? "") > (aliases.get(b!) ?? "") ? -1 : 1),
				content: (microAssetClass) => (
					<TextInput
						classList="grow min-w-0"
						value={aliases.get(microAssetClass!) ?? ""}
						onChangeText={(alias) => setAliases(aliases.set(microAssetClass!, alias))}
					/>
				),
			},
		],
		[aliases, t],
	);

	return (
		<Dialog
			show={props.show}
			header="Rename asset classes"
			size="xxlarge"
			footer={
				<DialogFooter
					primaryAction={<SubmitButton>{t("BUTTON.DONE")}</SubmitButton>}
					neutralAction={
						<Button palette="tertiary" onClick={props.onClose}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
				/>
			}
			onSubmitAsync={() => props.onSubmitAsync(aliases)}
			onClose={props.onClose}
		>
			<Banner severity="info" classList="mb-4">
				The names you customize will also be found in the other market view
			</Banner>
			<TextInput
				leftContent={<Icon icon="Search" />}
				value={searchedMicroAssetClasses}
				onChangeText={setSearchedMicroAssetClasses}
				placeholder="Search"
				classList="mb-4"
			/>

			<Table visibleRows={8} rows={filtered} columns={columns} />
		</Dialog>
	);
};

export default MarketViewSetting;

const microAssetClassColumns = {
	microAssetClass(t: UseTranslationResponse<"en", undefined>["0"]): TableColumn<MarketViewMicroAssetClasses> {
		return {
			header: "Micro Asset Class",
			name: "microAssetClass",
			sortFn: (a, b) => (a > b ? 1 : -1),
			content: (microAssetClass) => t(`MARKET_STUDIO_SETTINGS.${microAssetClass}`),
			relativeWidth: 1,
		};
	},
};

function MicroAssetClassTable({
	rows,
	aliases,
	onSelectionChange,
	onBatchRename,
	assetClassType,
	custom,
}: {
	rows: MarketViewMicroAssetClasses[];
	onSelectionChange(microAssetClasses: MarketViewMicroAssetClasses[]): void;
	aliases: Map</* microAssetClass */ MarketViewMicroAssetClasses, /* alias */ string>;
	onBatchRename(aliases: Map</* microAssetClass */ MarketViewMicroAssetClasses, /* alias */ string>): void;
	assetClassType: MarketViewType;
	custom?: boolean;
}) {
	const { t } = useTranslation();
	const rowsRef = useUpdatedRef(rows);
	const assetClassAliasTableColumns = useMemo<Array<TableColumn<MarketViewMicroAssetClasses>>>(
		() => [
			microAssetClassColumns.microAssetClass(t),
			{
				header: "Custom Name",
				name: "alias",
				sortFn: (a, b) => ((aliases.get(a!) ?? "") > (aliases.get(b!) ?? "") ? -1 : 1),
				relativeWidth: 1,
				content: (microAssetClass) => {
					return <div className="flex-1 min-w-0 truncate">{aliases.get(microAssetClass!) ?? ""}</div>;
				},
			},
			{
				header: null,
				width: 40,
				content: (microAssetClass) => (
					<CommonItemActions.Delete
						onDelete={() =>
							onSelectionChange(rowsRef.current.filter((refMicroAssetClass) => refMicroAssetClass !== microAssetClass))
						}
					/>
				),
			},
		],
		[aliases, onSelectionChange, rowsRef, t],
	);

	const [showRenameModal, setShowRenameModal] = useState(false);
	const [showAssetClassSelectionModal, setShowAssetClassSelectionModal] = useState(false);

	const portfolioStudioPreferenceApi = useApiGen(PortfolioStudioPreferencesApiFactory);

	const instrumentsQuery = useQueryNoRefetch({
		queryKey: ["marketView", "selectableMicroAssetClass", assetClassType, custom],
		queryFn: () => axiosExtract(portfolioStudioPreferenceApi.getSelectableAssetClasses(assetClassType, custom)),
	});

	const microAssetClassSelectCtx = useMultiSelect();
	const microAssetClassSelectCtxRef = useUpdatedRef(microAssetClassSelectCtx);
	useEffect(() => {
		microAssetClassSelectCtxRef.current.actions.setSelection(rows.map((microAssetClass) => microAssetClass));
	}, [microAssetClassSelectCtxRef, rows]);

	return (
		<>
			<Searchable
				matchFn={(item, query) =>
					microAssetClassMatchFn(t(`MARKET_STUDIO_SETTINGS.${item}` as any), aliases.get(item) ?? "", query)
				}
				collection={rows}
			>
				{({ filtered, query, setQuery }) => (
					<div>
						<div className="flex justify-between items-end mb-4">
							<div>
								<FormField label="Market view asset classes">
									{(forward) => (
										<TextInput
											{...forward}
											classList="w-[336px]"
											name="microAssetClass"
											onChangeText={setQuery}
											value={query}
											placeholder="Search"
											leftContent={<Icon icon="Search" size={20} color={themeCSSVars.palette_N300} />}
										/>
									)}
								</FormField>
							</div>
							<div className="flex gap-2">
								<Button size="small" palette="secondary" onClick={() => setShowRenameModal(true)}>
									<Icon icon="Edit" size={16} />
									&nbsp;Rename
								</Button>
								<ReactQueryWrapperBase
									query={instrumentsQuery}
									loadingFallback={<CircularProgressBar value="indeterminate" outerDiameter={24} />}
								>
									{({ selectableAssetClasses }) => (
										<>
											<Button
												disabled={!selectableAssetClasses}
												size="small"
												palette="secondary"
												onClick={() => setShowAssetClassSelectionModal(true)}
											>
												<Icon icon="Outline1" size={16} />
												&nbsp;Add asset class
											</Button>

											{selectableAssetClasses && (
												<AddRemoveMicroAssetClassDialog
													rows={selectableAssetClasses}
													selected={rows.map((microAssetClass) => microAssetClass!)}
													aliases={aliases}
													onClose={() => setShowAssetClassSelectionModal(false)}
													onSubmitAsync={(selection) => {
														onSelectionChange(selection);
														setShowAssetClassSelectionModal(false);
													}}
													show={showAssetClassSelectionModal}
												/>
											)}
										</>
									)}
								</ReactQueryWrapperBase>
							</div>
						</div>
						<Table columns={assetClassAliasTableColumns} rows={filtered} visibleRows={10} />
					</div>
				)}
			</Searchable>
			<RenameMicroAssetClassDialog
				aliases={aliases}
				show={showRenameModal}
				rows={rows}
				onClose={() => setShowRenameModal(false)}
				onSubmitAsync={(newAliases) => {
					onBatchRename(newAliases);
					setShowRenameModal(false);
				}}
			/>
		</>
	);
}

const AddRemoveMicroAssetClassDialog = (props: {
	show: boolean;
	onSubmitAsync(microAssetClasses: MarketViewMicroAssetClasses[]): MaybePromise<void>;
	aliases: Map</* microAssetClass */ MarketViewMicroAssetClasses, /* alias */ string>;
	onClose(): void;
	rows: Array<MarketViewMicroAssetClasses>;
	selected: Array<MarketViewMicroAssetClasses>;
}) => {
	const { t } = useTranslation();

	const {
		query: searchedMicroAssetClasses,
		setQuery: setSearchedMicroAssetClasses,
		filtered,
	} = useSearchable({
		collection: props.rows,
		matchFn: (microAssetClass, query) =>
			microAssetClassMatchFn(
				t(`MARKET_STUDIO_SETTINGS.${microAssetClass}` as any),
				props.aliases.get(microAssetClass!),
				query,
			),
	});

	const checkboxColumnData = useSelectableTableColumn({
		rows: props.rows,
		filteredRows: filtered,
		selectBy: (microAssetClass): MarketViewMicroAssetClasses => microAssetClass!,
		mode: "checkbox",
	});

	const checkboxColumnDataMultiSelectCtxRef = useUpdatedRef(checkboxColumnData.multiSelectCtx);
	useEffect(() => {
		checkboxColumnDataMultiSelectCtxRef.current.actions.setSelection(props.selected);
	}, [checkboxColumnDataMultiSelectCtxRef, props.selected]);

	const columns = useMemo<Array<TableColumn<MarketViewMicroAssetClasses>>>(
		() => [
			checkboxColumnData.column,
			microAssetClassColumns.microAssetClass(t),
			{
				header: t("TABLE.HEADERS.ALIAS"),
				content: (microAssetClass) => props.aliases.get(microAssetClass!) ?? "",
			},
		],
		[checkboxColumnData.column, t, props.aliases],
	);

	return (
		<Dialog
			show={props.show}
			header="Select one of more instruments"
			size="xxlarge"
			footer={
				<DialogFooter
					primaryAction={<SubmitButton>{t("BUTTON.DONE")}</SubmitButton>}
					neutralAction={
						<Button palette="tertiary" onClick={props.onClose}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
				/>
			}
			onSubmitAsync={() => props.onSubmitAsync(checkboxColumnData.multiSelectCtx.data.selection.toArray())}
			onClose={props.onClose}
		>
			<TextInput
				leftContent={<Icon icon="Search" />}
				value={searchedMicroAssetClasses}
				onChangeText={setSearchedMicroAssetClasses}
				placeholder="Search your micro asset classes"
				classList="mb-4"
			/>

			<Table
				visibleRows={8}
				rows={filtered}
				columns={columns}
				onRowClick={(microAssetClass) => checkboxColumnData.toggle(microAssetClass!)}
			/>
		</Dialog>
	);
};

function microAssetClassMatchFn(microAssetClass: string | undefined, alias: string | undefined, query: string) {
	if (!query) {
		return true;
	}
	const stringifiedRow = `${microAssetClass} ${alias}`.toLowerCase();
	const words = query.toLowerCase().split(" ");
	return words.every((keys) => stringifiedRow.includes(keys));
}

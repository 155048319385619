import type {
	InvestmentMinInfo,
	InvestmentReferenceListEntry,
	InvestmentStatuses,
	RichAcl,
	UserReferenceColumnOrdering,
	UserReferenceColumnPreference,
	UserReferenceColumnPreferencePreferencesTypeEnum,
} from "$root/api/api-gen";
import {
	BenchmarksControllerApiFactory,
	IntegrationsControllerApiFactory,
	InvestmentControllerV4ApiFactory,
	PortfolioStudioPreferencesApiFactory,
} from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import type { ColumnMetadata } from "$root/components/tables-extra/CustomizeColumns";
import { CustomizeColumns } from "$root/components/tables-extra/CustomizeColumns";
import { useUpdateAccessListControlDialog } from "$root/functional-areas/acl/hook/useUpdateAccessListControlDialog";
import type { UsePerformCrudActions } from "$root/hooks/usePerformCrud";
import usePerformCrud from "$root/hooks/usePerformCrud";
import { platformToast } from "$root/notification-system/toast";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { builtInSortFnFor } from "$root/utils/collections";
import { preventSubmitOnPressEnter } from "$root/utils/experimental";
import { nullary } from "$root/utils/functions";
import { useDebouncedMemo } from "$root/utils/react-extra";
import type { DropdownMenuProps } from "@mdotm/mdotui/components";
import {
	BatchActions,
	Button,
	DropdownMenuActionButton,
	Icon,
	TableV2,
	TextInput,
	contextMenuHandler,
	sortRows,
} from "@mdotm/mdotui/components";
import type { MultiSelectCtx } from "@mdotm/mdotui/headless";
import { useMultiSelect } from "@mdotm/mdotui/headless";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { QueryObserverBaseResult } from "@tanstack/react-query";
import type { FC } from "react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CrudModal } from "..";
import { usePortfolioStudioTableSettings } from "../portfolio-studio-table-settings";
import { useReferenceColumn } from "./columns";
import { aclByArea, roleByArea } from "$root/functional-areas/acl/checkers/all";
import AuthorizationGuard from "$root/components/AuthorizationGuard";
import { validateACLPermissions } from "$root/functional-areas/acl/checkers/shared";
import { Map } from "immutable";
import { useUserValue } from "$root/functional-areas/user";
import { actionsColumn } from "$root/ui-lib/interactive-collections/common-table-actions";
import ReactQueryWrapper from "$root/components/ReactQueryWrapper";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { spawnInvestmentReferenceImportDialog } from "$root/functional-areas/portfolio/ImportReference";
import { noop } from "@mdotm/mdotui/utils";

type ReferenceListProps = {
	references: InvestmentReferenceListEntry[];
	benchmarks: InvestmentReferenceListEntry[];
	columnsPreferences?: UserReferenceColumnPreference[];
	deleteMultiSelectCtx: MultiSelectCtx<string>;
	refetch: {
		references: QueryObserverBaseResult<InvestmentReferenceListEntry[]>["refetch"];
		benchmarks: QueryObserverBaseResult<InvestmentReferenceListEntry[]>["refetch"];
		columnsPreferences: QueryObserverBaseResult<UserReferenceColumnOrdering>["refetch"];
	};
};

type GroupVariants = "All" | "Benchmark" | "References";
type ReferenceVariants = "benchmark" | "portfolio";

export type UnifiedMockedReferenceProps = {
	name?: string;
	identifier?: string;
	creationTime?: string;
	modificationTime?: string;
	status?: string;
	type: "References" | "Benchmark";
	linkedPortfolio?: InvestmentMinInfo[];
	richAcl?: RichAcl;
	nofPortfolios?: number;
};

function unifyReferenceAndBenchmarksData(
	benchmarks?: InvestmentReferenceListEntry[],
	references?: InvestmentReferenceListEntry[],
): Array<UnifiedMockedReferenceProps> {
	const mapReferences = (references ?? []).map(
		(r): UnifiedMockedReferenceProps => ({
			name: r.name,
			identifier: r.uuid,
			creationTime: r.creationTime,
			modificationTime: r.modificationTime,
			status: r.status,
			type: "References",
			linkedPortfolio: r.referralInvestments,
			richAcl: r.richAcl,
			nofPortfolios: r.nofPortfolios,
		}),
	);

	const mapBenchmarks = (benchmarks ?? []).map(
		(b): UnifiedMockedReferenceProps => ({
			name: b.name,
			identifier: b.uuid,
			creationTime: b.creationTime,
			modificationTime: b.modificationTime,
			status: b.status,
			type: "Benchmark",
			linkedPortfolio: b.referralInvestments,
			richAcl: b.richAcl,
			nofPortfolios: b.nofPortfolios,
		}),
	);

	return [...mapReferences, ...mapBenchmarks].sort(builtInSortFnFor("modificationTime"));
}

const ReferenceList: FC<ReferenceListProps> = ({
	benchmarks,
	references,
	deleteMultiSelectCtx,
	refetch,
	columnsPreferences,
}) => {
	const [searchQuery, setSearchQuery] = useState("");
	const [isPortfolioNameAvailable, setIsPorfolioNameAvailable] = useState(true);
	const [showGroup /* , setShowGroup */] = useState<GroupVariants>("All");

	const [modalData, setModalData] = useState<{
		open: boolean;
		action: UsePerformCrudActions | "share";
		portfolioName: string;
		uuid: string;
		type: ReferenceVariants;
	}>({
		open: false,
		action: "delete",
		portfolioName: "",
		uuid: "",
		type: "portfolio",
	});

	const { performAction: portfolioActions } = usePerformCrud("portfolio");
	const { performAction: benchmarkActions } = usePerformCrud("benchmark");
	const [customizeTableModalVisible, setCustomizeTableModalVisible] = useState(false);
	const history = useHistory();

	const user = useUserValue();
	const { t } = useTranslation();

	const investmentApi = useApiGen(InvestmentControllerV4ApiFactory);
	const benchmarkV4Api = useApiGen(BenchmarksControllerApiFactory);
	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);
	const integrationsApi = useApiGen(IntegrationsControllerApiFactory);
	const { aclSpawn: targetPortfolioSpawn } = useUpdateAccessListControlDialog("INVESTMENT");
	const { aclSpawn: benchmarkSpawn } = useUpdateAccessListControlDialog("BENCHMARK");

	const benchmarksdeleteMultiSelectCtx = useMultiSelect<string>();
	const referencesdeleteMultiSelectCtx = useMultiSelect<string>();

	const { value: debouncedLowerCaseSearchQuery } = useDebouncedMemo(
		() => searchQuery.toLocaleLowerCase(),
		[searchQuery],
		{
			debounceInterval: 200,
		},
	);

	const rows = useMemo<Record<GroupVariants, UnifiedMockedReferenceProps[]>>(
		() => ({
			Benchmark: unifyReferenceAndBenchmarksData(benchmarks),
			References: unifyReferenceAndBenchmarksData(undefined, references),
			All: unifyReferenceAndBenchmarksData(benchmarks, references),
		}),
		[references, benchmarks],
	);

	const filteredMultiSelectCtx = useMemo<Record<GroupVariants, MultiSelectCtx<string>>>(
		() => ({
			Benchmark: benchmarksdeleteMultiSelectCtx,
			References: referencesdeleteMultiSelectCtx,
			All: deleteMultiSelectCtx,
		}),
		[benchmarksdeleteMultiSelectCtx, deleteMultiSelectCtx, referencesdeleteMultiSelectCtx],
	);

	const referenceByUid = useMemo(() => Map(rows.All.map((p) => [p.identifier ?? "", p])), [rows.All]);

	const filteredRows = useMemo(
		() =>
			rows[showGroup].filter((item) =>
				[item.name ?? "", item.status ?? ""].some((el) => el.toLowerCase().includes(debouncedLowerCaseSearchQuery)),
			),
		[debouncedLowerCaseSearchQuery, rows, showGroup],
	);

	const { column: checkboxColumn, rowClassList } = TableV2.useSelectableTableColumn({
		rows: filteredRows,
		multiSelectCtx: filteredMultiSelectCtx[showGroup],
		selectBy: (r) => r.identifier!,
	});

	const { referenceListOrderByName, setReferenceListOrderByName } = usePortfolioStudioTableSettings();
	const visibleColumns = useReferenceColumn(columnsPreferences);

	const getDropdownActions = useCallback(
		({
			name,
			identifier,
			type,
			status,
			linkedPortfolio,
			richAcl,
		}: UnifiedMockedReferenceProps): DropdownMenuProps<HTMLButtonElement, "">["actions"] => {
			const referenceLength = linkedPortfolio ? Boolean(linkedPortfolio.length) : true;
			const area = type === "Benchmark" ? "benchmark" : ("targetPortfolio" satisfies keyof typeof aclByArea);
			return [
				({ onClose }) => (
					<AuthorizationGuard permissionChecker={aclByArea[area].canDelete} acl={richAcl?.acl ?? []}>
						<DropdownMenuActionButton
							icon="Delete"
							disabled={referenceLength}
							onClick={() => {
								setModalData({
									open: true,
									action: "delete",
									portfolioName: name ?? "",
									uuid: identifier ?? "",
									type: type === "Benchmark" ? "benchmark" : "portfolio",
								});
								onClose();
							}}
							data-qualifier="PortfolioStudio/ReferenceList/DropdownMenu/DropdownItem(Delete)"
						>
							Delete
						</DropdownMenuActionButton>
					</AuthorizationGuard>
				),
				({ onClose }) => (
					<DropdownMenuActionButton
						icon="Content-Copy"
						disabled={status ? invalidStatus.includes(status as InvestmentStatuses) : true}
						onClick={() => {
							setModalData({
								open: true,
								action: "duplicate",
								portfolioName: name ?? "",
								uuid: identifier ?? "",
								type: type === "Benchmark" ? "benchmark" : "portfolio",
							});
							onClose();
						}}
						data-qualifier="PortfolioStudio/ReferenceList/DropdownMenu/DropdownItem(Duplicate)"
					>
						Duplicate
					</DropdownMenuActionButton>
				),
				({ onClose }) => (
					<AuthorizationGuard permissionChecker={aclByArea[area].canRename} acl={richAcl?.acl ?? []}>
						<DropdownMenuActionButton
							icon="Edit"
							disabled={type === "Benchmark" && status === "ERROR"}
							onClick={() => {
								setModalData({
									open: true,
									action: "rename",
									portfolioName: name ?? "",
									uuid: identifier ?? "",
									type: type === "Benchmark" ? "benchmark" : "portfolio",
								});
								onClose();
							}}
							data-qualifier="PortfolioStudio/ReferenceList/DropdownMenu/DropdownItem(Rename)"
						>
							Rename
						</DropdownMenuActionButton>
					</AuthorizationGuard>
				),
				({ onClose }) => (
					<AuthorizationGuard
						permissionChecker={(userId, acl) =>
							type === "References"
								? validateACLPermissions(userId, acl, roleByArea.targetPortfolio.EDITOR)
								: validateACLPermissions(userId, acl, roleByArea.marketView.EDITOR)
						}
						acl={richAcl?.acl ?? []}
					>
						<DropdownMenuActionButton
							icon="share"
							onClickAsync={async () => {
								if (type === "References") {
									await targetPortfolioSpawn(name, identifier, refetch.references);
								}

								if (type === "Benchmark") {
									await benchmarkSpawn(name, identifier, refetch.benchmarks);
								}

								onClose();
							}}
							data-qualifier="PortfolioStudio/ReferenceList/DropdownMenu/DropdownItem(Share)"
						>
							Share
						</DropdownMenuActionButton>
					</AuthorizationGuard>
				),
			];
		},
		[benchmarkSpawn, refetch, targetPortfolioSpawn],
	);

	const columns = useMemo<TableV2.TableColumn<UnifiedMockedReferenceProps>[] | null>(
		() =>
			visibleColumns
				? [
						checkboxColumn,
						...visibleColumns,
						actionsColumn({
							onSettingsClick: () => setCustomizeTableModalVisible(true),
							triggerProps: {
								"data-qualifier": "PortfolioStudio/Reference/DropdownMenu",
							},
							dropdownActions: getDropdownActions,
						}),
				  ]
				: null,
		[checkboxColumn, getDropdownActions, visibleColumns],
	);

	const filteredSortedRows = useMemo(
		() => (columns ? sortRows({ rows: filteredRows, columns, orderByArr: referenceListOrderByName }) : filteredRows),
		[columns, filteredRows, referenceListOrderByName],
	);

	const discriminateCrudAction = useCallback(
		(type: ReferenceVariants) => {
			if (type === "portfolio") {
				return { performAction: portfolioActions, verify: investmentApi.isInvestmentNameAvailable };
			}
			return { performAction: benchmarkActions, verify: benchmarkV4Api.isBenchmarkNameAvailable };
		},
		[benchmarkActions, benchmarkV4Api, investmentApi, portfolioActions],
	);

	const onCloseModal = useCallback(() => {
		setModalData({ open: false, action: modalData.action, uuid: "", portfolioName: "", type: "portfolio" });
		setIsPorfolioNameAvailable((prev) => (prev === true ? prev : true));
	}, [modalData.action]);

	const onDelete = useCallback(
		async (uuid: string, name: string, type: ReferenceVariants) => {
			try {
				const { performAction } = discriminateCrudAction(type);
				await performAction({ action: "delete", uuid });

				trackMixPanelEvent(`Portfolio`, {
					Type: "Delete",
					ID: uuid,
					Name: name,
				});

				if (type === "benchmark") {
					await refetch.benchmarks({ throwOnError: true });
				}
				if (type === "portfolio") {
					await refetch.references({ throwOnError: true });
				}
				platformToast({
					children: t("PUSH_NOTIFICATION.SUCCESS_DELETE_REFERENCE", { name }),
					severity: "success",
					icon: "Portfolio",
				});
			} catch (error) {
				reportPlatformError(error, "ERROR", "portfolio", `delete reference investment "${uuid}"`);
				platformToast({ children: t("SOMETHING_WENT_WRONG"), severity: "error", icon: "Portfolio" });
			} finally {
				onCloseModal();
				deleteMultiSelectCtx.actions.remove(uuid);
			}
		},
		[deleteMultiSelectCtx.actions, discriminateCrudAction, onCloseModal, refetch, t],
	);

	const onSubmit = useCallback(
		async (
			action: Exclude<UsePerformCrudActions, "delete">,
			uuid: string,
			name: string,
			type: "portfolio" | "benchmark",
		) => {
			try {
				const { performAction, verify } = discriminateCrudAction(type);

				const { data: isNameAvailable } = await verify(name);
				if (!isNameAvailable) {
					setIsPorfolioNameAvailable(isNameAvailable);
					return;
				}

				await performAction({ action, uuid, name });

				if (type === "benchmark") {
					await refetch.benchmarks({ throwOnError: true });
				}
				if (type === "portfolio") {
					await refetch.references({ throwOnError: true });
				}

				const benchmarkNotificationMessage: Record<typeof action, string> = {
					duplicate: t("PUSH_NOTIFICATION.SUCCESS_DUPLICATED_CUSTOM_BENCHMARK", { name }),
					rename: t("PUSH_NOTIFICATION.SUCCESS_RENAMED_CUSTOM_BENCHMARK", { name }),
				};

				const portfolioNotificationMessage: Record<typeof action, string> = {
					duplicate: t("PUSH_NOTIFICATION.SUCCESS_DUPLICATED_TARGET_PORTFOLIO", { name }),
					rename: t("PUSH_NOTIFICATION.SUCCESS_RENAMED_TARGET_PORTFOLIO", { name }),
				};

				platformToast({
					children: type === "portfolio" ? portfolioNotificationMessage[action] : benchmarkNotificationMessage[action],
					severity: "success",
					icon: "Portfolio",
				});

				onCloseModal();
			} catch (error) {
				reportPlatformError(error, "ERROR", "portfolio", `${action} reference investment "${uuid}"`);
				platformToast({ children: t("SOMETHING_WENT_WRONG"), severity: "error", icon: "Portfolio" });
			}
		},
		[onCloseModal, discriminateCrudAction, refetch, t],
	);

	const onChangeTableColumn = useCallback(
		async (data: ColumnMetadata<UserReferenceColumnPreferencePreferencesTypeEnum>[]) => {
			try {
				const payload = {
					userReferenceColumnPreferences: data.map((preference) => ({
						enabled: preference.visible,
						preferencesType: preference.id,
					})),
				} satisfies UserReferenceColumnOrdering;
				await portfolioStudioPreferencesApi.setUserReferenceColumnOrderingPreferences(payload);
				await refetch.columnsPreferences({ throwOnError: true });
				platformToast({
					children: "Table Successfully Edited.",
					severity: "success",
					icon: "Settings",
				});
			} catch (error) {
				platformToast({
					children: "Failed to update table",
					severity: "error",
					icon: "Settings",
				});
				throw new Error(String(error));
			} finally {
				setCustomizeTableModalVisible(false);
			}
		},
		[portfolioStudioPreferencesApi, refetch],
	);

	const customizableColumns = (columnsPreferences ?? []).map((c) => ({
		label: c.preferencesType ? t(`TABLE.HEADERS.${c.preferencesType}`) : "-",
		id: c.preferencesType!,
		visible: c.enabled ?? false,
		disabled: c.preferencesType === "NAME",
		hidden: c.preferencesType === "NAME",
	}));

	return (
		<>
			<CustomizeColumns
				show={customizeTableModalVisible}
				onClose={() => setCustomizeTableModalVisible(false)}
				columns={customizableColumns}
				// limit={Math.min(7, customizableColumns?.filter((x) => x.hidden === false).length ?? 1)}
				onSubmitAsync={onChangeTableColumn}
			/>

			<div className="flex justify-between items-center">
				<TextInput
					value={searchQuery}
					name="search"
					maxLength={60}
					onChangeText={setSearchQuery}
					placeholder={t("PORTFOLIOS.CONSTRAINTS_TARGETS.PORTFOLIO_LIST")}
					onKeyDown={preventSubmitOnPressEnter}
					style={{ width: 692 }}
					leftContent={<Icon icon="Search" size="1.4em" />}
				/>
				<div className="flex gap-4">
					<ReactQueryWrapper
						queryFn={() => axiosExtract(integrationsApi.retrieveReferenceImportConverterType())}
						queryKey={["importFormat", "reference"]}
					>
						{(converters) => (
							<Button
								size="small"
								palette="secondary"
								onClick={() =>
									spawnInvestmentReferenceImportDialog({
										converters,
										onImportFinished: ({ imported }) => {
											if (imported.length) {
												refetch.benchmarks({ throwOnError: true }).catch(noop);
												refetch.references({ throwOnError: true }).catch(noop);
											}
										},
									})
								}
							>
								<Icon icon="Dowload" size={16} classList="-rotate-90 mr-1" />
								Import
							</Button>
						)}
					</ReactQueryWrapper>
					<Button
						size="small"
						palette="secondary"
						onClick={() => history.push("/portfolios/upload/target")}
						data-qualifier="PortfolioStudio/ReferenceList/NewTargetPortfolio"
					>
						<Icon icon="Outline1" size={16} />
						New target portfolio
					</Button>
					<Button
						size="small"
						palette="secondary"
						onClick={() => history.push("/portfolios/upload/benchmark")}
						data-qualifier="PortfolioStudio/ReferenceList/NewBenchamrk"
					>
						<Icon icon="Outline1" size={16} />
						New benchmark
					</Button>
				</div>
			</div>
			<BatchActions
				classList="my-2"
				selected={deleteMultiSelectCtx.data.selection.size}
				total={rows.All.length}
				actions={[
					{
						label: "Delete",
						icon: "Delete",
						onClick: nullary(deleteMultiSelectCtx.actions.confirmSelection),
						disabled: deleteMultiSelectCtx.data.selection.some((id) => {
							const reference = referenceByUid?.get(id);
							return (
								(reference?.linkedPortfolio ?? []).length > 0 ||
								aclByArea.targetPortfolio.canDelete(user.id, reference?.richAcl?.acl ?? []) === false
							);
						}),
					},
				]}
			/>
			{columns && (
				<TableV2.BaseHScrollTable
					onRowClick={(row) => row.identifier && deleteMultiSelectCtx.actions.toggle(row.identifier)}
					onRowContextMenu={(row, _index, e) => contextMenuHandler(e, getDropdownActions(row))}
					key="referenceListTable"
					columns={columns}
					rows={filteredSortedRows}
					rowClassList={rowClassList}
					rowStyle={({ identifier }) => {
						const selected = deleteMultiSelectCtx.data.selection?.has(identifier!);
						return selected ? { backgroundColor: themeCSSVars.Table_highlightedRowBackgroundColor } : {};
					}}
					orderBy={referenceListOrderByName}
					// TODO: refactor
					style={{ maxHeight: "calc(100dvh - 160px)" }}
					noDataText="No data available"
					pinnedColumns={[
						{ name: "name", side: "left" },
						{ name: "settings-action", side: "right" },
					]}
					onOrderByChange={setReferenceListOrderByName}
				/>
			)}
			{modalData.action === "delete" && (
				<CrudModal
					show={modalData.open}
					action={modalData.action}
					title={t("PORTFOLIOS.MODAL.SINGLE_DELETE.TITLE")}
					portfolioName={modalData.portfolioName}
					onCancel={onCloseModal}
					onClose={onCloseModal}
					onSubmit={() => onDelete(modalData.uuid, modalData.portfolioName, modalData.type)}
				/>
			)}

			{modalData.action === "duplicate" && (
				<CrudModal
					show={modalData.open}
					action={modalData.action}
					title={t("PORTFOLIOS.MODAL.DUPLICATE.TITLE", { portfolio: modalData.portfolioName })}
					onCancel={onCloseModal}
					onClose={onCloseModal}
					isInvalid={!isPortfolioNameAvailable}
					onSubmit={(name) => onSubmit("duplicate", modalData.uuid, name, modalData.type)}
					portfolioName={modalData.portfolioName}
				/>
			)}

			{modalData.action === "rename" && (
				<CrudModal
					show={modalData.open}
					action={modalData.action}
					title={t("PORTFOLIOS.MODAL.RENAME.TITLE", { portfolio: modalData.portfolioName })}
					onCancel={onCloseModal}
					onClose={onCloseModal}
					isInvalid={!isPortfolioNameAvailable}
					onSubmit={(name) => onSubmit("rename", modalData.uuid, name, modalData.type)}
					portfolioName={modalData.portfolioName}
				/>
			)}
		</>
	);
};

export default ReferenceList;

const invalidStatus: Array<InvestmentStatuses> = [
	"REVIEW",
	"CALCULATING",
	"RETRIEVING_DATA",
	"PROPOSAL_READY",
	"ERROR",
];

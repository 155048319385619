import AuthorizationGuard from "$root/components/AuthorizationGuard";
import { CustomLabels } from "$root/components/CustomLabels";
import { formatNumber } from "$root/localization/formatters";
import { Text } from "@mdotm/mdotui/components";
import { useTranslation } from "react-i18next";
import type { InvestmentSummary } from "$root/api/api-gen";
import type { PrintableProps } from "$root/components/EvolvedPrint/configuration";
import { Card } from "$root/components/EvolvedPrint/components/Card";
import type { ReportTemplateItemMap } from "$root/pages/PortfolioStudioSettings/ReportEditor/report-latest";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { Languages } from "$root/localization/i18n";

export function Summary(
	props: PrintableProps<
		{ portfolio: InvestmentSummary; config: ReportTemplateItemMap["summary"]; language?: Languages },
		unknown
	>,
): JSX.Element {
	const {
		name,
		primaryBenchmarkName,
		primaryBenchmarkType,
		primaryBenchmarkIdentifier,
		universeName,
		selectedTrackingErrorConstraint,
		baseCurrency,
		scoreValue,
		scoreIdentifier,
		// reference,
	} = props.portfolio;
	const { language = "en" } = props;
	const { t } = useTranslation(undefined, { lng: language });

	return (
		<Card>
			<div className="py-4 border-t border-b-2 pr-9" style={{ borderColor: themeCSSVars.palette_N300 }}>
				<div className="grid grid-cols-2 gap-x-8 gap-y-5">
					{props.config.portfolioName && (
						<div>
							<Text type="Body/S/Light-UPPERCASE" color="rgb(140, 142, 168)">
								{t("REPORT_BUILDER.SUMMARY.PORTFOLIO_NAME")}
							</Text>
							<Text
								type="Body/S/Bold"
								as="div"
								classList="max-w-[200px] truncate"
								data-title={name ?? "-"}
								title={name ?? "-"}
							>
								{name ?? "-"}
							</Text>
						</div>
					)}
					{props.config.baseCurrency && (
						<div>
							<Text type="Body/S/Light-UPPERCASE" color="rgb(140, 142, 168)">
								{t("REPORT_BUILDER.SUMMARY.BASE_CURRENCY")}
							</Text>
							<Text
								type="Body/S/Bold"
								as="div"
								classList="max-w-[200px] truncate"
								data-title={name ?? "-"}
								title={name ?? "-"}
							>
								{baseCurrency ?? "-"}
							</Text>
						</div>
					)}
					{props.config.benchmark && (
						<div>
							<Text type="Body/S/Light-UPPERCASE" color="rgb(140, 142, 168)">
								{t("REPORT_BUILDER.SUMMARY.BENCHMARK")}
							</Text>
							<Text
								type="Body/S/Bold"
								as="div"
								data-title={t("REPORT_BUILDER.SUMMARY.BENCHMARK")}
								title={t("REPORT_BUILDER.SUMMARY.BENCHMARK")}
								classList="whitespace-nowrap text-ellipsis overflow-hidden  cursor-pointer"
							>
								{primaryBenchmarkIdentifier ? primaryBenchmarkName : ""}
							</Text>
						</div>
					)}

					{props.config.universe && (
						<div>
							<Text type="Body/S/Light-UPPERCASE" color="rgb(140, 142, 168)">
								{t("REPORT_BUILDER.SUMMARY.UNIVERSE")}
							</Text>
							<Text
								type="Body/S/Bold"
								as="div"
								data-title={primaryBenchmarkType ?? "-"}
								title={primaryBenchmarkType ?? "-"}
								classList={{
									"whitespace-nowrap text-ellipsis overflow-hidden pr-4 underline cursor-pointer !font-bold":
										Boolean(universeName),
								}}
							>
								{universeName ?? t("REPORT_BUILDER.SUMMARY.NO_UNIVERSE")}
							</Text>
						</div>
					)}

					{props.config.reference && (
						<div>
							<Text type="Body/S/Light-UPPERCASE" color="rgb(140, 142, 168)">
								{t("REPORT_BUILDER.SUMMARY.REFERENCE")}
							</Text>
							<Text
								type="Body/S/Bold"
								as="div"
								data-title={t("INVESTMENT_SUMMARY.REFERENCE")}
								title={t("REPORT_BUILDER.SUMMARY.REFERENCE")}
								classList={{
									"whitespace-nowrap text-ellipsis overflow-hidden pr-4 underline cursor-pointer !font-bold": Boolean(
										selectedTrackingErrorConstraint,
									),
								}}
							>
								{selectedTrackingErrorConstraint?.investmentReference?.name || t("REPORT_BUILDER.SUMMARY.NO_REFERENCE")}
							</Text>
						</div>
					)}
					{props.config.averageScore && (
						<AuthorizationGuard requiredService="CUSTOM_QUALITIES">
							{() => (
								<div>
									<Text type="Body/S/Light-UPPERCASE" color="rgb(140, 142, 168)">
										{t("AVERAGE_SCORE")}
									</Text>
									<Text
										type="Body/S/Bold"
										as="div"
										data-title={t("AVERAGE_SCORE")}
										title={t("REPORT_BUILDER.SUMMARY.AVARAGE_SCORE")}
										classList="whitespace-nowrap text-ellipsis overflow-hidden  cursor-pointer"
									>
										{scoreIdentifier ? (
											<>
												(<CustomLabels labelKey={scoreIdentifier ?? "-"} fallback="SCORE" />){" "}
												{scoreValue ? formatNumber(scoreValue) : ""}
											</>
										) : (
											"None"
										)}
									</Text>
								</div>
							)}
						</AuthorizationGuard>
					)}
				</div>
			</div>
		</Card>
	);
}

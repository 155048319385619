import i18n from "i18next";

export type FormatNumberOptions = {
	maxDecimalPlaces: number;
	minDecimalPlaces: number;
	fallback: string;
	locale: string | undefined;
};

export type FormatNumber = {
	(n: number | string | null | undefined, decimalPlaces?: number): string;
	(n: number | string | null | undefined, opts: Partial<FormatNumberOptions>): string;
};

export type FormatDate = {
	(date: Date | string | number | undefined | null, opts?: Partial<FormatDateOptions>): string;
};

export const formatNumber: FormatNumber = (n, decimalPlacesOrOptions) => {
	const opts: FormatNumberOptions =
		typeof decimalPlacesOrOptions === "number"
			? {
					locale: i18n.resolvedLanguage,
					fallback: "-",
					minDecimalPlaces: decimalPlacesOrOptions,
					maxDecimalPlaces: decimalPlacesOrOptions,
			  }
			: {
					locale: decimalPlacesOrOptions?.locale ?? i18n.resolvedLanguage,
					fallback: decimalPlacesOrOptions?.fallback ?? "-",
					maxDecimalPlaces: decimalPlacesOrOptions?.maxDecimalPlaces ?? 2,
					minDecimalPlaces: decimalPlacesOrOptions?.minDecimalPlaces ?? decimalPlacesOrOptions?.maxDecimalPlaces ?? 2,
			  };

	if (n !== null && n !== undefined && (typeof n === "number" || (typeof n === "string" && n !== ""))) {
		return (typeof n === "number" ? n : Number(n)).toLocaleString(opts.locale, {
			maximumFractionDigits: opts.maxDecimalPlaces,
			minimumFractionDigits: opts.minDecimalPlaces,
			minimumIntegerDigits: 1,
		});
	}
	return opts.fallback;
};

export type FormatDateOptions = {
	fallback: string;
	locale: string | undefined;
	minute: Intl.DateTimeFormatOptions["minute"] | null;
	hour: Intl.DateTimeFormatOptions["hour"] | null;
	day: Intl.DateTimeFormatOptions["day"] | null;
	month: Intl.DateTimeFormatOptions["month"] | null;
	year: Intl.DateTimeFormatOptions["year"] | null;
};

export const formatDate: FormatDate = (date, opts) => {
	if (
		date !== null &&
		date !== undefined &&
		(typeof date === "object" || (typeof date === "string" && date !== "") || typeof date === "number")
	) {
		return (typeof date === "object" ? date : new Date(date)).toLocaleDateString(
			opts?.locale ?? i18n.resolvedLanguage,
			{
				day: opts?.day !== null ? opts?.day ?? "2-digit" : undefined,
				month: opts?.month !== null ? opts?.month ?? "2-digit" : undefined,
				year: opts?.year !== null ? opts?.year ?? "numeric" : undefined,
				hour: opts?.hour !== null ? opts?.hour ?? undefined : undefined,
				minute: opts?.minute !== null ? opts?.minute ?? undefined : undefined,
			},
		);
	}
	return opts?.fallback ?? "-";
};

export function getLocalizedDateMask(locale?: string): string {
	return (
		{
			it: "__/__/____",
			en: "__/__/____",
		}[locale ?? i18n.resolvedLanguage ?? i18n.language] ?? "__/__/____"
	);
}

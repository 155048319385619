import type { BenchmarkSummary, InvestmentStatuses } from "$root/api/api-gen";
import {
	BenchmarksControllerApiFactory,
	EntityEditorControllerApiFactory,
	IntegrationsControllerApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { LabelRounded } from "$root/components/LabelRounded/Index";
import type { PageHeaderProps } from "$root/components/PageHeader";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import ReactQueryWrapper from "$root/components/ReactQueryWrapper";
import ReviewCompositionErrors from "$root/components/ReviewCompositionErrors";
import { useEventBus } from "$root/event-bus";
import EntityStatus from "$root/functional-areas/acl/EntityStatus";
import { EditProxiedInstrumentSection } from "$root/functional-areas/proxies/EditProxiedInstrumentSection";
import type { UsePerformCrudActions } from "$root/hooks/usePerformCrud";
import usePerformCrud from "$root/hooks/usePerformCrud";
import { useLocaleFormatters } from "$root/localization/hooks";
import { platformToast } from "$root/notification-system/toast";
import type { CustomAxiosError } from "$root/third-party-integrations/axios";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { objMatchFn } from "$root/utils/objects";
import { pxToRem } from "$root/utils/pxToRem";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { ellipsis } from "$root/utils/strings";
import { BenchmarkContext } from "$root/widgets-architecture/contexts/benchmark";
import { Card } from "$root/widgets-architecture/layout/Card";
import WidgetsMapper from "$root/widgets-architecture/layout/WidgetsMapper";
import { AsyncButton, Banner, Button, Icon, Table, Text } from "@mdotm/mdotui/components";
import { noop } from "@mdotm/mdotui/utils";
import type { FC } from "react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { CrudModal, PortfolioStudioTab } from "../PortfoliosStudio";
import type { AxiosError } from "axios";
import { spawnAccessDialog } from "$root/functional-areas/acl/AccessDialog";
import { PageDownloadAndMoreActionsMenu } from "$root/components/PageDownloadAndMoreActionsMenu";
import { downloadContentDisposition } from "$root/utils/files";
import { aclByArea } from "$root/functional-areas/acl/checkers/all";
import { useUserValue } from "$root/functional-areas/user";
import { PageCtxConsumer } from "$root/components/PageCtx";
import { createPortal } from "react-dom";

const severityByStatus: Record<InvestmentStatuses, PageHeaderProps["severity"]> = {
	CALCULATING: "calculating",
	ERROR: "error",
	READY: "success",
	ACCEPTED: "success",
	PROPOSAL_READY: "success",
	RETRIEVING_DATA: "calculating",
	REVIEW: undefined,
	DRAFT: "info", // TODO(openapi-update): do we need a new status?
};

type PageHeaderSubtitleSummary = {
	summary?: BenchmarkSummary;
	onChangeAcl?: () => Promise<unknown>;
	setCrudAction: (crudAction: UsePerformCrudActions) => void;
};

const PageHeaderSubtitle: FC<PageHeaderSubtitleSummary> = (props) => {
	const { summary, onChangeAcl } = props;
	const { richAcl } = summary ?? {};
	const { t } = useTranslation();

	const { formatDate } = useLocaleFormatters();
	const benchmarkV4Api = useApiGen(BenchmarksControllerApiFactory);
	const integrationsApi = useApiGen(IntegrationsControllerApiFactory);
	const user = useUserValue();
	const { push } = useTypedNavigation();
	return (
		<div className="flex justify-between items-center min-h-[54px]">
			<div className="flex justify-between items-center space-x-4 text-[#585D68]">
				<div className="flex items-center" data-qualifier="BenchmarkDetails/PageHeader/Status">
					<span className="mr-2 uppercase">{t("STATUS")}</span>
					<LabelRounded type="status" content={{ label: summary?.status ?? "-", component: "" }} />
				</div>
				<div className="" data-qualifier="BenchmarkDetails/PageHeader/Action">
					<span className="uppercase">Last action request:</span>&nbsp;
					<span className="font-bold uppercase">
						{summary?.action} ({formatDate(summary?.modificationTime ?? "")})
					</span>
				</div>
				<EntityStatus
					accessControl={richAcl}
					entity="BENCHMARK"
					entityId={summary?.benchmarkIdentifier}
					entityName={summary?.name}
					refetch={onChangeAcl}
				/>
			</div>
			<div className="flex items-center gap-2">
				{summary?.status !== "CALCULATING" &&
					summary?.status !== "RETRIEVING_DATA" &&
					aclByArea.benchmark.canEditComposition(user.id, summary?.richAcl?.acl ?? []) && (
						<div className="flex justify-end shrink">
							<Button
								size="small"
								palette="primary"
								onClick={() =>
									push("CustomBenchmark/EditCustomBenchmark", { benchmarkId: summary!.benchmarkIdentifier! })
								}
							>
								<Icon icon="Edit" size={16} />
								&nbsp; {t("BUTTON.EDIT")}
							</Button>
						</div>
					)}
				<PageDownloadAndMoreActionsMenu
					area="benchmark"
					downloadActions={[
						{
							icon: "xls",
							disabled: summary?.status === "CALCULATING" || !summary,
							onClickAsync: async () => {
								if (!summary?.benchmarkIdentifier) {
									throw new Error("unable to find benchmarkId of undefined");
								}
								const response = await benchmarkV4Api.exportComposition1(summary?.benchmarkIdentifier, {
									responseType: "blob",
								});
								downloadContentDisposition(response);
							},
							label: "Benchmark composition",
							"data-qualifier": "BenchmarkDetails/DropdownMenu/DropdownItem(XLSReport)",
						},
						{
							icon: "xls",
							onClickAsync: async () => {
								if (!summary?.benchmarkIdentifier) {
									throw new Error("unable to find benchmarkId of undefined");
								}
								const response = await integrationsApi.exportReference(summary?.benchmarkIdentifier, {
									responseType: "blob",
								});
								downloadContentDisposition(response);
							},
							label: "Reference template",
							"data-qualifier": "BenchmarkDetails/DropdownMenu/DropdownItem(ReferenceTemplate)",
						},
					]}
					moreActions={[
						!aclByArea.benchmark.canDelete(user.id, richAcl?.acl ?? [])
							? null
							: {
									icon: "Delete",
									onClick: () => {
										props.setCrudAction("delete");
									},
									"data-qualifier": "BenchmarkDetails/DropdownMenu/DropdownItem(Delete)",
									disabled: summary?.status === "ERROR",
									label: t("BUTTON.DELETE"),
							  },
						{
							icon: "Content-Copy",
							onClick: () => {
								props.setCrudAction("duplicate");
							},
							"data-qualifier": "BenchmarkDetails/DropdownMenu/DropdownItem(Duplicate)",
							disabled: summary?.status === "CALCULATING",
							label: t("BUTTON.DUPLICATE"),
						},
						!aclByArea.benchmark.canRename(user.id, richAcl?.acl ?? [])
							? null
							: {
									icon: "Edit",
									onClick: () => {
										props.setCrudAction("rename");
									},
									"data-qualifier": "BenchmarkDetails/DropdownMenu/DropdownItem(Rename)",
									disabled: summary?.status === "ERROR",
									label: t("BUTTON.RENAME"),
							  },
					]}
				/>
			</div>
		</div>
	);
};

const CustomBenchmark = (): JSX.Element => {
	const [isBenchmarkNameAvailable, setIsBenchmarkNameAvailable] = useState(true);
	const [crudAction, setCrudAction] = useState<UsePerformCrudActions | undefined>(undefined);

	const { t } = useTranslation();
	const { benchmarkId } = useParams<{ benchmarkId?: string }>();
	const { performAction } = usePerformCrud("benchmark");
	const benchmarksV4Api = useApiGen(BenchmarksControllerApiFactory);
	const editorApi = useApiGen(EntityEditorControllerApiFactory);
	const { push } = useTypedNavigation();

	const { data: summary, refetch: refetchSummary } = useQueryNoRefetch(["getBenchmarkSummary"], {
		enabled: Boolean(benchmarkId),
		queryFn: async () => {
			if (!benchmarkId) {
				throw Error("unable to find benchmarkId of undefined");
			}
			const { data } = await benchmarksV4Api.getBenchmarkSummary(benchmarkId);
			return data;
		},
		onError: (error: AxiosError<CustomAxiosError>) => {
			if (error.response?.data.code === 404 || error.response?.data.message === "Accesso negato") {
				spawnAccessDialog({
					onClick: (onClose) => {
						push("PortfoliosStudio", { tab: PortfolioStudioTab.References });
						onClose();
					},
				});
				return;
			}

			platformToast({
				children: t("SOMETHING_WENT_WRONG"),
				severity: "error",
				icon: "Portfolio",
			});
			push("PortfoliosStudio", { status: "notFound", tab: PortfolioStudioTab.References });
		},
	});

	useEventBus("benchmark-update", {
		filter: objMatchFn({ uuid: benchmarkId }),
		listener: () => {
			refetchSummary().catch(noop);
		},
	});

	useEventBus("shared-entity", {
		filter: objMatchFn({ sharedEntityUuid: benchmarkId }),
		listener: () => {
			refetchSummary().catch(noop);
		},
	});

	const onCloseModal = useCallback(() => setCrudAction(undefined), []);

	const onDelete = useCallback(
		async (name: string, uuid?: string) => {
			try {
				if (!uuid) {
					return;
				}
				await performAction({ action: "delete", uuid });

				platformToast({
					children: t("PUSH_NOTIFICATION.SUCCESS_DELETE_CUSTOM_BENCHMARK", { name }),
					severity: "success",
					icon: "Portfolio",
				});
			} catch (error) {
				platformToast({
					children: t("SOMETHING_WENT_WRONG"),
					severity: "error",
					icon: "Portfolio",
				});
			} finally {
				onCloseModal();
				setTimeout(() => push("PortfoliosStudio", { tab: PortfolioStudioTab.References }), 1000);
			}
		},
		[performAction, t, onCloseModal, push],
	);

	const onSubmit = useCallback(
		async (action: Exclude<UsePerformCrudActions, "delete">, name: string, uuid?: string) => {
			try {
				if (!uuid) {
					return;
				}
				const { data: isNameAvailable } = await benchmarksV4Api.isBenchmarkNameAvailable(name);
				if (isNameAvailable === false) {
					setIsBenchmarkNameAvailable(isNameAvailable);
					return;
				}

				await performAction({ action, uuid, name });
				const notificationMessage: Record<typeof action, string> = {
					duplicate: t("PUSH_NOTIFICATION.SUCCESS_DUPLICATED_CUSTOM_BENCHMARK", { name }),
					rename: t("PUSH_NOTIFICATION.SUCCESS_RENAMED_CUSTOM_BENCHMARK", { name }),
				};

				platformToast({
					children: notificationMessage[action],
					severity: "success",
					icon: "Portfolio",
				});

				if (action === "rename") {
					await refetchSummary();
					onCloseModal();
					return;
				}

				if (action === "duplicate") {
					onCloseModal();
					setTimeout(() => push("PortfoliosStudio", { tab: PortfolioStudioTab.References }), 1000);
				}
			} catch (error) {
				platformToast({
					children: t("SOMETHING_WENT_WRONG"),
					severity: "error",
					icon: "Portfolio",
				});
			}
		},
		[benchmarksV4Api, performAction, t, refetchSummary, onCloseModal, push],
	);

	const shouldBeInReviewMode = useMemo(() => {
		return summary?.status === "REVIEW" || summary?.status === "RETRIEVING_DATA";
	}, [summary?.status]);

	const { formatNumber } = useLocaleFormatters();

	return (
		<>
			<PageHeader
				severity={summary?.status ? severityByStatus[summary?.status] : undefined}
				title={summary?.name ?? ""}
				crumbs={[
					{
						children: t("PLACEHOLDER.TITLE.PORTFOLIOS"),
						href: typedUrlForRoute("PortfoliosStudio", {}),
					},
					{
						children: "References",
						href: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.References }),
					},
					{
						children: ellipsis(summary?.name ?? "...", 30),
					},
				]}
				subTitle={
					summary?.status !== "REVIEW" && (
						<PageHeaderSubtitle summary={summary} onChangeAcl={refetchSummary} setCrudAction={setCrudAction} />
					)
				}
				name="BenchmarkDetails"
			/>

			{shouldBeInReviewMode ? (
				<ReactQueryWrapper
					queryFn={() => axiosExtract(editorApi.getEditorReviewComposition(benchmarkId ?? "", "BENCHMARK"))}
					queryKey={["benchmarkCompositionInReview", summary?.status]}
				>
					{(data) => (
						<Card>
							<Text type="Body/XL/Bold" classList="mb-4">
								Composition
							</Text>
							<ReviewCompositionErrors errors={data?.uploadErrors} />
							<div className="mb-4" hidden={summary?.status === "RETRIEVING_DATA"}>
								<Banner severity="warning" title="Review Before Saving">
									Some instruments require your attention. This may be due to missing information or because they have
									been delisted. Please review the list below and make any necessary updates before saving.
								</Banner>
							</div>
							{summary?.status === "RETRIEVING_DATA" ? (
								<>
									<div className="mb-4">
										<Banner severity="info">{t("PORTFOLIO_UPLOAD_ALERTS.CALCULATING_UPLOAD")}</Banner>
									</div>
									<Table
										columns={[
											{
												relativeWidth: 4,
												header: t("TABLE.HEADERS.INDEX"),
												content: ({ instrument }) => instrument ?? "",
											},
											{
												relativeWidth: 2.5,
												header: t("COMPOSITION.ASSET_CLASS"),
												content: ({ assetClass }) => assetClass ?? "",
											},
											{
												relativeWidth: 2.5,
												header: t("COMPOSITION.MICRO_ASSET_CLASS"),
												content: ({ microAssetClass }) => microAssetClass ?? "",
											},
											{
												relativeWidth: 1,
												header: t("COMPOSITION.WEIGHT"),
												cellClassList: "tabular-nums",
												content: ({ weight }) => `${formatNumber(weight ?? 0)}%`,
												align: "end",
											},
										]}
										rows={data.composition ?? []}
										visibleRows={Math.min(data.composition?.length ?? 0, 12)}
									/>
								</>
							) : (
								<EditProxiedInstrumentSection
									section="benchmark"
									composition={data.composition ?? []}
									onSaveAsync={async (tickerComposition) => {
										//remind: rick
										await editorApi.saveEditorReviewEntity("BENCHMARK", {
											// TODO: test
											identifier: benchmarkId,
											tickerComposition,
										});
										await refetchSummary?.();
									}}
									customActions={({ isSaveDisabled, onSaveAsync }) => (
										<PageCtxConsumer>
											{(ctx) =>
												ctx.titleActionPortalTarget &&
												createPortal(
													<div className="flex items-center gap-2">
														<Button
															onClick={() => push("PortfoliosStudio", { tab: PortfolioStudioTab.References })}
															size="small"
															palette="tertiary"
														>
															Cancel
														</Button>
														<AsyncButton
															onClickAsync={onSaveAsync}
															disabled={isSaveDisabled}
															size="small"
															palette="primary"
														>
															Save
														</AsyncButton>
													</div>,
													ctx.titleActionPortalTarget,
												)
											}
										</PageCtxConsumer>
									)}
								/>
							)}
						</Card>
					)}
				</ReactQueryWrapper>
			) : (
				<BenchmarkContext.Provider value={{ benchmarkId }}>
					<WidgetsMapper widgetName="BenchmarkComposition" style={{ minHeight: "calc(100dvh - 288px)" }} />
				</BenchmarkContext.Provider>
			)}

			{crudAction === "delete" && (
				<CrudModal
					show={Boolean(crudAction)}
					action={crudAction}
					title={t("PORTFOLIOS.MODAL.SINGLE_DELETE.TITLE")}
					portfolioName={summary?.name ?? ""}
					onCancel={onCloseModal}
					onClose={onCloseModal}
					onSubmit={() => onDelete(summary?.name ?? "", summary?.benchmarkIdentifier)}
					name="BenchmarkDetails"
				/>
			)}

			{crudAction === "duplicate" && (
				<CrudModal
					show={Boolean(crudAction)}
					action={crudAction}
					title={t("PORTFOLIOS.MODAL.DUPLICATE.TITLE", { portfolio: summary?.name })}
					onCancel={onCloseModal}
					onClose={onCloseModal}
					isInvalid={!isBenchmarkNameAvailable}
					onSubmit={(name) => onSubmit("duplicate", name, summary?.benchmarkIdentifier)}
					portfolioName={summary?.name ?? ""}
					name="BenchmarkDetails"
				/>
			)}

			{crudAction === "rename" && (
				<CrudModal
					show={Boolean(crudAction)}
					action={crudAction}
					title={t("PORTFOLIOS.MODAL.RENAME.TITLE", { portfolio: summary?.name })}
					onCancel={onCloseModal}
					onClose={onCloseModal}
					isInvalid={!isBenchmarkNameAvailable}
					onSubmit={(name) => onSubmit("rename", name, summary?.benchmarkIdentifier)}
					portfolioName={summary?.name ?? ""}
					name="BenchmarkDetails"
				/>
			)}
		</>
	);
};

export default CustomBenchmark;

import type { PortfolioMetric } from "$root/api/api-gen";
import { useLocaleFormatters } from "$root/localization/hooks";
import { TableV2 } from "@mdotm/mdotui/components";
import { useTranslation } from "react-i18next";
import type { PrintableProps } from "$root/components/EvolvedPrint/configuration";
import { Card } from "$root/components/EvolvedPrint/components/Card";
import { TinyTableDataCell, TinyTableHeadCell } from "$root/components/EvolvedPrint/components/table/tiny-table";
import type { Languages } from "$root/localization/i18n";

export function PortfolioMetrics({
	benchmark,
	list,
	language = "en",
}: PrintableProps<{ benchmark: boolean; language?: Languages }, PortfolioMetric>): JSX.Element {
	const { t } = useTranslation(undefined, { lng: language });
	const { formatNumber } = useLocaleFormatters();

	const columns = [
		{
			name: "type",
			width: 330,
			header: (props) => (
				<TinyTableHeadCell {...props}>{t("REPORT_BUILDER.PORTFOLIO_METRICS.TABLE.METRIC")}</TinyTableHeadCell>
			),
			content: ({ type }, props) => (
				<TinyTableDataCell {...props}>
					{t(`REPORT_BUILDER.PORTFOLIO_METRICS.METRICS.${type!}`) ?? "-"}
				</TinyTableDataCell>
			),
		},
		{
			name: "current",
			width: 124,
			cellClassList: "tabular-nums",
			header: (props) => (
				<TinyTableHeadCell {...props}>{t("REPORT_BUILDER.PORTFOLIO_METRICS.TABLE.CURRENT")}</TinyTableHeadCell>
			),
			align: "end",
			content: ({ value, type }, props) => {
				const label = t(`REPORT_BUILDER.PORTFOLIO_METRICS.METRICS.${type!}`) ?? "-";
				return (
					<TinyTableDataCell {...props}>{`${formatNumber(value)}${
						label.toLowerCase().includes("ratio") ? "" : "%"
					}`}</TinyTableDataCell>
				);
			},
		},
		{
			name: "benchmark",
			width: 124,
			cellClassList: "tabular-nums",
			header: (props) => (
				<TinyTableHeadCell {...props}>{t("REPORT_BUILDER.PORTFOLIO_METRICS.TABLE.BENCHMARK")}</TinyTableHeadCell>
			),
			align: "end",
			content: ({ benchmarkValue, type }, props) => {
				const label = t(`REPORT_BUILDER.PORTFOLIO_METRICS.METRICS.${type!}`) ?? "-";
				return (
					<TinyTableDataCell {...props}>{`${formatNumber(benchmarkValue)}${
						label.toLowerCase().includes("ratio") ? "" : "%"
					}`}</TinyTableDataCell>
				);
			},
			hidden: !benchmark,
		},
		{
			name: "empty",
			minWidth: 0,
			header: (props) => <TinyTableHeadCell {...props}>{null}</TinyTableHeadCell>,
			content: (_, props) => <TinyTableDataCell {...props}>{null}</TinyTableDataCell>,
		},
	] satisfies TableV2.TableColumn<PortfolioMetric>[];

	return (
		<Card title={t("REPORT_BUILDER.PORTFOLIO_METRICS.TITLE")}>
			<TableV2.BaseTable palette="uniform" columns={columns} rows={list} noDataText={t("COMPOSITION.NO_COMPOSITION")} />
		</Card>
	);
}

import type {
	MarketViewListEntry,
	MarketViewSettings,
	UserMarketViewColumnOrdering,
	UserMarketViewColumnPreference,
	UserMarketViewColumnPreferencePreferencesTypeEnum,
} from "$root/api/api-gen";
import {
	IntegrationsControllerApiFactory,
	MarketViewControllerApiFactory,
	MarketViewSettingsActiveMarketViewTypesEnum,
	PortfolioStudioPreferencesApiFactory,
} from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import AuthorizationGuard from "$root/components/AuthorizationGuard";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import CreateMarketViewCustomIcon from "$root/components/glyphs/CreateMarketViewCustomIcon";
import CreateMarketViewPositioningIconV2 from "$root/components/glyphs/CreateMarketViewPositioningIconV2";
import CreateMarketViewRegimeIconV2 from "$root/components/glyphs/CreateMarketViewRegimeIconV2";
import type { ColumnMetadata } from "$root/components/tables-extra/CustomizeColumns";
import { CustomizeColumns } from "$root/components/tables-extra/CustomizeColumns";
import { aclByArea, roleByArea } from "$root/functional-areas/acl/checkers/all";
import { validateACLPermissions } from "$root/functional-areas/acl/checkers/shared";
import { useUpdateAccessListControlDialog } from "$root/functional-areas/acl/hook/useUpdateAccessListControlDialog";
import type { UsePerformCrudActions } from "$root/hooks/usePerformCrud";
import usePerformCrud from "$root/hooks/usePerformCrud";
import { platformToast } from "$root/notification-system/toast";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { preventSubmitOnPressEnter } from "$root/utils/experimental";
import { nullary } from "$root/utils/functions";
import { useDebouncedMemo } from "$root/utils/react-extra";
import type { DropdownMenuProps } from "@mdotm/mdotui/components";
import {
	BatchActions,
	Button,
	Dialog,
	DropdownMenuActionButton,
	Icon,
	TableV2,
	TextInput,
	contextMenuHandler,
	sortRows,
} from "@mdotm/mdotui/components";
import type { MultiSelectCtx } from "@mdotm/mdotui/headless";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { QueryObserverBaseResult } from "@tanstack/react-query";
import { Map } from "immutable";
import type { FC } from "react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CrudModal } from "..";
import { usePortfolioStudioTableSettings } from "../portfolio-studio-table-settings";
import { useMarketViewColumn } from "./columns";
import { useUserValue } from "$root/functional-areas/user";
import { actionsColumn } from "$root/ui-lib/interactive-collections/common-table-actions";
import ReactQueryWrapper from "$root/components/ReactQueryWrapper";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { spawnMarketViewImportDialog } from "$root/functional-areas/market-view/Import";
import { noop } from "$root/utils/runtime";

type MarketViewsListProps = {
	markets: MarketViewListEntry[];
	marketViewSetting?: MarketViewSettings;
	deleteMultiSelectCtx: MultiSelectCtx<string>;
	columnsPreferences?: UserMarketViewColumnPreference[];
	refetch: {
		marketView: QueryObserverBaseResult<MarketViewListEntry[]>["refetch"];
		columnsPreferences: QueryObserverBaseResult<UserMarketViewColumnOrdering>["refetch"];
	};
};

const MarketViewsList: FC<MarketViewsListProps> = ({
	markets,
	deleteMultiSelectCtx,
	refetch,
	marketViewSetting,
	columnsPreferences,
}) => {
	const [showNewMarketView, setShowNewMarketView] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [isPortfolioNameAvailable, setIsPorfolioNameAvailable] = useState(true);
	const [customizeTableModalVisible, setCustomizeTableModalVisible] = useState(false);

	const [modalData, setModalData] = useState<{
		open: boolean;
		action: UsePerformCrudActions | "share";
		portfolioName: string;
		uuid: string;
	}>({
		open: false,
		action: "delete",
		portfolioName: "",
		uuid: "",
	});

	const { performAction } = usePerformCrud("market");
	const { aclSpawn } = useUpdateAccessListControlDialog("MARKET_VIEW");
	const { t } = useTranslation();
	const { push } = useTypedNavigation();
	const marketViewApi = useApiGen(MarketViewControllerApiFactory);
	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);
	const integrationsApi = useApiGen(IntegrationsControllerApiFactory);

	const user = useUserValue();

	const { value: debouncedLowerCaseSearchQuery } = useDebouncedMemo(
		() => searchQuery.toLocaleLowerCase(),
		[searchQuery],
		{
			debounceInterval: 200,
		},
	);

	const filteredRows = useMemo(
		() =>
			markets.filter((item) =>
				[item.name ?? "", item.forecastHorizon ?? ""].some((el) =>
					el.toLowerCase().includes(debouncedLowerCaseSearchQuery),
				),
			),
		[debouncedLowerCaseSearchQuery, markets],
	);

	const marketByUid = useMemo(() => Map(markets.map((p) => [p.uuid ?? "", p])), [markets]);

	const { column: checkboxColumn, rowClassList } = TableV2.useSelectableTableColumn({
		rows: markets,
		filteredRows,
		selectBy: (r) => r.uuid!,
		multiSelectCtx: deleteMultiSelectCtx,
	});

	const { marketViewListOrderByName, setMarketViewListOrderByName } = usePortfolioStudioTableSettings();
	const visibleColumns = useMarketViewColumn(columnsPreferences, marketViewSetting);

	const getDropdownActions = useCallback(
		({
			name,
			uuid,
			type,
			richAcl,
			custom,
		}: MarketViewListEntry): DropdownMenuProps<HTMLButtonElement, "">["actions"] => {
			return [
				({ onClose }) => (
					<AuthorizationGuard permissionChecker={aclByArea.marketView.canDelete} acl={richAcl?.acl ?? []}>
						<DropdownMenuActionButton
							icon="Delete"
							onClick={() => {
								setModalData({ open: true, action: "delete", portfolioName: name ?? "", uuid: uuid ?? "" });
								onClose();
							}}
							data-qualifier="PortfolioStudio/MarketViewList/DropdownMenu/DropdownItem(Delete)"
						>
							Delete
						</DropdownMenuActionButton>
					</AuthorizationGuard>
				),
				({ onClose }) => (
					<AuthorizationGuard permissionChecker={aclByArea.marketView.canRename} acl={richAcl?.acl ?? []}>
						<DropdownMenuActionButton
							icon="Edit"
							onClick={() => {
								setModalData({ open: true, action: "rename", portfolioName: name ?? "", uuid: uuid ?? "" });
								onClose();
							}}
							data-qualifier="PortfolioStudio/MarketViewList/DropdownMenu/DropdownItem(Rename)"
						>
							Rename
						</DropdownMenuActionButton>
					</AuthorizationGuard>
				),
				({ onClose }) => (
					<DropdownMenuActionButton
						icon="Content-Copy"
						onClick={() => {
							push("MarketViewWorkSpace", {
								action: "clone",
								uuid: uuid!,
								type: type!,
								isCustom: String(Boolean(custom)),
							});
							onClose();
						}}
						data-qualifier="PortfolioStudio/MarketViewList/DropdownMenu/DropdownItem(Duplicate)"
					>
						Duplicate
					</DropdownMenuActionButton>
				),
				({ onClose }) => (
					<AuthorizationGuard
						permissionChecker={(userId, acl) => validateACLPermissions(userId, acl, roleByArea.marketView.EDITOR)}
						acl={richAcl?.acl ?? []}
					>
						<DropdownMenuActionButton
							icon="share" //TODO: change icon with share
							onClickAsync={async () => {
								await aclSpawn(name, uuid, refetch.marketView);
								onClose();
							}}
							data-qualifier="PortfolioStudio/MarketViewList/DropdownMenu/DropdownItem(Share)"
						>
							Share
						</DropdownMenuActionButton>
					</AuthorizationGuard>
				),
			];
		},
		[push, refetch, aclSpawn],
	);

	const columns = useMemo<TableV2.TableColumn<MarketViewListEntry>[] | null>(
		() =>
			visibleColumns
				? [
						checkboxColumn,
						...visibleColumns,
						actionsColumn({
							onSettingsClick: () => setCustomizeTableModalVisible(true),
							triggerProps: {
								"data-qualifier": "PortfolioStudio/UniverseList/DropdownMenu",
							},
							dropdownActions: getDropdownActions,
						}),
				  ]
				: null,
		[visibleColumns, checkboxColumn, getDropdownActions],
	);

	const filteredSortedRows = useMemo(
		() => (columns ? sortRows({ rows: filteredRows, columns, orderByArr: marketViewListOrderByName }) : filteredRows),
		[columns, filteredRows, marketViewListOrderByName],
	);

	const onCloseModal = useCallback(() => {
		setModalData({ open: false, action: modalData.action, uuid: "", portfolioName: "" });
		setIsPorfolioNameAvailable(true);
	}, [modalData.action]);

	const onDelete = useCallback(
		async (uuid: string, name: string) => {
			try {
				await performAction({ action: "delete", uuid });

				trackMixPanelEvent(`Portfolio`, {
					Type: "Delete",
					ID: uuid,
					Name: name,
				});

				await refetch.marketView({ throwOnError: true });
				platformToast({
					children: t("PUSH_NOTIFICATION.SUCCESS_DELETE_MARKET_VIEW", { name }),
					severity: "success",
					icon: "Portfolio",
				});
			} catch (error) {
				reportPlatformError(error, "ERROR", "market-views", `delete market view "${uuid}"`);
				platformToast({
					children: t("SOMETHING_WENT_WRONG"),
					severity: "error",
					icon: "Portfolio",
				});
			} finally {
				onCloseModal();
			}
		},
		[onCloseModal, performAction, refetch, t],
	);

	const onSubmit = useCallback(
		async (action: Exclude<UsePerformCrudActions, "delete">, uuid: string, name: string) => {
			try {
				const { data: isNameAvailable } = await marketViewApi.isMarketViewNameAvailable(name);
				if (!isNameAvailable) {
					setIsPorfolioNameAvailable(isNameAvailable);
					return;
				}

				await performAction({ action, uuid, name });
				await refetch.marketView({ throwOnError: true });

				const notficationMessage: Record<typeof action, string> = {
					duplicate: t("MODAL.MARKET_DUPLICATE_TITLE_SUCCESS", { portfolioName: name }),
					rename: t("MODAL.MARKET_RENAME_TITLE_SUCCESS", { portfolioName: name }),
				};
				platformToast({
					children: notficationMessage[action],
					severity: "success",
					icon: "Portfolio",
				});

				onCloseModal();
			} catch (error) {
				reportPlatformError(error, "ERROR", "market-views", `${action} market view "${uuid}"`);
				platformToast({
					children: t("SOMETHING_WENT_WRONG"),
					severity: "error",
					icon: "Portfolio",
				});
			}
		},
		[marketViewApi, onCloseModal, performAction, refetch, t],
	);

	const onChangeTableColumn = useCallback(
		async (data: ColumnMetadata<UserMarketViewColumnPreferencePreferencesTypeEnum>[]) => {
			try {
				const payload = {
					userMarketViewColumnPreferences: data.map((preference) => ({
						enabled: preference.visible,
						preferencesType: preference.id,
					})),
				} satisfies UserMarketViewColumnOrdering;
				await portfolioStudioPreferencesApi.setUserMarketViewColumnMetricsOrderingPreferences(payload);
				await refetch.columnsPreferences({ throwOnError: true });
				platformToast({
					children: "Table Successfully Edited.",
					severity: "success",
					icon: "Settings",
				});
			} catch (error) {
				platformToast({
					children: "Failed to update table",
					severity: "error",
					icon: "Settings",
				});
				throw new Error(String(error));
			} finally {
				setCustomizeTableModalVisible(false);
			}
		},
		[portfolioStudioPreferencesApi, refetch],
	);

	const customizableColumns = (columnsPreferences ?? []).map((c) => ({
		label: c.preferencesType ? t(`TABLE.HEADERS.${c.preferencesType}`) : "-",
		id: c.preferencesType!,
		visible: c.enabled ?? false,
		disabled: c.preferencesType === "NAME",
		hidden: c.preferencesType === "NAME",
	}));

	return (
		<>
			<CustomizeColumns
				show={customizeTableModalVisible}
				onClose={() => setCustomizeTableModalVisible(false)}
				columns={customizableColumns}
				// limit={Math.min(7, customizableColumns?.filter((x) => x.hidden === false).length ?? 1)}
				onSubmitAsync={onChangeTableColumn}
			/>

			<div className="flex justify-between items-center">
				<TextInput
					value={searchQuery}
					name="search"
					maxLength={60}
					onChangeText={setSearchQuery}
					placeholder={t("PORTFOLIOS.CONSTRAINTS_TARGETS.PORTFOLIO_LIST")}
					onKeyDown={preventSubmitOnPressEnter}
					style={{ width: 692 }}
					leftContent={<Icon icon="Search" size="1.4em" />}
				/>

				<div className="flex gap-2">
					<Button
						size="small"
						palette="secondary"
						onClick={() =>
							spawnMarketViewImportDialog({
								converters: ["SPHERE_TEMPLATE_CONVERTER"] as const,
								onImportFinished: ({ imported }) => {
									if (imported.length) {
										refetch.marketView({ throwOnError: true }).catch(noop);
									}
								},
							})
						}
					>
						<Icon icon="Dowload" size={16} classList="-rotate-90 mr-1" />
						Import
					</Button>

					<Button
						palette="secondary"
						size="small"
						onClick={() => setShowNewMarketView(true)}
						data-qualifier="PortfolioStudio/MarketViewList/NewMarketView"
					>
						<span className="mr-1.5 h-fit">
							<Icon icon="Outline1" size={16} />
						</span>
						<p className="tracking-wide">Create market view</p>
					</Button>
				</div>
			</div>

			<BatchActions
				classList="my-2"
				selected={deleteMultiSelectCtx.data.selection.size}
				total={markets.length}
				actions={[
					{
						label: "Delete",
						icon: "Delete",
						onClick: nullary(deleteMultiSelectCtx.actions.confirmSelection),
						disabled: deleteMultiSelectCtx.data.selection.some(
							(id) => aclByArea.portfolio.canDelete(user.id, marketByUid?.get(id)?.richAcl?.acl ?? []) === false,
						),
					},
				]}
			/>
			{columns && (
				<TableV2.BaseHScrollTable
					onRowClick={(row) => row.uuid && deleteMultiSelectCtx.actions.toggle(row.uuid)}
					onRowContextMenu={(row, _index, e) => contextMenuHandler(e, getDropdownActions(row))}
					key="referenceListTable"
					columns={columns}
					rows={filteredSortedRows}
					rowClassList={rowClassList}
					rowStyle={({ uuid }) => {
						const selected = deleteMultiSelectCtx.data.selection?.has(uuid!);
						return selected ? { backgroundColor: themeCSSVars.Table_highlightedRowBackgroundColor } : {};
					}}
					// TODO: refactor
					style={{ maxHeight: "calc(100dvh - 160px)" }}
					noDataText="No data available"
					pinnedColumns={[
						{ name: "name", side: "left" },
						{ name: "settings-action", side: "right" },
					]}
					orderBy={marketViewListOrderByName}
					onOrderByChange={setMarketViewListOrderByName}
				/>
			)}
			<Dialog
				show={showNewMarketView}
				size={
					marketViewSetting?.activeMarketViewTypes?.length === 3
						? "large"
						: marketViewSetting?.activeMarketViewTypes?.length === 2
						  ? "medium"
						  : "small"
				}
				onClose={() => setShowNewMarketView(false)}
			>
				<div className="flex gap-4 mx-auto">
					{marketViewSetting?.activeMarketViewTypes?.includes(
						MarketViewSettingsActiveMarketViewTypesEnum.ExpectedReturnsVolatility,
					) && (
						<Link
							to={typedUrlForRoute("MarketViewWorkSpace/New", {
								type: MarketViewSettingsActiveMarketViewTypesEnum.ExpectedReturnsVolatility,
							})}
							className={`hover:bg-[color:${themeCSSVars.palette_N50}] hover:rounded-md p-4 flex-1`}
							data-qualifier="PortfolioStudio/MarketViewList/NewMarketView/ExpectedReturns"
						>
							<div className="w-[112px] h-[115px] overflow-hidden mx-auto">
								<CreateMarketViewRegimeIconV2
									style={{ width: 117, height: 120 }}
									viewBox={{ width: 117, height: 120 }}
								/>
							</div>
							<p className="text-lg font-semibold text-center">Expected returns</p>
							<p className="text-center">Set your market view regimes</p>
						</Link>
					)}
					{marketViewSetting?.activeMarketViewTypes?.includes(
						MarketViewSettingsActiveMarketViewTypesEnum.PositioningIndicators,
					) && (
						<Link
							to={typedUrlForRoute("MarketViewWorkSpace/New", {
								type: MarketViewSettingsActiveMarketViewTypesEnum.PositioningIndicators,
							})}
							className={`hover:bg-[color:${themeCSSVars.palette_N50}] hover:rounded-md p-4 flex-1`}
							data-qualifier="PortfolioStudio/MarketViewList/NewMarketView/UnderOverWeight"
						>
							<CreateMarketViewPositioningIconV2 classList="mx-auto" />
							<p className="text-lg font-semibold text-center">Under / over weight</p>
							<p className="text-center">Set your market view positioning</p>
						</Link>
					)}
					{marketViewSetting?.activeMarketViewTypes?.includes(MarketViewSettingsActiveMarketViewTypesEnum.Custom) && (
						<Link
							to={typedUrlForRoute("MarketViewWorkSpace/New", {
								type: marketViewSetting.customMarketViewType!,
								isCustom: "true",
							})}
							className={`hover:bg-[color:${themeCSSVars.palette_N50}] hover:rounded-md p-4 flex-1`}
							data-qualifier="PortfolioStudio/MarketViewList/NewMarketView/Custom"
						>
							<CreateMarketViewCustomIcon classList="mx-auto" />
							<p className="text-lg font-semibold text-center">{marketViewSetting.customMarketViewName ?? "Custom"}</p>
							<p className="text-center">Set your custom market view</p>
						</Link>
					)}
				</div>
			</Dialog>
			{modalData.action === "delete" && (
				<CrudModal
					show={modalData.open}
					action={modalData.action}
					title={t("PORTFOLIOS.MODAL.SINGLE_DELETE.TITLE")}
					portfolioName={modalData.portfolioName}
					onCancel={onCloseModal}
					onClose={onCloseModal}
					onSubmit={() => onDelete(modalData.uuid, modalData.portfolioName)}
				/>
			)}

			{modalData.action === "rename" && (
				<CrudModal
					show={modalData.open}
					action={modalData.action}
					title={t("MODAL.RENAME", { name: modalData.portfolioName })}
					onCancel={onCloseModal}
					onClose={onCloseModal}
					isInvalid={!isPortfolioNameAvailable}
					onSubmit={(name) => onSubmit("rename", modalData.uuid, name)}
					portfolioName={modalData.portfolioName}
				/>
			)}
		</>
	);
};

export default MarketViewsList;

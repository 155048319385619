import type { FileResponse } from "$root/api/api-gen";
import Footer from "$root/components/EvolvedPrint/components/pdf/Footer";
import Header from "$root/components/EvolvedPrint/components/pdf/Header";
import type { ComponentAndPropsPair } from "$root/components/EvolvedPrint/configuration";
import { Renderer } from "$root/components/EvolvedPrint/configuration";
import {
	firstPageHeaderHeight,
	otherPagesHeaderHeight,
	pageFooterHeight,
	paperPlaneId,
} from "$root/components/EvolvedPrint/configuration/shared";
import { mmToPx } from "$root/components/EvolvedPrint/configuration/utils";
import { CircularProgressBar } from "@mdotm/mdotui/components";
import { toClassName } from "@mdotm/mdotui/react-extensions";
import { useEffect, useRef, useState } from "react";

export default function Portrait(props: {
	title: string;
	subtitle: string;
	footer?: {
		logo?: FileResponse;
		description?: string;
	};
	componentAndPropsList: Array<ComponentAndPropsPair<any, any>>;
	autoScroll?: boolean;
}): JSX.Element {
	const [isReady, setIsReady] = useState(false);
	const A4PortraitHeight_mm = 297;
	const A4PortraitWidth_mm = 210;
	const portraitContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		let intervalId: ReturnType<typeof setInterval> | undefined = undefined;
		if (portraitContainerRef.current !== null && props.autoScroll) {
			intervalId = setInterval(() => {
				const reportTemplateRenderId = document.getElementById(paperPlaneId);

				if (reportTemplateRenderId && intervalId) {
					clearInterval(intervalId);
				}
				portraitContainerRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
			}, 700);

			portraitContainerRef.current.addEventListener("wheel", () => {
				clearInterval(intervalId);
			});
		}
	}, [props.componentAndPropsList, props.autoScroll]);

	return (
		<div
			ref={portraitContainerRef}
			className={toClassName({
				"overflow-scroll": props.autoScroll,
			})}
		>
			{isReady ? (
				<div key="ready" data-role="start-printing" data-qualifier="pdfReport/promiseId" id={paperPlaneId} />
			) : (
				<CircularProgressBar
					key="loading"
					value="indeterminate"
					classList="fixed top-2 right-2 z-50"
					outerDiameter={36}
				/>
			)}
			<div data-size="A4portrait" className="page_brake">
				{props.componentAndPropsList[0].component({
					...props.componentAndPropsList[0].commonProps,
					list: props.componentAndPropsList[0].splittableProps,
					firstRender: true,
					availableSpace: mmToPx(A4PortraitHeight_mm),
				})}
			</div>
			<div data-size="A4Template">
				<Renderer
					pageHeader={(forward) => <Header {...forward} title={props.title} subtitle={props.subtitle} />}
					pageFooter={(forward) => (
						<Footer {...forward} customLogo={props.footer?.logo} customDescription={props.footer?.description} />
					)}
					firstPageHeaderHeight={firstPageHeaderHeight}
					otherPagesHeaderHeight={otherPagesHeaderHeight}
					pageFooterHeight={pageFooterHeight}
					pageHeight={mmToPx(A4PortraitHeight_mm)}
					pageWidth={mmToPx(A4PortraitWidth_mm)}
					componentAndPropsList={props.componentAndPropsList.slice(1, props.componentAndPropsList.length)}
					onFinish={() => setIsReady(true)}
				/>
			</div>
			{/* <div data-size="A4portrait" className="page_brake">
				{props.componentAndPropsList.at(-1)!.component({
					...props.componentAndPropsList.at(-1)!.commonProps,
					list: props.componentAndPropsList.at(-1)!.splittableProps,
					firstRender: true,
					availableSpace: mmToPx(A4PortraitHeight_mm),
				})}
			</div> */}
		</div>
	);
}

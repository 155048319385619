import type { InvestmentSummary, ReviewTicker } from "$root/api/api-gen";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { CustomLabels } from "$root/components/CustomLabels";
import { Card } from "$root/components/EvolvedPrint/components/Card";
import { TinyTableDataCell, TinyTableHeadCell } from "$root/components/EvolvedPrint/components/table/tiny-table";
import type { PrintableProps } from "$root/components/EvolvedPrint/configuration";
import { InfoDelta } from "$root/components/InfoDelta";
import { useUserValue } from "$root/functional-areas/user";
import { useLocaleFormatters } from "$root/localization/hooks";
import type { Languages } from "$root/localization/i18n";
import type {
	ReportTemplateItemMap,
	ReportTemplateVariant,
} from "$root/pages/PortfolioStudioSettings/ReportEditor/version/report-v1";
import { TableV2 } from "@mdotm/mdotui/components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

const Composition = ({
	config,
	variant,
	portfolio,
	language = "en",
	list,
}: PrintableProps<
	{
		config: ReportTemplateItemMap["composition"];
		variant: ReportTemplateVariant;
		portfolio: InvestmentSummary;
		language?: Languages;
	},
	ReviewTicker
>): JSX.Element => {
	const { t } = useTranslation(undefined, { lng: language });
	const user = useUserValue();
	const formatters = useLocaleFormatters();
	const { formatNumber } = formatters;

	const preferences = variant === "current" ? config.currentColumnPreferences : config.proposalColumnPreferences;

	const columns = useMemo(
		() =>
			preferences.map(
				(preference) =>
					({
						header: (props) => (
							<TinyTableHeadCell {...props}>
								{t(`REPORT_BUILDER.COMPOSITION.TABLE.${preference.column}`)}
							</TinyTableHeadCell>
						),
						hidden: preference.enabled === false,
						...match(preference)
							.with(
								{ column: "INSTRUMENT_NAME" },
								(): Omit<TableV2.TableColumn<ReviewTicker>, "header"> => ({
									name: "instrument",
									width: variant === "proposal" ? 144 : undefined,
									minWidth: variant === "current" ? 104 : undefined,
									content: ({ instrument }, props) => (
										<TinyTableDataCell fontSize={9} lineClamp={3} {...props}>
											{instrument ?? ""}
										</TinyTableDataCell>
									),
								}),
							)
							.with(
								{ column: "IDENTIFIER" },
								(): Omit<TableV2.TableColumn<ReviewTicker>, "header"> => ({
									name: "identifier",
									width: 104,
									content: ({ identifier }, props) => (
										<TinyTableDataCell {...props}>{identifier ?? ""}</TinyTableDataCell>
									),
								}),
							)
							.with(
								{ column: "ASSET_CLASS" },
								(): Omit<TableV2.TableColumn<ReviewTicker>, "header"> => ({
									name: "assetClass",
									width: 85,
									content: ({ assetClass }, props) => (
										<TinyTableDataCell {...props}>{assetClass ?? ""}</TinyTableDataCell>
									),
								}),
							)
							.with(
								{ column: "SCORE" },
								(): TableV2.TableColumn<ReviewTicker> => ({
									name: "score",
									width: 50,
									header: (props) => (
										<TinyTableHeadCell {...props}>
											{portfolio?.scoreIdentifier ? (
												<CustomLabels
													labelKey={portfolio?.scoreIdentifier}
													fallback={t("REPORT_BUILDER.COMPOSITION.TABLE.SCORE")}
												/>
											) : (
												t("REPORT_BUILDER.COMPOSITION.TABLE.SCORE")
											)}
										</TinyTableHeadCell>
									),
									content: ({ score }, props) => (
										<TinyTableDataCell {...props}>{score ? formatNumber(score) : ""}</TinyTableDataCell>
									),
									hidden: preference.enabled === false || !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }),
								}),
							)
							.with(
								{ column: "WEIGHT" },
								(): Omit<TableV2.TableColumn<ReviewTicker>, "header"> => ({
									name: "weight",
									width: 60,
									cellClassList: "tabular-nums",
									content: ({ weight }, props) => (
										<TinyTableDataCell {...props}>{`${formatNumber(weight ?? 0)}%`}</TinyTableDataCell>
									),
									align: "end",
								}),
							)
							.with(
								{ column: "MICRO_ASSET_CLASS" },
								(): Omit<TableV2.TableColumn<ReviewTicker>, "header"> => ({
									name: "microAssetClass",
									width: 144,
									content: ({ microAssetClass }, props) => (
										<TinyTableDataCell fontSize={9} lineClamp={3} {...props}>
											{microAssetClass ?? ""}
										</TinyTableDataCell>
									),
								}),
							)
							.with(
								{ column: "CURRENT_WEIGHT" },
								(): Omit<TableV2.TableColumn<ReviewTicker>, "header"> => ({
									name: "previousWeight",
									width: 60,
									cellClassList: "tabular-nums",
									content: ({ previousWeight }, props) => (
										<TinyTableDataCell {...props}>{`${formatNumber(previousWeight ?? 0)}%`}</TinyTableDataCell>
									),
									align: "end",
								}),
							)
							.with(
								{ column: "ENHANCED_WEIGHT" },
								(): Omit<TableV2.TableColumn<ReviewTicker>, "header"> => ({
									name: "weight",
									width: 60,
									cellClassList: "tabular-nums",
									content: ({ weight }, props) => (
										<TinyTableDataCell {...props}>{`${formatNumber(weight ?? 0)}%`}</TinyTableDataCell>
									),
									align: "end",
								}),
							)
							.with(
								{ column: "DIFFERENCE" },
								(): Omit<TableV2.TableColumn<ReviewTicker>, "header"> => ({
									name: "difference",
									width: 96,
									align: "end",
									content: (instrument, props) => {
										const deltaWeight = Number((instrument.weight ?? 0) - (instrument.previousWeight ?? 0)).toFixed(2);
										return (
											<TinyTableDataCell {...props}>
												<InfoDelta diff={Number(deltaWeight) ?? 0} enh={instrument.weight ?? 0} />
											</TinyTableDataCell>
										);
									},
								}),
							)
							.exhaustive(),
					}) as TableV2.TableColumn<ReviewTicker>,
			),
		[formatNumber, portfolio?.scoreIdentifier, preferences, t, user, variant],
	);

	return (
		<Card title={t("REPORT_BUILDER.COMPOSITION.TITLE")}>
			<TableV2.BaseTable palette="uniform" columns={columns} rows={list} noDataText={t("COMPOSITION.NO_COMPOSITION")} />
		</Card>
	);
};

export default Composition;

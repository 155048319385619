import { FunctionalAreasContext } from "$root/App/context";
import type {
	PageDto,
	SyncInfoDto,
	UserPortfolioColumnOrdering,
	UserPortfolioColumnPreference,
	UserPortfolioColumnPreferencePreferencesTypeEnum,
	InvestmentExportConverterType,
	InvestmentCommentaryDTO,
} from "$root/api/api-gen";
import {
	IntegrationsControllerApiFactory,
	InvestmentBulkBulkStaticConfigurationControllerV1ApiFactory,
	InvestmentBulkEnhancementConfigurationControllerV4ApiFactory,
	InvestmentControllerV4ApiFactory,
	InvestmentsReportTemplateControllerApiFactory,
	PdfControllerApiFactory,
	PortfolioStudioPreferencesApiFactory,
	type InvestmentListEntry,
	type InvestmentStatuses,
	CommentaryTemplateControllerApiFactory,
} from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import AuthorizationGuard, { hasAccess } from "$root/components/AuthorizationGuard";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import ReactQueryWrapper from "$root/components/ReactQueryWrapper";
import CreatePortfolioIcon from "$root/components/glyphs/CreatePortfolioIcon";
import UploadPortfolioIcon from "$root/components/glyphs/UploadPortfolioIcon";
import type { ColumnMetadata } from "$root/components/tables-extra/CustomizeColumns";
import { CustomizeColumns } from "$root/components/tables-extra/CustomizeColumns";
import { aclByArea, roleByArea } from "$root/functional-areas/acl/checkers/all";
import { validateACLPermissions } from "$root/functional-areas/acl/checkers/shared";
import { useUpdateAccessListControlDialog } from "$root/functional-areas/acl/hook/useUpdateAccessListControlDialog";
import type { CompareDataItem, CompareOverlayProps } from "$root/functional-areas/compare-portfolio/CompareOverlay";
import { CompareOverlay } from "$root/functional-areas/compare-portfolio/CompareOverlay";
import { spawnInvestmentImportDialog } from "$root/functional-areas/portfolio/Import";
import { useUserValue } from "$root/functional-areas/user";
import useCompositionDownload from "$root/hooks/useCompositionDownload";
import type { UsePerformCrudActions } from "$root/hooks/usePerformCrud";
import usePerformCrud from "$root/hooks/usePerformCrud";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { actionsColumn } from "$root/ui-lib/interactive-collections/common-table-actions";
import { customObjectEntriesFn, preventSubmitOnPressEnter } from "$root/utils/experimental";
import { nullary } from "$root/utils/functions";
import { parallelize } from "$root/utils/promise";
import { useDebouncedMemo } from "$root/utils/react-extra";
import type {
	ActionWithOptionalGroup,
	BatchAction,
	DropdownMenuProps,
	Option,
	TableColumn,
} from "@mdotm/mdotui/components";
import {
	BatchActions,
	Button,
	Checkbox,
	Dialog,
	DialogFooter,
	DropdownMenuActionButton,
	Icon,
	SubmitButton,
	Table,
	TableV2,
	TextInput,
	contextMenuHandler,
	sortRows,
} from "@mdotm/mdotui/components";
import { type MultiSelectCtx } from "@mdotm/mdotui/headless";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import {
	adaptAnimatedNodeProvider,
	generateUniqueDOMId,
	spawn,
	toClassListRecord,
} from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor, groupBy, noop } from "@mdotm/mdotui/utils";
import type { QueryObserverBaseResult } from "@tanstack/query-core";
import equal from "fast-deep-equal";
import { Map, Set } from "immutable";
import type { FC } from "react";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CrudModal } from "..";
import { usePortfolioStudioTableSettings } from "../portfolio-studio-table-settings";
import { usePortfolioColumn } from "./columns";
import type { ReportTemplateVariant } from "$root/pages/PortfolioStudioSettings/ReportEditor/version/report-v1";
import { defaultPortfolioTemplates, isDefaultReportTemplate } from "$root/functional-areas/reports/default-templates";
import { PortfolioStudioSettingTabEnum } from "$root/functional-areas/portfolio-studio-settings";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { format } from "date-fns";
import { PortfolioDetailsTabs } from "$root/pages/PortfolioDetails";

const isPortfolioEnhancable = (status?: InvestmentStatuses) => status === "READY" || status === "ACCEPTED";

type PortfolioListProps = {
	portfolios: Array<InvestmentListEntry>;
	columnsPreferences?: UserPortfolioColumnPreference[];
	deleteMultiSelectCtx: MultiSelectCtx<string>;
	userSyncInfo?: SyncInfoDto | undefined;
	refetch: {
		investments: QueryObserverBaseResult<InvestmentListEntry[]>["refetch"];
		columnsPreferences: QueryObserverBaseResult<UserPortfolioColumnOrdering>["refetch"];
	};
	isVisible: boolean;
};

type InvestmentTemplateChooser = { name?: string; uuid: string; status?: InvestmentStatuses };
type UserPreferenceMap = Map<string, { current: boolean; enhance: boolean }>;
type TemplateChooserSubmitParams = { name?: string; uuid: string; choice: "current" | "enhance" };
type PortfolioTemplateChooserDialogProps = {
	show: boolean;
	onClose?(): void;
	investments: Array<InvestmentTemplateChooser>;
	onSubmitAsync(investments: Array<TemplateChooserSubmitParams>): Promise<void>;
};
const PortfolioTemplateChooserDialog = (props: PortfolioTemplateChooserDialogProps) => {
	const { t } = useTranslation();

	const proposalReadyInvestments = useMemo(() => {
		return props.investments.filter((investment) => investment.status === "PROPOSAL_READY");
	}, [props.investments]);

	const [userPereference, _setUserPereference] = useState<UserPreferenceMap>(Map());
	const userPreferenceRef = useRef<UserPreferenceMap>(
		Map(proposalReadyInvestments.map(({ uuid }) => [uuid, { current: true, enhance: false }])),
	);
	function setUserPereference(newMap: UserPreferenceMap) {
		_setUserPereference(newMap);
		userPreferenceRef.current = newMap;
	}

	const columns = useMemo<Array<TableColumn<InvestmentTemplateChooser>>>(
		() => [
			{
				header: t("TABLE.HEADERS.NAME"),
				content: ({ name }) => name ?? "",
				sortFn: builtInSortFnFor("name"),
				name: "portfolio name",
				relativeWidth: 0.6,
			},
			{
				header: (
					<div className="flex space-x-1">
						<Checkbox
							checked={
								userPreferenceRef.current.filter((x) => x.current).size === proposalReadyInvestments.length
									? true
									: userPreferenceRef.current.filter((x) => x.current).size === 0
									  ? false
									  : "indeterminate"
							}
							onChange={(selection) =>
								setUserPereference(
									Map(
										proposalReadyInvestments.map((investment) => {
											const preference = userPreferenceRef.current.get(investment.uuid);
											if (!preference) {
												return [investment.uuid, { current: selection, enhance: false }];
											}
											return [investment.uuid, { current: selection, enhance: preference.enhance }];
										}),
									),
								)
							}
						/>
						<p>Current</p>
					</div>
				),
				content: (row) => {
					const preference = userPreferenceRef.current.get(row.uuid);
					return (
						<Checkbox
							checked={preference?.current ?? false}
							onChange={(current) =>
								setUserPereference(
									userPreferenceRef.current.update(row.uuid, (x) => ({ current, enhance: x?.enhance ?? false })),
								)
							}
						/>
					);
				},
				name: "current choice",
				relativeWidth: 0.2,
			},
			{
				header: (
					<div className="flex space-x-1">
						<Checkbox
							checked={
								userPreferenceRef.current.filter((x) => x.enhance).size === proposalReadyInvestments.length
									? true
									: userPreferenceRef.current.filter((x) => x.enhance).size === 0
									  ? false
									  : "indeterminate"
							}
							onChange={(selection) =>
								setUserPereference(
									Map(
										proposalReadyInvestments.map((investment) => {
											const preference = userPreferenceRef.current.get(investment.uuid);
											if (!preference) {
												return [investment.uuid, { current: false, enhance: selection }];
											}
											return [investment.uuid, { current: preference.current, enhance: selection }];
										}),
									),
								)
							}
						/>
						<p>Enhance</p>
					</div>
				),
				content: (row) => {
					const preference = userPreferenceRef.current.get(row.uuid);
					return (
						<Checkbox
							checked={preference?.enhance ?? false}
							onChange={(enhance) =>
								setUserPereference(
									userPreferenceRef.current.update(row.uuid, (x) => ({ current: x?.current ?? false, enhance })),
								)
							}
						/>
					);
				},
				name: "enhace choice",
				relativeWidth: 0.2,
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[proposalReadyInvestments, t, userPreferenceRef.current],
	);

	return (
		<Dialog
			show={props.show}
			onClose={props.onClose}
			header="Choose which type of report you want generate"
			onSubmitAsync={async () => {
				const onSubmitAsyncParams = props.investments.flatMap((x): TemplateChooserSubmitParams[] => {
					const preference = userPereference.get(x.uuid);
					if (!preference) {
						return [{ name: x.name, uuid: x.uuid, choice: "current" }];
					}

					return customObjectEntriesFn(preference).flatMap(([key, choice]) =>
						choice ? [{ name: x.name, uuid: x.uuid, choice: key }] : [],
					);
				});

				await props.onSubmitAsync(onSubmitAsyncParams);
			}}
			footer={({ loading }) => (
				<DialogFooter
					primaryAction={<SubmitButton palette="primary"> {t("BUTTON.SAVE_PROCEED")}</SubmitButton>}
					neutralAction={
						<Button palette="tertiary" disabled={loading} onClick={props.onClose}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
				/>
			)}
			size="large"
		>
			<p className="mb-4">
				These portfolio are on <strong>Proposal Ready</strong> status choose the report template you want to generate
			</p>
			<Table
				columns={columns}
				rows={proposalReadyInvestments}
				enableVirtualScroll
				visibleRows={Math.min(proposalReadyInvestments.length, 8)}
			/>
		</Dialog>
	);
};

type spawnPortfolioTemplateChooserProps = Omit<PortfolioTemplateChooserDialogProps, "onClose" | "show">;
export function spawnPortfolioTemplateChooser(params: spawnPortfolioTemplateChooserProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ resolve, show }) => (
			<PortfolioTemplateChooserDialog
				{...params}
				show={show}
				onClose={() => resolve()}
				onSubmitAsync={async (portfolio) => {
					await params.onSubmitAsync(portfolio);
					resolve();
				}}
			/>
		)),
	);
}

type DefaultSelectionProps = { current: boolean; enhance: boolean };
const defaultSelection = { current: false, enhance: false } satisfies DefaultSelectionProps;
const CompareDialog = (props: {
	investments: Array<InvestmentListEntry>;
	comparedInvestments: Array<CompareDataItem>;
	show: boolean;
	onClose?(): void;
	onCancel(): void;
	onSubmit?(selectedInvestments: Map<string, DefaultSelectionProps>): void;
}) => {
	const { t } = useTranslation();
	const [selectedInvestments, setSelectedInvestment] = useState<Map<string, DefaultSelectionProps>>(Map());

	useEffect(() => {
		if (props.show) {
			const groupCompared = groupBy(props.comparedInvestments, (x) => x.uuid!);
			const comparedInvestment = Map<string, DefaultSelectionProps>(
				customObjectEntriesFn(groupCompared).map(([uuid, comparedItem]): [string, DefaultSelectionProps] => [
					uuid,
					{
						current: comparedItem?.some((x) => !x.enhanced) ?? false,
						enhance: comparedItem?.some((x) => x.enhanced) ?? false,
					},
				]),
			);

			setSelectedInvestment((prevInvestments) => {
				const comparedInvestmentToList = comparedInvestment.toArray();
				if (equal(prevInvestments, comparedInvestmentToList)) {
					return prevInvestments;
				}

				return comparedInvestment;
			});
		}
	}, [props.comparedInvestments, props.show]);

	const getInvestment = useCallback(
		(uuid?: string) => {
			if (!uuid) {
				return;
			}

			return selectedInvestments.get(uuid);
		},
		[selectedInvestments],
	);

	const onUpdatePreference = useCallback((opt: keyof DefaultSelectionProps, uuid?: string) => {
		return (selected: boolean) => {
			setSelectedInvestment((selections) => {
				if (!uuid) {
					return selections;
				}

				const investment = selections.get(uuid);
				if (investment === undefined) {
					return selections.set(uuid, { ...defaultSelection, [opt]: selected });
				}

				return selections.set(uuid, { ...investment, [opt]: selected });
			});
		};
	}, []);

	const columns = useMemo<TableColumn<InvestmentListEntry, string>[]>(
		() => [
			{
				header: t("TABLE.HEADERS.NAME"),
				content: (row) => row.name,
				sortFn: builtInSortFnFor("name"),
				name: "name",
				relativeWidth: 0.6,
			},
			{
				header: t("TABLE.HEADERS.CURRENT"),
				content: (row) => (
					<Checkbox
						checked={getInvestment(row.uuid)?.current ?? false}
						onChange={onUpdatePreference("current", row.uuid)}
					/>
				),
				name: "current",
				relativeWidth: 0.2,
			},
			{
				header: t("TABLE.HEADERS.PROPOSAL"),
				content: (row) => (
					<Checkbox
						checked={getInvestment(row.uuid)?.enhance ?? false}
						onChange={onUpdatePreference("enhance", row.uuid)}
					/>
				),
				name: "proposal",
				relativeWidth: 0.2,
			},
		],
		[getInvestment, t, onUpdatePreference],
	);

	return (
		<Dialog
			show={props.show}
			onClose={props.onClose}
			header="Compare portolios"
			onSubmitAsync={() => props.onSubmit?.(selectedInvestments)}
			footer={({ loading }) => (
				<DialogFooter
					primaryAction={<SubmitButton palette="primary">Proceed</SubmitButton>}
					neutralAction={
						<Button disabled={loading} onClick={props.onCancel}>
							Cancel
						</Button>
					}
				/>
			)}
			size="large"
		>
			<div>
				<p className="mb-4">
					The following portfolios have proposals ready. Please choose which you&apos;d like to compare: the portfolio,
					the proposal, or both.
				</p>
				<Table rows={props.investments.filter(({ status }) => status === "PROPOSAL_READY")} columns={columns} />
			</div>
		</Dialog>
	);
};

const PortfolioList: FC<PortfolioListProps> = ({
	portfolios,
	refetch,
	deleteMultiSelectCtx: tableMultiSelectCtx,
	isVisible,
	userSyncInfo,
	columnsPreferences,
}) => {
	const { state } = useContext(FunctionalAreasContext);
	const [searchQuery, setSearchQuery] = useState("");
	const [isPortfolioNameAvailable, setIsPorfolioNameAvailable] = useState(true);
	const [showNewPortfolio, setShowNewPortfolio] = useState(false);
	const [showCompareDialog, setShowCompareDialog] = useState(false);
	const [customizeTableModalVisible, setCustomizeTableModalVisible] = useState(false);
	const [comparablePortfolioData, setComparablePortfolioData] = useState<CompareOverlayProps["compareData"]>([]);
	const selection = useMemo(() => tableMultiSelectCtx.data.selection.toArray(), [tableMultiSelectCtx]);
	const user = useUserValue();

	const [modalData, setModalData] = useState<{
		open: boolean;
		action: UsePerformCrudActions;
		portfolioName: string;
		uuid: string;
	}>({
		open: false,
		action: "delete",
		portfolioName: "",
		uuid: "",
	});

	const { performAction } = usePerformCrud("portfolio");
	const { aclSpawn } = useUpdateAccessListControlDialog("INVESTMENT");

	const { t } = useTranslation();

	const investmentApi = useApiGen(InvestmentControllerV4ApiFactory);
	const bulkEnhancementApi = useApiGen(InvestmentBulkEnhancementConfigurationControllerV4ApiFactory);
	const investmentBulkBulkStaticV1Api = useApiGen(InvestmentBulkBulkStaticConfigurationControllerV1ApiFactory);
	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);
	const pdfControllerV3Api = useApiGen(PdfControllerApiFactory);
	const integrationsApi = useApiGen(IntegrationsControllerApiFactory);
	const investmentsReportTemplateApi = useApiGen(InvestmentsReportTemplateControllerApiFactory);

	const { portfolioListOrderByName, setPortfolioListOrderByName } = usePortfolioStudioTableSettings();

	const { value: debouncedLowerCaseSearchQuery } = useDebouncedMemo(
		() => searchQuery.toLocaleLowerCase(),
		[searchQuery],
		{
			debounceInterval: 200,
		},
	);

	const portfolioByUid = useMemo(() => Map(portfolios.map((p) => [p.uuid ?? "", p])), [portfolios]);

	const filteredRows = useMemo(
		() =>
			portfolios.filter((item) =>
				[item.name ?? "", item.status ?? ""].some((el) => el.toLowerCase().includes(debouncedLowerCaseSearchQuery)),
			),
		[debouncedLowerCaseSearchQuery, portfolios],
	);

	const { column: checkboxColumn, rowClassList } = TableV2.useSelectableTableColumn({
		multiSelectCtx: tableMultiSelectCtx,
		rows: filteredRows,
		selectBy: (p) => p.uuid!,
	});

	const { push } = useTypedNavigation();

	const getDropdownActions = useCallback(
		({ uuid, name, status, richAcl }: InvestmentListEntry): DropdownMenuProps<HTMLElement, "">["actions"] => {
			const isEnhanceable = isPortfolioEnhancable(status);

			return [
				({ onClose }) => (
					<AuthorizationGuard permissionChecker={aclByArea.portfolio.canCreateProposal} acl={richAcl?.acl ?? []}>
						<DropdownMenuActionButton
							icon={isEnhanceable ? "Enhance" : "Enhance-Not-available"}
							disabled={!isEnhanceable}
							onClick={() => {
								push("Portfolios/EditPortfolio", { portfolioUid: uuid! });
								onClose();
							}}
							data-qualifier="PortfolioStudio/PortfolioList/DropdownMenu/DropdownItem(Enhance)"
						>
							Create proposal
						</DropdownMenuActionButton>
					</AuthorizationGuard>
				),
				({ onClose }) => (
					<AuthorizationGuard permissionChecker={aclByArea.portfolio.canDelete} acl={richAcl?.acl ?? []}>
						<DropdownMenuActionButton
							icon="Delete"
							onClick={() => {
								setModalData({ open: true, action: "delete", portfolioName: name ?? "", uuid: uuid ?? "" });
								onClose();
							}}
							data-qualifier="PortfolioStudio/PortfolioList/DropdownMenu/DropdownItem(Delete)"
						>
							Delete
						</DropdownMenuActionButton>
					</AuthorizationGuard>
				),
				({ onClose }) => (
					<DropdownMenuActionButton
						icon="Content-Copy"
						disabled={status ? invalidStatus.includes(status) : true}
						onClick={() => {
							setModalData({ open: true, action: "duplicate", portfolioName: name ?? "", uuid: uuid ?? "" });
							onClose();
						}}
						data-qualifier="PortfolioStudio/PortfolioList/DropdownMenu/DropdownItem(Duplicate)"
					>
						Duplicate
					</DropdownMenuActionButton>
				),
				({ onClose }) => (
					<AuthorizationGuard permissionChecker={aclByArea.portfolio.canRename} acl={richAcl?.acl ?? []}>
						<DropdownMenuActionButton
							icon="Edit"
							onClick={() => {
								setModalData({ open: true, action: "rename", portfolioName: name ?? "", uuid: uuid ?? "" });
								onClose();
							}}
							data-qualifier="PortfolioStudio/PortfolioList/DropdownMenu/DropdownItem(Rename)"
						>
							Rename
						</DropdownMenuActionButton>
					</AuthorizationGuard>
				),
				({ onClose }) => (
					<AuthorizationGuard
						permissionChecker={(userId, acl) => validateACLPermissions(userId, acl, roleByArea.portfolio.EDITOR)}
						acl={richAcl?.acl ?? []}
					>
						<DropdownMenuActionButton
							icon="share"
							onClickAsync={async () => {
								await aclSpawn(name, uuid, refetch.investments);
								onClose();
							}}
							data-qualifier="PortfolioStudio/PortfolioList/DropdownMenu/DropdownItem(Share)"
						>
							Share
						</DropdownMenuActionButton>
					</AuthorizationGuard>
				),
				({ onClose }) => (
					<AuthorizationGuard requiredRole="ROOT">
						<DropdownMenuActionButton
							icon="Ask-ai"
							onClick={() => {
								if (!state.chat?.appendUserMessageAndSend) {
									onClose();
									return;
								}
								state.chat
									?.appendUserMessageAndSend(
										`Based on its current composition, how does ${name} look in terms of risk?`,
									)
									.catch(noop);
								onClose();
							}}
							data-qualifier="PortfolioStudio/PortfolioList/DropdownMenu/DropdownItem(AskAI)"
						>
							Ask AI
						</DropdownMenuActionButton>
					</AuthorizationGuard>
				),
			];
		},
		[push, refetch, aclSpawn, state.chat],
	);

	const visibleColumns = usePortfolioColumn(
		comparablePortfolioData,
		(data, enhanced) =>
			setComparablePortfolioData((prev) => {
				const portfolio = prev.find((x) => x.uuid === data.uuid && Boolean(x.enhanced) === Boolean(enhanced));
				if (portfolio) {
					return prev.filter((x) => x.id !== portfolio.id);
				}

				return [
					...prev,
					{
						id: generateUniqueDOMId(),
						composition:
							(enhanced ? data.macroAssetClassExposureEnhanced : data?.macroAssetClassExposure)?.map((x) => ({
								quality: x.firstQualityLevel,
								weight: x.weight,
							})) ?? [],
						numberOfInstrument: data.nofInstruments ?? 0,
						portfolioName: data.name ?? "-",
						uuid: data.uuid,
						enhanced,
						note: data.lastActionNote,
						action: data.action,
					},
				];
			}),
		columnsPreferences,
		isVisible,
		userSyncInfo,
	);

	const columns = useMemo(
		() =>
			visibleColumns
				? ([
						checkboxColumn,
						...visibleColumns,
						actionsColumn({
							onSettingsClick: () => setCustomizeTableModalVisible(true),
							triggerProps: {
								"data-qualifier": "PortfolioStudio/PortfolioList/DropdownMenu",
							},
							dropdownActions: getDropdownActions,
						}),
				  ] satisfies TableV2.TableColumn<InvestmentListEntry>[])
				: null,
		[checkboxColumn, visibleColumns, getDropdownActions],
	);

	const filteredSortedRows = useMemo(
		() => (columns ? sortRows({ rows: filteredRows, columns, orderByArr: portfolioListOrderByName }) : filteredRows),
		[columns, filteredRows, portfolioListOrderByName],
	);

	const onCloseModal = useCallback(() => {
		setModalData((prev) => ({ open: false, action: prev.action, uuid: "", portfolioName: "", type: "portfolio" }));
		setIsPorfolioNameAvailable((prev) => (prev === true ? prev : true));
	}, []);

	const onDelete = useCallback(
		async (uuid: string, name: string) => {
			try {
				await performAction({ action: "delete", uuid });

				trackMixPanelEvent(`Portfolio`, {
					Type: "Delete",
					ID: uuid,
					Name: name,
				});

				await refetch.investments({ throwOnError: true });
				platformToast({
					children: t("PUSH_NOTIFICATION.SUCCESS_DELETE_PORTFOLIO", { name }),
					severity: "success",
					icon: "Portfolio",
				});
			} catch (error) {
				reportPlatformError(error, "ERROR", "portfolio", `delete investment "${uuid}"`);
				platformToast({
					children: t("SOMETHING_WENT_WRONG"),
					severity: "error",
					icon: "Portfolio",
				});
			} finally {
				onCloseModal();
				tableMultiSelectCtx.actions.remove(uuid);
			}
		},
		[tableMultiSelectCtx.actions, onCloseModal, performAction, refetch, t],
	);

	const onSubmit = useCallback(
		async (action: Exclude<UsePerformCrudActions, "delete">, uuid: string, name: string) => {
			try {
				const { data: isNameAvailable } = await investmentApi.isInvestmentNameAvailable(name);
				if (!isNameAvailable) {
					setIsPorfolioNameAvailable(isNameAvailable);
					return;
				}

				await performAction({ action, uuid, name });
				await refetch.investments({ throwOnError: true });
				const notficationMessage: Record<typeof action, string> = {
					duplicate: t("PORTFOLIOS.DUPLICATE_OK_MESSAGE", { portfolioName: name }),
					rename: t("PORTFOLIOS.RENAME_OK_MESSAGE", { portfolioName: name }),
				};

				platformToast({
					children: notficationMessage[action],
					severity: "success",
					icon: "Portfolio",
				});

				onCloseModal();
			} catch (error) {
				reportPlatformError(error, "ERROR", "portfolio", `${action} investment "${uuid}"`);
				platformToast({
					children: t("SOMETHING_WENT_WRONG"),
					severity: "error",
					icon: "Portfolio",
				});
			}
		},
		[investmentApi, onCloseModal, performAction, refetch, t],
	);

	const onCreateBulkProposal = useCallback(async () => {
		try {
			const uuids = tableMultiSelectCtx.data.selection.toArray();
			const { uuid } = await axiosExtract(bulkEnhancementApi.createDraftBulkEnhancement(uuids));
			push("Portfolios/EditPortfolioMulti", { proposalUuid: uuid ?? "" });
		} catch (error) {
			reportPlatformError(error, "ERROR", "portfolio", "try to create bulk enhancement");
			platformToast({
				children: "Unable to create a proposal",
				severity: "error",
				icon: "Dowload",
			});
			throw error;
		}
	}, [bulkEnhancementApi, tableMultiSelectCtx.data.selection, push]);

	const onCreateBulkEditSettings = useCallback(
		async (uuids: string[]) => {
			try {
				const { uuid } = await axiosExtract(investmentBulkBulkStaticV1Api.createDraftConfiguration(uuids));
				push("Portfolios/SettingsPortfolioMulti", { bulkUid: uuid ?? "" });
			} catch (error) {
				reportPlatformError(error, "ERROR", "portfolio", "try to create bulk enhancement");
				platformToast({
					children: "Unable to edit settings in bulk",
					severity: "error",
					icon: "Dowload",
				});
				throw error;
			}
		},
		[investmentBulkBulkStaticV1Api, push],
	);

	const onChangeTableColumn = useCallback(
		async (data: ColumnMetadata<UserPortfolioColumnPreferencePreferencesTypeEnum>[]) => {
			try {
				const payload = {
					userPortfolioColumnPreferences: data.map((preference) => ({
						enabled: preference.visible,
						preferencesType: preference.id,
					})),
				} satisfies UserPortfolioColumnOrdering;
				await portfolioStudioPreferencesApi.setUserPortfolioColumnMetricsOrderingPreferences(payload);
				await refetch.columnsPreferences({ throwOnError: true });
				platformToast({
					children: "Table Successfully Edited.",
					severity: "success",
					icon: "Settings",
				});
			} catch (error) {
				platformToast({
					children: "Failed to update table",
					severity: "error",
					icon: "Settings",
				});
				throw new Error(String(error));
			} finally {
				setCustomizeTableModalVisible(false);
			}
		},
		[portfolioStudioPreferencesApi, refetch],
	);

	const onDownloadReports = useCallback(
		async (pages: PageDto[]) => {
			try {
				// TODO: handle custom templates?
				// const pdfReportsUrls = reports.map(
				// 	(r): PageDto => ({
				// 		url: typedUrlForRoute("Login", {
				// 			from: typedUrlForRoute("Report", {
				// 				templateId: DefaultReportTemplateName.sphere,
				// 				objectId: r.uuid,
				// 				variant: (!r.enhance ? "current" : "proposal") satisfies ReportTemplateVariant,
				// 			}),
				// 		}),
				// 		name: r.name,
				// 	}),
				// );
				await pdfControllerV3Api.generateCustomReportsPdf({
					pages,
					templateType: "TPLPortraitNoMargin",
				});
				platformToast({
					children: "Sphere has taken over your request", //TODO: translate
					severity: "info",
					icon: "Portfolio",
				});
			} catch (error) {
				reportPlatformError(error, "ERROR", "portfolio", "unable to generate the bulk download");
				platformToast({
					children: "Something went wrong while generating the report",
					severity: "error",
					icon: "Portfolio",
				});
			} finally {
				tableMultiSelectCtx.actions.reset();
			}
		},
		[tableMultiSelectCtx.actions, pdfControllerV3Api],
	);

	const customizableColumns = (columnsPreferences ?? []).map((c) => ({
		label: c.preferencesType ? t(`TABLE.HEADERS.${c.preferencesType}`) : "-",
		id: c.preferencesType!,
		visible: c.enabled ?? false,
		disabled: c.preferencesType === "NAME",
		hidden: c.preferencesType === "NAME",
	}));

	function createComparePortfolio(portfolio: InvestmentListEntry, type: "current" | "enhanced"): CompareDataItem {
		return {
			id: generateUniqueDOMId(),
			composition:
				(type === "enhanced" ? portfolio?.macroAssetClassExposureEnhanced : portfolio?.macroAssetClassExposure)?.map(
					(x) => ({
						quality: x.firstQualityLevel,
						weight: x.weight,
					}),
				) ?? [],
			numberOfInstrument: portfolio.nofInstruments ?? 0,
			portfolioName: portfolio.name ?? "-",
			uuid: portfolio.uuid,
			enhanced: type === "enhanced",
			note: portfolio.lastActionNote,
			action: portfolio.action,
		};
	}

	const onComparePortfolios = useCallback(
		(selected?: Map<string, DefaultSelectionProps>) => {
			setComparablePortfolioData((prev) => {
				const selectedPortfolios = Set<string>([...prev.map((x) => x.uuid ?? ""), ...selection]);
				return selectedPortfolios.toArray().flatMap((uuid) => {
					const investment = portfolioByUid?.get(uuid);
					if (!investment) {
						return [];
					}

					const preferences = selected?.get(uuid);
					const existingPortfolio = prev.filter((portfolio) => portfolio.uuid === uuid);
					const currentPortfolio = createComparePortfolio(investment, "current");
					const enhancedPortfolio = createComparePortfolio(investment, "enhanced");
					if (existingPortfolio.length === 0) {
						return preferences === undefined
							? [currentPortfolio]
							: [
									...(preferences.current ? [currentPortfolio] : []),
									...(preferences.enhance ? [enhancedPortfolio] : []),
							  ];
					}

					return preferences === undefined
						? existingPortfolio
						: [
								...(preferences.current ? [existingPortfolio.find((x) => !x.enhanced) ?? currentPortfolio] : []),
								...(preferences.enhance ? [existingPortfolio.find((x) => x.enhanced) ?? enhancedPortfolio] : []),
						  ];
				});
			});
			setShowCompareDialog(false);
		},
		[portfolioByUid, selection],
	);

	const isPortfolioInUnconsistentStatus = useMemo(
		() =>
			selection.some(
				(uuid) =>
					portfolioByUid?.get(uuid)?.status === "ERROR" ||
					portfolioByUid?.get(uuid)?.status === "CALCULATING" ||
					portfolioByUid?.get(uuid)?.status === "REVIEW" ||
					portfolioByUid?.get(uuid)?.status === "RETRIEVING_DATA" ||
					portfolioByUid?.get(uuid)?.status === "DRAFT",
			),
		[selection, portfolioByUid],
	);

	const isMixedSelectionDisabled = useMemo(
		() => selection.some((id) => portfolioByUid.get(id)?.canUseAsMixedPortfolio === false),
		[portfolioByUid, selection],
	);

	const { scheduleMultiInvestmentsConversion, downloadInvestmentConversion } = useCompositionDownload();
	async function exportComposition(
		converter: InvestmentExportConverterType,
		investments: Array<{ uuid: string; enhancement?: boolean }>,
	) {
		try {
			//REMOVE after sprint 74
			if (converter === "EASIM_TEMPLATE_CONVERTER") {
				if (investments.length > 1) {
					const compositions = await parallelize(
						investments.map(
							({ uuid, enhancement }) =>
								() =>
									axiosExtract(integrationsApi.exportInvestment(uuid, enhancement)),
						),
					);

					await axiosExtract(integrationsApi.convertTo(converter, compositions, true));
					// downloadFileResponse(fileResponse, {})
					platformToast({
						children: "Sphere has taken over your request",
						severity: "info",
						icon: "Dowload",
					});
					return;
				}
				const { enhancement, uuid } = investments[0];
				const composition = await axiosExtract(integrationsApi.exportInvestment(uuid, enhancement));
				await axiosExtract(integrationsApi.convertTo(converter, [composition], true));
				platformToast({
					children: "Sphere has taken over your request",
					severity: "info",
					icon: "Dowload",
				});
				return;
			}

			if (investments.length > 1) {
				await scheduleMultiInvestmentsConversion(converter, investments);
				platformToast({
					children: "Sphere has taken over your request",
					severity: "info",
					icon: "Dowload",
				});
				// await downloadFileResponse(conversion, "xlsx");
				return;
			}
			const { enhancement, uuid } = investments[0];
			await downloadInvestmentConversion(
				converter,
				uuid,
				enhancement,
				// converter === InvestmentExportConverterType.EasimTemplateConverter,
			);

			// if (converter !== InvestmentExportConverterType.EasimTemplateConverter) {
			// } else {
			// 	platformToast({
			// 		children: "Sphere has taken over your request",
			// 		severity: "info",
			// 		icon: "Dowload",
			// 	});
			// }
		} catch (error) {
			platformToast({
				children: "Unable to export the selected investments",
				icon: "Portfolio",
				severity: "error",
			});
			throw error;
		}
	}

	const { data: templateList } = useQueryNoRefetch(["queryReportTemplateList"], {
		queryFn: async () => {
			const defautlts = [
				...defaultPortfolioTemplates.map((defaultTemplate) => ({
					...defaultTemplate,
					visible: true,
				})),
			];
			const list = await axiosExtract(investmentsReportTemplateApi.listInvestmentReportTemplates());
			return [
				...defautlts.filter((x) => !list.find((y) => x.templateName === y.templateName)),
				...list.filter((x) => x.visible),
			];
		},
	});

	const commentaryTemplateApi = useApiGen(CommentaryTemplateControllerApiFactory);
	const commentaryTemplate = useQueryNoRefetch(["queryCommentartTemplateList"], {
		queryFn: async () => {
			const list = await axiosExtract(commentaryTemplateApi.getTemplateList());
			return list.flatMap(
				(x): Array<Option<string>> => (x.visible && x.uuid && x.name ? [{ label: x.name, value: x.uuid }] : []),
			);
		},
	});

	return (
		<>
			<CustomizeColumns
				show={customizeTableModalVisible}
				onClose={() => setCustomizeTableModalVisible(false)}
				columns={customizableColumns}
				// limit={Math.min(7, customizableColumns?.filter((x) => x.hidden === false).length ?? 1)}
				onSubmitAsync={onChangeTableColumn}
			/>

			<div className="flex justify-between items-center">
				<TextInput
					value={searchQuery}
					name="search"
					maxLength={60}
					onChangeText={setSearchQuery}
					placeholder={t("PORTFOLIOS.CONSTRAINTS_TARGETS.PORTFOLIO_LIST")}
					onKeyDown={preventSubmitOnPressEnter}
					style={{ width: 692 }}
					leftContent={<Icon icon="Search" size="1.4em" />}
				/>
				<div className="flex gap-2">
					<AuthorizationGuard
						requiredServices={["IMPORT"]}
						requiredParam={({ importFormats }) => importFormats !== undefined && importFormats.length > 0}
					>
						{() => (
							<ReactQueryWrapper
								queryFn={async () => {
									const importConverters = await axiosExtract(integrationsApi.retrieveInvestmentImportConverterType());
									return importConverters.filter((converter) => user.importFormats?.includes(converter));
								}}
								queryKey={["importFormat", "portfolio"]}
							>
								{(converters) => (
									<Button
										size="small"
										palette="secondary"
										onClick={() =>
											spawnInvestmentImportDialog({
												converters,
												onImportFinished: ({ imported }) => {
													if (imported.length) {
														refetch.investments({ throwOnError: true }).catch(noop);
													}
												},
											})
										}
									>
										<Icon icon="Dowload" size={16} classList="-rotate-90 mr-1" />
										Import
									</Button>
								)}
							</ReactQueryWrapper>
						)}
					</AuthorizationGuard>
					<Button
						size="small"
						palette="secondary"
						onClick={() => setShowNewPortfolio(true)}
						data-qualifier="PortfolioStudio/PortfolioList/NewPortfolio"
					>
						<Icon icon="Outline1" size={16} />
						New Portfolio
					</Button>
				</div>
			</div>

			<BatchActions
				classList="my-2"
				selected={tableMultiSelectCtx.data.selection.size}
				total={portfolios.length}
				actions={[
					{
						label: "Delete",
						icon: "Delete",
						onClick: nullary(tableMultiSelectCtx.actions.confirmSelection),
						disabled: tableMultiSelectCtx.data.selection.some(
							(id) => aclByArea.portfolio.canDelete(user.id, portfolioByUid?.get(id)?.richAcl?.acl ?? []) === false,
						),
					},
					{
						label: "Download",
						icon: "Dowload",
						...(isPortfolioInUnconsistentStatus
							? {
									onClick: noop,
									disabled: true,
									tooltip: {
										children: "Portfolios in error, calculation and review status cannot be downloaded",
										overrideColor: themeCSSVars.palette_N300,
									},
							  }
							: {
									dropdown: {
										actions: [
											// ({ onClose }) => (
											// 	<DropdownMenuActionButton
											// 		icon="Dowload"
											// 		key="downloadReports"
											// 		onClickAsync={async () => {
											// 			if (selection.some((uuid) => portfolioByUid?.get(uuid)?.status === "PROPOSAL_READY")) {
											// 				spawnPortfolioTemplateChooser({
											// 					investments: selection.map((id) => ({
											// 						name: portfolioByUid.get(id)?.name,
											// 						uuid: portfolioByUid.get(id)?.uuid ?? "",
											// 						status: portfolioByUid.get(id)?.status,
											// 					})),
											// 					onSubmitAsync: async (data) => {
											// 						const investments = data.map(({ uuid, choice, name }) => ({
											// 							uuid,
											// 							enhance: choice === "enhance",
											// 							name: name ?? `report_with_missing_name-${uuid}`,
											// 						}));
											// 						await onDownloadReports(investments);
											// 					},
											// 				});
											// 				return;
											// 			}

											// 			const investments = selection.map((id) => {
											// 				const uuid = portfolioByUid.get(id)?.uuid ?? "";
											// 				return {
											// 					uuid,
											// 					enhance: false,
											// 					name: portfolioByUid.get(id)?.name ?? `report_with_missing_name-${uuid}`,
											// 				};
											// 			});
											// 			await onDownloadReports(investments);
											// 			onClose();
											// 		}}
											// 	>
											// 		Download Reports(pdf)
											// 	</DropdownMenuActionButton>
											// ),
											...(templateList ?? []).map(
												(template): ActionWithOptionalGroup<""> => ({
													children: ({ onClose }) => (
														<DropdownMenuActionButton
															icon="pdf"
															onClickAsync={async () => {
																if (selection.some((uuid) => portfolioByUid?.get(uuid)?.status === "PROPOSAL_READY")) {
																	spawnPortfolioTemplateChooser({
																		investments: selection.map((id) => ({
																			name: portfolioByUid.get(id)?.name,
																			uuid: portfolioByUid.get(id)?.uuid ?? "",
																			status: portfolioByUid.get(id)?.status,
																		})),
																		onSubmitAsync: async (data) => {
																			const pages = data.map(
																				({ choice, uuid, name }, index): PageDto => ({
																					url: typedUrlForRoute("Login", {
																						from: typedUrlForRoute("Report", {
																							templateId: isDefaultReportTemplate(template)
																								? template.id
																								: template.uuid!,
																							objectId: uuid!,
																							variant: (choice === "current"
																								? "current"
																								: "proposal") satisfies ReportTemplateVariant,
																						}),
																					}),
																					name: `Portfolio_${name}_template_${template.templateName}_${format(
																						new Date(),
																						"MMddyyyy",
																					)}${choice === "current" ? "" : "_Proposal_Ready"}_(${index})`,
																				}),
																			);
																			await onDownloadReports(pages);
																		},
																	});
																	onClose();
																	return;
																}

																const pages = selection.map(
																	(uuid): PageDto => ({
																		url: typedUrlForRoute("Login", {
																			from: typedUrlForRoute("Report", {
																				templateId: isDefaultReportTemplate(template) ? template.id : template.uuid!,
																				objectId: uuid!,
																				variant: "current" satisfies ReportTemplateVariant,
																			}),
																		}),
																		name: `Portfolio_${portfolioByUid?.get(uuid)?.name}_template_${
																			template.templateName
																		}_${format(new Date(), "MMddyyyy")}`,
																	}),
																);
																await onDownloadReports(pages);
																onClose();
															}}
														>
															{(template.templateName ?? "").concat(" (pdf)")}
														</DropdownMenuActionButton>
													),
												}),
											),
											...(user.exportFormats ?? []).map(
												(exportConverter) =>
													function Export({ onClose }: { onClose(): void }) {
														return (
															<AuthorizationGuard requiredService="EXPORT">
																<DropdownMenuActionButton
																	icon="xls"
																	key="customDownload"
																	classList={`hover:bg-[color:${themeCSSVars.palette_P50}] w-full`}
																	onClickAsync={async () => {
																		if (
																			selection.some((uuid) => portfolioByUid?.get(uuid)?.status === "PROPOSAL_READY")
																		) {
																			spawnPortfolioTemplateChooser({
																				investments: selection.map((id) => ({
																					name: portfolioByUid.get(id)?.name,
																					uuid: portfolioByUid.get(id)?.uuid ?? "",
																					status: portfolioByUid.get(id)?.status,
																				})),
																				onSubmitAsync: async (data) => {
																					const investments = data.map(({ uuid, choice }) => ({
																						uuid,
																						enhancement: choice === "enhance",
																					}));
																					await exportComposition(exportConverter, investments);
																				},
																			});
																			onClose();
																			return;
																		}

																		const investments = selection.map((uuid) => ({ uuid }));
																		await exportComposition(exportConverter, investments);
																		onClose();
																	}}
																>
																	{`${t(`EXPORT.${exportConverter}`)} template`}
																</DropdownMenuActionButton>
															</AuthorizationGuard>
														);
													},
											),

											({ onClose }) => (
												<AuthorizationGuard requiredService="INVESTMENTS_REPORT_TEMPLATE_EDITOR">
													<DropdownMenuActionButton
														icon="Settings"
														key="report"
														classList={`border-t border-[${themeCSSVars.palette_N200}]`}
														onClickAsync={() => {
															push("PortfolioStudioSettings", {
																tab: PortfolioStudioSettingTabEnum.ReportCustomisation,
															});
															onClose();
														}}
													>
														Report customisation
													</DropdownMenuActionButton>
												</AuthorizationGuard>
											),
										],
									},
							  }),
					},
					{
						label: "Compare",
						icon: "compare",
						disabled: isPortfolioInUnconsistentStatus,
						onClick: selection.some((uuid) => portfolioByUid?.get(uuid)?.status === "PROPOSAL_READY")
							? () => setShowCompareDialog(true)
							: () => onComparePortfolios(),
						tooltip: {
							children: "Portfolios in error, calculation and review status cannot be compared",
							overrideColor: themeCSSVars.palette_N300,
							disabled: isPortfolioInUnconsistentStatus === false,
						},
					},

					...(hasAccess(user, { requiredService: "COMMENTARY_BUILDER" })
						? [
								{
									label: "Generate commentary",
									icon: "Ask-ai",
									...(isPortfolioInUnconsistentStatus ||
									selection.some(
										(id) =>
											!aclByArea.portfolio.canEditComposition(user.id, portfolioByUid?.get(id)?.richAcl?.acl ?? []),
									)
										? {
												onClick: noop,
												disabled: true,
												tooltip: {
													children: "Portfolios in error, calculation and review status cannot be downloaded",
													overrideColor: themeCSSVars.palette_N300,
												},
										  }
										: {
												dropdown: {
													actions:
														commentaryTemplate.data?.map((template) => ({
															children: ({ onClose }) => (
																<DropdownMenuActionButton
																	onClickAsync={async () => {
																		// if (
																		// 	selection.some((uuid) => portfolioByUid?.get(uuid)?.status === "PROPOSAL_READY")
																		// ) {
																		// 	spawnPortfolioTemplateChooser({
																		// 		investments: selection.map((id) => ({
																		// 			name: portfolioByUid.get(id)?.name,
																		// 			uuid: portfolioByUid.get(id)?.uuid ?? "",
																		// 			status: portfolioByUid.get(id)?.status,
																		// 		})),
																		// 		onSubmitAsync: async (data) => {
																		// 			const investments = data.map(({ uuid, choice }) => ({
																		// 				investmentUUID: uuid,
																		// 				templateUUID: template.value,
																		// 				proposal: choice === "enhance",
																		// 			}));

																		// 			await investmentApi.createCommentaryBulkFromTemplate(investments);
																		// 		},
																		// 	});
																		// 	onClose();
																		// }

																		const investmentsCommentaryToUpdate = selection.map(
																			(uuid) =>
																				({
																					templateUUID: template.value,
																					investmentUUID: uuid,
																				}) satisfies InvestmentCommentaryDTO,
																		);
																		await investmentApi.createCommentaryBulkFromTemplate(investmentsCommentaryToUpdate);
																		onClose();
																	}}
																>
																	{template.label}
																</DropdownMenuActionButton>
															),
														})) ?? [],
													listboxAppearance: { classList: "max-h-[312px]" },
												},
										  }),
								} satisfies BatchAction,
						  ]
						: [
								{
									label: "Generate commentary",
									icon: "Ask-ai",
									disabled: selection.some(
										(id) =>
											!aclByArea.portfolio.canEditComposition(user.id, portfolioByUid?.get(id)?.richAcl?.acl ?? []),
									),
									onClickAsync: async () => {
										await investmentApi.createCommentaryBulk(selection);
										// TODO: show platform toast or ignore because we already have push notifications on commentary updates?
									},
								} satisfies BatchAction,
						  ]),

					{
						label: "Create proposal",
						icon: "Enhance",
						disabled: selection.some(
							(id) =>
								portfolioByUid?.get(id)?.canBulkEnhance === undefined ||
								portfolioByUid?.get(id)?.canBulkEnhance === false ||
								aclByArea.portfolio.canCreateProposal(user.id, portfolioByUid?.get(id)?.richAcl?.acl ?? []) === false,
						),
						onClickAsync: async () => {
							if (tableMultiSelectCtx.data.selection.size === 1) {
								push("Portfolios/EditPortfolio", {
									portfolioUid: tableMultiSelectCtx.data.selection.first()!,
								});
							} else {
								await onCreateBulkProposal();
							}
						},
						tooltip: {
							children:
								"Portfolios in error, calculation, review status and without a universe cannot be used to create a proposal",
							overrideColor: themeCSSVars.palette_N300,
							disabled:
								selection.some(
									(id) =>
										portfolioByUid?.get(id)?.canBulkEnhance === undefined ||
										portfolioByUid?.get(id)?.canBulkEnhance === false ||
										aclByArea.portfolio.canCreateProposal(user.id, portfolioByUid?.get(id)?.richAcl?.acl ?? []) ===
											false,
								) === false,
						},
					},
					{
						label: "Edit Settings",
						icon: "Settings",
						disabled:
							selection.some(
								(id) =>
									aclByArea.portfolio.canEditSettings(user.id, portfolioByUid?.get(id)?.richAcl?.acl ?? []) === false ||
									portfolioByUid.get(id)?.status === "PROPOSAL_READY",
							) || isPortfolioInUnconsistentStatus,
						onClickAsync: async () => {
							if (tableMultiSelectCtx.data.selection.size === 1) {
								push("PortfolioDetails", {
									portfolioUid: tableMultiSelectCtx.data.selection.first(),
									tab: PortfolioDetailsTabs.PORTFOLIO_STUDIO_SETTINGS,
								});
							} else {
								await onCreateBulkEditSettings(tableMultiSelectCtx.data.selection.toArray());
							}
						},
						tooltip: {
							children:
								"Portfolios in error, calculation, porposal ready, review status and without a universe cannot be used to create a bulk edit settings",
							overrideColor: themeCSSVars.palette_N300,
							disabled: selection.every((id) =>
								aclByArea.portfolio.canEditSettings(user.id, portfolioByUid?.get(id)?.richAcl?.acl ?? []),
							),
						},
					},
					...(hasAccess(user, { requiredService: "MIXED_PORTFOLIOS" })
						? [
								{
									label: "Merge",
									icon: "merge",
									disabled: isMixedSelectionDisabled,
									onClick: () =>
										push("Portfolios/UploadPortfolioPage/Portfolio", {
											uuids: selection.join(","),
										}),
									tooltip: {
										children: "Portfolios in error, calculation and review status cannot be mixed",
										overrideColor: themeCSSVars.palette_N300,
										disabled: isMixedSelectionDisabled === false,
									},
								} satisfies BatchAction,
						  ]
						: []),
				]}
			/>

			{columns && (
				<TableV2.BaseHScrollTable
					key="portfolioListTable"
					onRowClick={(row) => row.uuid && tableMultiSelectCtx.actions.toggle(row.uuid)}
					onRowContextMenu={(row, _index, e) => contextMenuHandler(e, getDropdownActions(row))}
					columns={columns}
					rows={filteredSortedRows}
					rowClassList={(row, rowIndex) => ({
						...toClassListRecord(rowClassList(row, rowIndex)),
						PortfolioListTableRow: true,
					})}
					rowStyle={({ uuid }) => {
						const selected = tableMultiSelectCtx.data.selection?.has(uuid!);
						return selected ? { backgroundColor: themeCSSVars.Table_highlightedRowBackgroundColor } : {};
					}}
					orderBy={portfolioListOrderByName}
					onOrderByChange={setPortfolioListOrderByName}
					// TODO: refactor
					style={{ maxHeight: "calc(100dvh - 160px)" }}
					noDataText="No data available"
					pinnedColumns={[
						{ name: "name", side: "left" },
						{ name: "settings-action", side: "right" },
					]}
				/>
			)}

			<Dialog show={showNewPortfolio} size="medium" onClose={() => setShowNewPortfolio(false)}>
				<div className="grid grid-cols-2 gap-4 ">
					<Link
						to={typedUrlForRoute("Portfolios/CreatePortfolio", { uuid: "" })}
						className={`hover:bg-[color:${themeCSSVars.palette_N50}] hover:rounded-md p-4`}
						data-qualifier="PortfolioStudio/PortfolioList/NewPortfolio/Create"
					>
						<CreatePortfolioIcon classList="mx-auto" />
						<p className="text-lg font-semibold text-center">Create</p>
						<p className="text-center">Set constraints and targets and let Sphere create a portfolio for you</p>
					</Link>
					<Link
						to={typedUrlForRoute("Portfolios/UploadPortfolioPage/Portfolio", {})}
						className={`hover:bg-[color:${themeCSSVars.palette_N50}] hover:rounded-md p-4`}
						data-qualifier="PortfolioStudio/PortfolioList/NewPortfolio/UploadEditor"
					>
						<UploadPortfolioIcon classList="mx-auto" />
						<p className="text-lg font-semibold text-center">Upload and/or Set Weights</p>
						<p className="text-center">Import existing portfolios and set weights</p>
					</Link>
				</div>
			</Dialog>

			{modalData.action === "delete" && (
				<CrudModal
					show={modalData.open}
					action={modalData.action}
					title={t("PORTFOLIOS.MODAL.SINGLE_DELETE.TITLE")}
					portfolioName={modalData.portfolioName}
					onCancel={onCloseModal}
					onClose={onCloseModal}
					onSubmit={() => onDelete(modalData.uuid, modalData.portfolioName)}
					submitAttribute={{ "data-qualifier": "PortfolioStudio/PortfolioList/CrudModal/Delete(Submit)" }}
					cancelAttribute={{ "data-qualifier": "PortfolioStudio/PortfolioList/CrudModal/Delete(Cancel)" }}
				/>
			)}

			{modalData.action === "duplicate" && (
				<CrudModal
					show={modalData.open}
					action={modalData.action}
					title={t("PORTFOLIOS.MODAL.DUPLICATE.TITLE", { portfolio: modalData.portfolioName })}
					onCancel={onCloseModal}
					onClose={onCloseModal}
					isInvalid={!isPortfolioNameAvailable}
					onSubmit={(name) => onSubmit("duplicate", modalData.uuid, name)}
					portfolioName={modalData.portfolioName}
					submitAttribute={{ "data-qualifier": "PortfolioStudio/PortfolioList/CrudModal/Duplicate(Submit)" }}
					cancelAttribute={{ "data-qualifier": "PortfolioStudio/PortfolioList/CrudModal/Duplicate(Cancel)" }}
				/>
			)}

			{modalData.action === "rename" && (
				<CrudModal
					show={modalData.open}
					action={modalData.action}
					title={t("PORTFOLIOS.MODAL.RENAME.TITLE", { portfolio: modalData.portfolioName })}
					onCancel={onCloseModal}
					onClose={onCloseModal}
					isInvalid={!isPortfolioNameAvailable}
					onSubmit={(name) => onSubmit("rename", modalData.uuid, name)}
					portfolioName={modalData.portfolioName}
					submitAttribute={{ "data-qualifier": "PortfolioStudio/PortfolioList/CrudModal/Rename(Submit)" }}
					cancelAttribute={{ "data-qualifier": "PortfolioStudio/PortfolioList/CrudModal/Rename(Cancel)" }}
				/>
			)}

			<CompareOverlay
				show={comparablePortfolioData.length > 0}
				onClose={() => setComparablePortfolioData([])}
				onRemove={(id) => setComparablePortfolioData((v) => v.filter((x) => x.id !== id))}
				compareData={comparablePortfolioData}
			/>

			<CompareDialog
				show={showCompareDialog}
				onCancel={() => setShowCompareDialog(false)}
				onClose={() => setShowCompareDialog(false)}
				investments={selection.flatMap((uuid) => (portfolioByUid.get(uuid) ? [portfolioByUid.get(uuid)!] : []))}
				comparedInvestments={comparablePortfolioData}
				onSubmit={onComparePortfolios}
			/>
		</>
	);
};

export default PortfolioList;

const invalidStatus: Array<InvestmentStatuses> = [
	"ERROR",
	"REVIEW",
	"CALCULATING",
	"RETRIEVING_DATA",
	"PROPOSAL_READY",
];

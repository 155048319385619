/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type {
	CustomerModel,
	FeedbackResponse,
	MarketViewSettings,
	UserModel,
	CustomMarketViewMicroAssetClass,
} from "$root/api/api-gen";
import {
	BucketControllerApiFactory,
	CustomerControllerApiFactory,
	CustomerControllerV3ApiFactory,
	ImportDtoImportConnectionTypeEnum,
	InvestmentExportConverterType,
	InvestmentImportConverterType,
	MarketScenarioIds,
	MarketViewMicroAssetClasses,
	MarketViewSettingsActiveMarketViewTypesEnum,
	IntegrationsControllerApiFactory,
	FeedbacksControllerApiFactory,
	MarketViewType,
	ProtocolsControllerApiFactory,
	ServiceType,
	UserControllerApiFactory,
} from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import AuthorizationGuard from "$root/components/AuthorizationGuard";
import { DropzoneArea } from "$root/components/DropzoneArea";
import { IconWalls, WallOverlay } from "$root/components/IconWall";
import ImpersonateBox from "$root/components/ImpersonateBox/impersonate";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import UploadButton from "$root/components/UploadButton";
import EmptyImportBox from "$root/functional-areas/upload/box/EmptyImportBox";
import SuccessImportBox from "$root/functional-areas/upload/box/SuccessImportBox";
import { UserRole, useUserValue } from "$root/functional-areas/user";
import { platformToast } from "$root/notification-system/toast";
import { PaletteColors } from "$root/styles/themePalette";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { FormController } from "$root/third-party-integrations/react-hook-form";
import { zExt } from "$root/third-party-integrations/zod";
import { FormFields } from "$root/ui-lib/form/FormFields";
import { filterMap } from "$root/utils/collections";
import { LocalizedError } from "$root/utils/errors";
import { customObjectEntriesFn, customObjectValuesFn } from "$root/utils/experimental";
import { downloadContentDisposition } from "$root/utils/files";
import { parallelize } from "$root/utils/promise";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import type { OrderBy, TableColumn } from "@mdotm/mdotui/components";
import {
	AsyncButton,
	AutoTooltip,
	Banner,
	BatchActions,
	Button,
	Checkbox,
	Collapsible,
	DefaultCollapsibleContent,
	DefaultCollapsibleHeader,
	DefaultTabTitle,
	Dialog,
	DropdownMenu,
	DropdownMenuActionButton,
	Form,
	FormField,
	Icon,
	Label,
	Radio,
	RadioGroup,
	Select,
	SubmitButton,
	Tab,
	TabGroup,
	Table,
	Text,
	TextInput,
	TooltipContent,
	defaultLabelClassName,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import { useSearchable } from "@mdotm/mdotui/headless";
import { ForEach, Switch, toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { MaybeArray } from "@mdotm/mdotui/utils";
import { alwaysArray, builtInSort, builtInSortFnFor, switchExpr, unpromisify } from "@mdotm/mdotui/utils";
import type { ReactNode } from "react";
import * as React from "react";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

type TRole = {
	[key: string]: number;
};

// TODO: consider using the api-gen model. Note that there are a couple of differences
const defaultNewUserData: {
	email: string;
	name: string;
	surname: string;
	customerId: string;
	services: ServiceType[];
	enabled: boolean;
	role: string;
	importFormats?: Array<InvestmentImportConverterType>;
	exportFormats?: Array<InvestmentExportConverterType>;
	importEnabled?: boolean;
	importDirectory?: string;
} = {
	email: "",
	name: "",
	surname: "",
	customerId: "",
	services: [],
	enabled: false,
	role: "",
	importFormats: undefined,
	exportFormats: undefined,
	importEnabled: undefined,
	importDirectory: undefined,
};

// TODO: consider using the api-gen model. Note that there are a couple of differences
const defaultNewCustomerData = {
	name: "",
	theme: "",
	maxUsers: 0,
	maxPortfolios: 0,
	tokenDuration: 24,
	enabled: false,
	password: "",
	lowRiskProfile: undefined as undefined | string,
	midRiskProfile: undefined as undefined | string,
	highRiskProfile: undefined as undefined | string,
};

function getServiceTypeCategory(serviceType: ServiceType) {
	switch (serviceType) {
		case "REPORTS":
		case "INVESTMENTS":
		case "SIGNALS":
		case "CUSTOM_REPORT_CB1":
		case "HELP_CENTER":
		case "NOTIFICATION_CENTER":
		case "ASK_SPHERE":
		case "GLOBAL_RISK_MAP":
			return "Areas";
		case "CUSTOM_QUALITIES":
		case "IMPORT":
		case "EXPORT":
		case "MIXED_PORTFOLIOS":
		case "MANDATE_TYPE_SELECTION_VISIBLE":
		case "NUMBER_OF_INSTRUMENTS_CHECK_BYPASS":
			return "Portfolio Studio Services";
		case "SHOW_SPHERE_STRATEGICAL_MARKET_VIEWS_ONLY":
		case "CUSTOM_MARKET_VIEW_SETTINGS_EDITOR":
		case "CUSTOM_MARKET_VIEW_COMMENTARY_EDITOR":
			return "Market view";
		case "PORTFOLIO_STUDIO_COMMENTARY_TAB":
		case "COMMENTARY_BUILDER":
		case "INVESTMENTS_REPORT_TEMPLATE_EDITOR":
			return "Customize Reports";
		case "EXPERT":
		case "EXPERT_VIEW":
			return "Deprecated";
		default:
			return "Unknow";
	}
}

function getServiceTypeTooltip(serviceType: ServiceType): string | undefined {
	switch (serviceType) {
		case "REPORTS":
			return "Enables the Market signals area (Outlook, Outlook Focus and Macro Indicators).";
		case "INVESTMENTS":
			return "Enables the Portfolio Studio area.";
		case "SIGNALS":
			return "Enables the HMM area.";
		case "CUSTOM_REPORT_CB1":
			return "Enables the Custom Reports area (ex CherryBank Custom Reports).";
		case "HELP_CENTER":
			return "Enables the Help Center area.";
		case "CUSTOM_QUALITIES":
			return "Enable the possibility to set a score to the instruments on the universe, and to set constraints on those values.";
		case "IMPORT":
			return "Enable the possibility to import portfolios and universes with custom formats.";
		case "EXPORT":
			return "Enable the possibility to export portfolios with formats.";
		case "NOTIFICATION_CENTER":
			return "Enables the Notification Center area.";
		case "ASK_SPHERE":
			return "Enables the Ask Sphere area.";
		case "SHOW_SPHERE_STRATEGICAL_MARKET_VIEWS_ONLY":
			return "Expose to the user only a limited set of starting scenarios to create the market views. In particular, the ones with 1y+ horizon";
		case "CUSTOM_MARKET_VIEW_SETTINGS_EDITOR":
			return "Users with this service enabled can modify the custom market view template of the organization. The service applies only if the organization have the service 'read market view commentaries' enabled.";
		case "CUSTOM_MARKET_VIEW_COMMENTARY_EDITOR":
			return "Users with this service enabled can modify the commentaries associated to the market views. The service applies only if the organization have the service 'read market view commentaries' enabled.";
		case "MANDATE_TYPE_SELECTION_VISIBLE":
			return "Enable the possibility to select a commentary type (var-based, benchmark-based) for the user portfolios. Used mainly for commentaries generation";
		case "EXPERT_VIEW":
			return "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, incidunt rerum vitae dicta.";
		case "EXPERT":
			return "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, incidunt rerum vitae dicta.";
		case "MIXED_PORTFOLIOS":
			return "Users with this service enabled can create portfolio, by using other portfolio in the composition";
		case "NUMBER_OF_INSTRUMENTS_CHECK_BYPASS":
			return "Users with this service enabled can bypass the min number of instruments required for creating portfolio";
		case "PORTFOLIO_STUDIO_COMMENTARY_TAB":
			return "User with this service can see the commentary in a separete tab inside the portfolio details";
		case "COMMENTARY_BUILDER":
			return "User with this service can access to a new area where he can customize his own commentary templates";
		case "INVESTMENTS_REPORT_TEMPLATE_EDITOR":
			return "User with this service can create personalized portfolio template that will be shared across the organaization";
		case "GLOBAL_RISK_MAP":
			return "Enables Global Risk Map in the HMM area.";
		default:
			return undefined;
	}
}

// User Data, View and change
const UserBox = (props: {
	onFinish: () => Promise<void>;
	userData?: MaybeArray<UserModel>;
	customers: CustomerModel[];
	isCurrentUser?: boolean;
}) => {
	const { onFinish, customers, userData, isCurrentUser = false } = props;
	const mode = userData ? "edit" : "new";

	const { t } = useTranslation();
	const userApi = useApiGen(UserControllerApiFactory);
	const integrationsApi = useApiGen(IntegrationsControllerApiFactory);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [tabIndex, setTabIndex] = useState(0);

	const { query, filtered, setQuery } = useSearchable({
		collection: customObjectValuesFn(ServiceType).sort(),
		matchFn: (row, q) => {
			const stringifiedRow = JSON.stringify(row).toLowerCase();
			const words = q.toLowerCase().split(" ");
			return words.every((keys) => stringifiedRow.indexOf(keys) >= 0);
		},
	});

	const filteredAndGrouped = useMemo(() => {
		const groups: Record<string, ServiceType[]> = {};
		for (const service of filtered) {
			if (!groups[getServiceTypeCategory(service)]) {
				groups[getServiceTypeCategory(service)] = [];
			}
			groups[getServiceTypeCategory(service)].push(service);
		}
		return groups;
	}, [filtered]);

	const { data: integrationConverter } = useQueryNoRefetch(["queryIntegrationImport"], {
		queryFn: async () => {
			const investmentImportConverter = await axiosExtract(integrationsApi.retrieveInvestmentImportConverterType());
			const investmentExportConverter = await axiosExtract(integrationsApi.retrieveExportConverterType());
			const universeImportConverter = await axiosExtract(integrationsApi.retrieveUniverseImportConverterType());
			return {
				investmentImportConverter,
				investmentExportConverter,
				universeImportConverter,
			};
		},
	});

	const customerSelect = customers.map((el) => {
		return { label: el.name ?? "-", value: el.id! };
	});

	const [overrideMethod, setOverrideMethod] = useState<"Add" | "Replace" | "Remove">("Add");

	const userSyncData = useMemo<typeof defaultNewUserData>(
		() => ({
			...defaultNewUserData,
			...(Array.isArray(userData)
				? {
						services: userData.length === 1 ? userData[0].services ?? [] : [],
						importFormats: userData.length === 1 ? userData[0].importFormats : undefined,
						exportFormats: userData.length === 1 ? userData[0].exportFormats : undefined,
				  }
				: userData
				  ? {
							email: userData.email ?? "",
							name: userData.name ?? "",
							surname: userData.surname ?? "",
							services: userData.services ?? [],
							role: userData.role ?? "",
							enabled: userData.enabled ?? false,
							customerId: userData.customerId ?? "",
							importFormats: userData.importFormats,
							exportFormats: userData.exportFormats,
							importEnabled: userData.importEnabled,
							importDirectory: userData.importDirectory,
				    }
				  : {}),
		}),
		[userData],
	);

	function OverrideServices(user: UserModel, values: typeof userSyncData): UserModel {
		return switchExpr(overrideMethod, {
			Add: () => ({
				...user,
				services: Array.from(new Set<ServiceType>([...(user.services ?? []), ...(values.services ?? [])])),
				importFormats: values.importFormats ?? user.importFormats,
				exportFormats: values.exportFormats ?? user.exportFormats,
			}),
			Remove: () => ({
				...user,
				services: (user.services ?? []).filter((service) => (values.services ?? []).includes(service) === false),
				s: values.importFormats ?? user.importFormats,
				exportFormats: values.exportFormats ?? user.exportFormats,
			}),
			Replace: () => ({
				...user,
				services: values.services,
				importFormats: values.importFormats ?? user.importFormats,
				exportFormats: values.exportFormats ?? user.exportFormats,
			}),
		});
	}

	const onSubmit = async (values: typeof userSyncData) => {
		setIsLoading(true);
		try {
			switch (mode) {
				case "new": {
					await userApi.createUser(values);
					break;
				}
				case "edit": {
					if (!userData) {
						throw new Error("missing userData in edit mode");
					}
					if (!Array.isArray(userData)) {
						await userApi.updateUser(userData.id!, values);
					} else {
						const failed: unknown[] = [];
						await parallelize(
							userData.map(
								(user) => () =>
									userApi.updateUser(user.id!, OverrideServices(user, values)).catch((err) => failed.push(err)),
							),
							{ concurrency: 3 },
						);
						if (failed.length > 0) {
							throw failed;
						}
					}
					break;
				}
			}
			await onFinish();
			setIsLoading(false);
		} catch (e) {
			platformToast({
				children: t("SETTING_PANEL.CREATE_USER_ERROR"),
				severity: "error",
				icon: "Top-menu-user",
			});
		} finally {
			setIsLoading(false);
		}
	};

	const roleOptions = t("SETTING_PANEL.ROLES_OPTIONS", { returnObjects: true });

	const { control, formState, handleSubmit, setValue, watch } = useForm({
		defaultValues: userSyncData ?? defaultNewUserData,
		resolver: zodResolver(
			z.object({
				name: zExt.nonEmptyString(t),
				surname: zExt.nonEmptyString(t),
				email: z
					.string({ required_error: t("REQUIRED_FIELD") })
					.min(1)
					.email(t("login.INVALID_FIELDS_NOT")),
				role: zExt.nonEmptyString(t),
				customerId: zExt.nonEmptyString(t),
				enabled: z.boolean(),
				services: z.array(zExt.nonEmptyString(t)).min(1, t("REQUIRED_FIELD")),
				importFormats: z.array(z.nativeEnum(InvestmentImportConverterType)).optional(),
				exportFormats: z.array(z.nativeEnum(InvestmentExportConverterType)).optional(),
				importEnabled: z.boolean().optional(),
				importDirectory: z.string().optional(),
			} satisfies { [key in keyof UserModel]?: unknown }),
		),
	});

	const serviceTabContent = (
		<>
			{Array.isArray(userData) && (
				<div className="border-b-2 border-solid border-[color:#616161] pb-2 mb-2 drop-shadow grid space-y-2">
					<span className={defaultLabelClassName}>Choose override method</span>
					<Banner severity="warning">
						<p>Be aware, this action is irreversible. Please carefully consider your choices before proceeding.</p>
					</Banner>
					<RadioGroup value={overrideMethod} onChange={(x) => setOverrideMethod(x)} classList="!block mt-2">
						<div className="flex gap-4">
							{["Add", "Remove", "Replace"].map((method) => (
								<Radio value={method} key={method}>
									{method}
								</Radio>
							))}
						</div>
					</RadioGroup>
				</div>
			)}

			<FormFields.Custom
				control={control}
				formState={formState}
				label={t("SETTING_PANEL.NAME")}
				name="services"
				classList="mb-2"
			>
				{({ field: { onChange, onBlur, value: services }, id }) => (
					<>
						<TextInput
							leftContent={<Icon icon="Search" />}
							value={query}
							id={id}
							onChangeText={setQuery}
							classList="mb-4"
							placeholder="Search the services you need"
						/>
						<div className="grid gap-2">
							<ForEach collection={customObjectEntriesFn(filteredAndGrouped)} keyProvider={([category]) => category}>
								{({ item: [category, categorizedServices] }) => (
									<Collapsible
										header={(params) => <DefaultCollapsibleHeader {...params}>{category}</DefaultCollapsibleHeader>}
									>
										{({ expand }) => (
											<DefaultCollapsibleContent expand={expand}>
												<div className="grid grid-cols-3 gap-2">
													<ForEach collection={categorizedServices}>
														{({ item: service }) => (
															<Checkbox
																checked={services?.indexOf(service) !== -1}
																onChange={(checked) => {
																	onChange(
																		checked ? [...(services ?? []), service] : services.filter((s) => s !== service),
																	);
																	onBlur();
																}}
															>
																{() => {
																	const tooltip = getServiceTypeTooltip(service);
																	if (tooltip) {
																		return (
																			<AutoTooltip
																				position="right"
																				severity="success"
																				trigger={({ innerRef }) => (
																					<span ref={innerRef}>{t(`SERVICES.${service}`)}</span>
																				)}
																			>
																				{tooltip}
																			</AutoTooltip>
																		);
																	}
																	return t(`SERVICES.${service}`);
																}}
															</Checkbox>
														)}
													</ForEach>
												</div>
											</DefaultCollapsibleContent>
										)}
									</Collapsible>
								)}
							</ForEach>
						</div>
					</>
				)}
			</FormFields.Custom>

			<div className="grid gap-4">
				<Collapsible
					header={(params) => (
						<DefaultCollapsibleHeader {...params}>
							<div className="flex space-x-2 items-center">
								<p>{t("SETTING_PANEL.IMPORTS")}</p>
								<FormFields.Custom control={control} formState={formState} label="" name="services">
									{({ field: { onChange, onBlur, value: services }, id }) => (
										<Checkbox
											checked={services?.indexOf("IMPORT") !== -1}
											onChange={(checked) => {
												onChange(checked ? [...(services ?? []), "IMPORT"] : services.filter((s) => s !== "IMPORT"));
												onBlur();
											}}
											switchType="switch"
										/>
									)}
								</FormFields.Custom>
							</div>
						</DefaultCollapsibleHeader>
					)}
				>
					{({ expand }) => (
						<DefaultCollapsibleContent expand={expand}>
							<FormFields.Custom control={control} formState={formState} name="importFormats" label="">
								{({ field: { onChange, value: converters } }) => (
									<div className="grid grid-cols-3 gap-2">
										<ForEach collection={integrationConverter?.investmentImportConverter ?? []}>
											{({ item: converter }) => (
												<Checkbox
													checked={converters?.includes(converter) ?? false}
													disabled={!watch("services").includes("IMPORT")}
													classList="mr-2"
													onChange={(checked) => {
														onChange(
															checked ? [...(converters ?? []), converter] : converters?.filter((s) => s !== converter),
														);
													}}
												>
													{t(`IMPORT.${converter}`)}
												</Checkbox>
											)}
										</ForEach>
									</div>
								)}
							</FormFields.Custom>
						</DefaultCollapsibleContent>
					)}
				</Collapsible>

				<Collapsible
					header={(params) => (
						<DefaultCollapsibleHeader {...params}>
							<div className="flex space-x-2 items-center">
								<p>{t("SETTING_PANEL.EXPORTS")}</p>
								<FormFields.Custom control={control} formState={formState} label="" name="services">
									{({ field: { onChange, onBlur, value: services } }) => (
										<Checkbox
											checked={services?.indexOf("EXPORT") !== -1}
											onChange={(checked) => {
												onChange(checked ? [...(services ?? []), "EXPORT"] : services.filter((s) => s !== "EXPORT"));
												onBlur();
											}}
											switchType="switch"
										/>
									)}
								</FormFields.Custom>
							</div>
						</DefaultCollapsibleHeader>
					)}
				>
					{({ expand }) => (
						<DefaultCollapsibleContent expand={expand}>
							<FormFields.Custom control={control} formState={formState} name="exportFormats" label="">
								{({ field: { onChange, value: converters } }) => (
									<div className="grid grid-cols-3 gap-2">
										<ForEach collection={integrationConverter?.investmentExportConverter ?? []}>
											{({ item: converter }) => (
												<Checkbox
													checked={converters?.includes(converter) ?? false}
													disabled={!watch("services").includes("EXPORT")}
													classList="mr-2"
													onChange={(checked) => {
														onChange(
															checked ? [...(converters ?? []), converter] : converters?.filter((s) => s !== converter),
														);
													}}
												>
													{t(`EXPORT.${converter}`)}
												</Checkbox>
											)}
										</ForEach>
									</div>
								)}
							</FormFields.Custom>
						</DefaultCollapsibleContent>
					)}
				</Collapsible>

				{/* Deprecated, importEnabled is a per-customer setting */}
				<Collapsible
					header={(params) => (
						<DefaultCollapsibleHeader {...params}>
							<div className="flex space-x-2 items-center">
								<p>Auto-sync (deprecated, use the customer settings to edit)</p>
								<FormFields.Custom control={control} formState={formState} label="" name="importEnabled">
									{({ field: { onChange, value: enabled } }) => (
										<Checkbox disabled checked={enabled ?? false} onChange={onChange} switchType="switch" />
									)}
								</FormFields.Custom>
							</div>
						</DefaultCollapsibleHeader>
					)}
				>
					{({ expand }) => (
						<DefaultCollapsibleContent expand={expand}>
							<FormFields.Text
								disabled
								control={control}
								formState={formState}
								label="Directory"
								name="importDirectory"
							/>
						</DefaultCollapsibleContent>
					)}
				</Collapsible>
			</div>
		</>
	);

	return (
		<>
			{!userSyncData ? (
				<IconWalls.ErrorData />
			) : (
				<WallOverlay showOverlay={isLoading} overlay={<IconWalls.LoadingData opaque={false} />}>
					<Form noValidate onSubmitAsync={() => handleSubmit(onSubmit)()} classList="space-y-4">
						{Array.isArray(userData) ? (
							serviceTabContent
						) : (
							<TabGroup tabIndex={tabIndex} onTabChange={setTabIndex}>
								<Tab
									title={(titleProps) => (
										<DefaultTabTitle
											{...titleProps}
											style={{
												color:
													formState.errors.customerId ||
													formState.errors.email ||
													formState.errors.enabled ||
													formState.errors.name ||
													formState.errors.role ||
													formState.errors.surname
														? themeCSSVars.palette_D500
														: undefined,
											}}
										>
											Info
										</DefaultTabTitle>
									)}
								>
									<div className="grid grid-cols-2 gap-2">
										<FormFields.Text
											control={control}
											formState={formState}
											label={t("SETTING_PANEL.NAME")}
											name="name"
											placeholder={t("SETTING_PANEL.NAME")}
										/>
										<FormFields.Text
											control={control}
											formState={formState}
											label={t("SETTING_PANEL.SURNAME")}
											name="surname"
											placeholder={t("SETTING_PANEL.SURNAME")}
										/>
										<div className="col-span-2">
											<FormFields.Text
												type="email"
												control={control}
												formState={formState}
												label={t("SETTING_PANEL.EMAIL")}
												name="email"
												placeholder={t("SETTING_PANEL.EMAIL")}
												disabled={mode === "edit"}
											/>
										</div>
										<FormFields.Select
											enableSearch
											control={control}
											formState={formState}
											label={t("SETTING_PANEL.CUSTOMER")}
											name="customerId"
											options={customerSelect}
										/>
										<FormFields.Select
											control={control}
											formState={formState}
											label={t("SETTING_PANEL.ROLE")}
											name="role"
											options={roleOptions}
											disabled={mode === "edit"}
										/>
										<FormFields.Checkbox
											control={control}
											formState={formState}
											label={t("SETTING_PANEL.USER_ENABLED")}
											name="enabled"
											disabled={isCurrentUser}
										>
											{t("SETTING_PANEL.USER_ENABLED")}
										</FormFields.Checkbox>
									</div>
								</Tab>
								<Tab
									title={(titleProps) => (
										<DefaultTabTitle
											{...titleProps}
											style={{
												color:
													formState.errors.exportFormats || formState.errors.importFormats || formState.errors.services
														? themeCSSVars.palette_D500
														: undefined,
											}}
										>
											Services
										</DefaultTabTitle>
									)}
								>
									{serviceTabContent}
								</Tab>
							</TabGroup>
						)}
						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
							}}
						>
							<SubmitButton>
								{mode === "edit" ? t("SETTING_PANEL.UPDATE_USER") : t("SETTING_PANEL.NEW_USER")}
							</SubmitButton>
						</div>
					</Form>
				</WallOverlay>
			)}
		</>
	);
};

const LoadCustomImage = (props: { onChange?(file?: string): void }) => {
	const ACCEPTED_FILE = "image/*";
	const { t } = useTranslation();
	const bucketApi = useApiGen(BucketControllerApiFactory);

	const [isVerifing, setIsVerifing] = useState(false);

	const { watch, reset, setValue } = useForm<{
		filename: string;
		fileContent: null | File;
	}>({
		defaultValues: {
			filename: "",
			fileContent: null satisfies null | File,
		},
	});

	const observableFileName = watch("filename");
	const observableFileContentController = watch("fileContent");

	const handleFileChange = React.useCallback(
		async (file: File | null) => {
			try {
				setIsVerifing(true);
				if (!file) {
					props.onChange?.(undefined);
					return reset();
				}

				const fileUrl = await axiosExtract(bucketApi.uploadFile(file));
				setValue("fileContent", file);
				setValue("filename", file.name);
				props.onChange?.(fileUrl);
			} catch (err) {
				console.log(err);
				if (err instanceof LocalizedError) {
					platformToast({
						children: err.localizedMsg,
						severity: "error",
						icon: "Positioning-indicator",
					});
				} else {
					platformToast({
						children: t("PORTFOLIOS.PORTFOLIO_GENERIC_UPLOAD_ERROR"),
						severity: "error",
						icon: "Positioning-indicator",
					});
				}
			} finally {
				setIsVerifing(false);
			}
		},
		[bucketApi, props, reset, setValue, t],
	);

	const uploadState = useMemo(() => {
		if (observableFileName) {
			return "success";
		}

		return "empty";
	}, [observableFileName]);

	const BoxMap = {
		empty: <EmptyImportBox />,
		success: <SuccessImportBox />,
	} satisfies Record<typeof uploadState, ReactNode>;

	return (
		<DropzoneArea
			onChange={unpromisify(handleFileChange)}
			accept={ACCEPTED_FILE}
			disabled={isVerifing}
			childrenWrapperAppearance={{
				classList: {
					[`relative rounded flex flex-1 gap-4 p-2 justify-between items-center border-2 `]: true,
					[`bg-[color:${themeCSSVars.palette_N50}] border-dashed border-[color:${themeCSSVars.palette_N500}]`]:
						observableFileName === null,
					[`bg-[color:${themeCSSVars.palette_N0}] border-[color:${themeCSSVars.palette_P600}]`]:
						Boolean(observableFileName),
				},
			}}
		>
			<div className="flex gap-2 items-center">
				{BoxMap[uploadState]}
				<p className="font-semibold">{observableFileName || "Select a file or drag and drop here"}</p>
			</div>

			{observableFileContentController ? (
				<Button unstyled size="small" onClick={() => reset()}>
					<Icon icon="Delete" size={20} color={themeCSSVars.palette_N500} />
				</Button>
			) : (
				<UploadButton
					size="small"
					onChange={unpromisify(handleFileChange)}
					accept={ACCEPTED_FILE}
					disabled={isVerifing}
					loading={isVerifing}
					label={observableFileContentController ? t("SELECT_NEW_FILE") : t("BUTTON.SELECT")}
				/>
			)}
		</DropzoneArea>
	);
};

type Ranges = { min?: number; max?: number };
const CustomerBox = (props: { onFinish: () => Promise<void>; customerData?: CustomerModel }) => {
	const { onFinish, customerData } = props;
	const mode = customerData ? "edit" : "new";

	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [currentTab, setCurrentTab] = useState<number>(0);
	const [portfolioSyncStaus, setPortfolioSyncStaus] = useState<"success" | "failed" | "deafult">("deafult");

	const customerV3Api = useApiGen(CustomerControllerV3ApiFactory);
	const userApi = useApiGen(UserControllerApiFactory);
	const protocolsApi = useApiGen(ProtocolsControllerApiFactory);

	const importServices = useQueryNoRefetch(["queryImportServices"], {
		queryFn: () => axiosExtract(customerV3Api.retrieveServicesType()),
	});

	const usersLinkToCustomers = useQueryNoRefetch(["queryUsersLinkToCustomers"], {
		enabled: customerData?.id !== undefined,
		queryFn: () => axiosExtract(customerV3Api.users(customerData!.id!)),
	});
	// CustomerModelDTO payload for updating customer
	const TABS = ["Info", "Services"];

	const customerSyncData = useMemo(() => ({ ...defaultNewCustomerData, ...customerData }), [customerData]);

	const { control, formState, handleSubmit, setValue, watch } = useForm({
		defaultValues: customerSyncData,
		resolver: zodResolver(
			z.object({
				importEnabled: z.boolean().optional(),
				importDirectory: z.string().optional(),
				importServiceType: z.nativeEnum(InvestmentImportConverterType).optional(),
				importPollingFrequencyInMinutes: z.number().optional(),
				userWhoReceivePtfFromImport: z.string().optional(),
				importConnectionType: z.nativeEnum(ImportDtoImportConnectionTypeEnum).optional(),
				enabled: z.boolean().optional(),
				name: zExt.nonEmptyString(t),
				theme: z.string().optional(),
				maxUsers: z.number({ required_error: t("REQUIRED_FIELD") }),
				maxPortfolios: z.number().optional().nullable(), // use line 832 when api will be ready
				tokenDuration: z.number({ required_error: t("REQUIRED_FIELD") }),
				// TODO: uncomment once the API is ready
				// commentsPerMonth: z.number({ required_error: t("REQUIRED_FIELD") }),
				// .min(24, t("SETTING_PANEL.INVALID_DURATION_VALUE")),
				importUrl: z.string().optional(),
				importPassword: z.string().optional(),
				port: z.number().optional(),
				importUsername: z.string().optional(),
				exportConnectionType: z.string().optional(),
				exportEnabled: z.boolean().optional(),
				exportDirectory: z.string().optional(),
				exportPassword: z.string().optional(),
				exportPort: z.number().optional(),
				exportServiceType: z.string().optional(),
				exportUrl: z.string().optional(),
				exportUsername: z.string().optional(),
				id: z.string().optional(),
				address: z.string().optional(),
				backgroundImage: z.string().optional(),
				clientLogo: z.string().optional(),
				disclaimer: z.string().optional(),
				footerImage: z.string().optional(),
				footerDescription: z.string().optional(),
				email: z.string().optional(),
				website: z.string().optional(),
				marketViewSettings: z
					.object({
						activeMarketViewTypes: z.array(z.nativeEnum(MarketViewSettingsActiveMarketViewTypesEnum)).optional(),
						customMarketViewName: z.string({ required_error: t("REQUIRED_FIELD") }).optional(),
						customMarketViewType: z.nativeEnum(MarketViewType).optional(),
						customMarketViewScenarioIds: z.array(z.nativeEnum(MarketScenarioIds)).optional(),
						customMarketViewMicroAssetClasses: z
							.array(
								z.object({
									alias: z.string().optional(),
									microAssetClass: z.any().optional(),
								} satisfies Record<keyof CustomMarketViewMicroAssetClass, unknown>),
							)
							.optional(),
						commentaryEditorVisible: z.boolean().optional(),
						customMarketViewAssetClasses: z.array(z.nativeEnum(MarketViewMicroAssetClasses)).optional(),
						defaultExpectedReturnMarketViewAssetClasses: z.array(z.nativeEnum(MarketViewMicroAssetClasses)).optional(),
						defaultPositioningMarketViewAssetClasses: z.array(z.nativeEnum(MarketViewMicroAssetClasses)).optional(),
						marketViewAssetClassAliases: z
							.array(
								z.object({
									alias: z.string().optional(),
									microAssetClass: z.any().optional(),
								} satisfies Record<keyof CustomMarketViewMicroAssetClass, unknown>),
							)
							.optional(),
					} satisfies Record<keyof MarketViewSettings, unknown>)
					.optional(),
				lowRiskProfile: z.string().optional(),
				midRiskProfile: z.string().optional(),
				highRiskProfile: z.string().optional(),
				profilePhoto: z.string().optional(),
			} satisfies Record<
				keyof CustomerModel & { lowRiskProfile: Ranges; midRiskProfile: Ranges; highRiskProfile: Ranges },
				unknown
			>),
		),
	});

	const onSubmit = () => {
		return handleSubmit(async (values) => {
			setIsLoading(true);
			console.log(values);
			try {
				switch (mode) {
					case "new": {
						await customerV3Api.createCustomerWithDto(values);
						break;
					}
					case "edit": {
						await customerV3Api.updateCustomer({
							importData: {
								directory: values.importDirectory,
								enabled: values.importEnabled,
								importConnectionType: values.importConnectionType,
								importServiceType: values.importServiceType,
								password: values.importPassword,
								pollingFrequencyInMinutes: values.importPollingFrequencyInMinutes,
								port: values.port,
								url: values.importUrl,
								username: values.importUsername,
								userWhoReceivePtfFromImport: values.userWhoReceivePtfFromImport,
							},
							enabled: values.enabled,
							id: values.id,
							maxPortfolios: values.maxPortfolios,
							maxUsers: values.maxUsers,
							name: values.name,
							theme: values.theme,
							tokenDuration: values.tokenDuration,
							address: values.address,
							backgroundImage: values.backgroundImage,
							clientLogo: values.clientLogo,
							disclaimer: values.disclaimer,
							email: values.email,
							footerDescription: values.footerDescription,
							footerImage: values.footerImage,
							website: values.website,
							marketViewSettings: {
								activeMarketViewTypes: values.marketViewSettings?.activeMarketViewTypes,
								commentaryEditorVisible: values.marketViewSettings?.commentaryEditorVisible,
								customMarketViewMicroAssetClasses: values.marketViewSettings?.customMarketViewMicroAssetClasses,
								customMarketViewName: values.marketViewSettings?.customMarketViewName,
								customMarketViewScenarioIds: values.marketViewSettings?.customMarketViewScenarioIds,
								customMarketViewType: values.marketViewSettings?.customMarketViewType,
							},
							profilePhoto: values.profilePhoto,
							// TODO: uncomment once the API is ready
							// commentsPerMonth: values.commentsPerMonth,
						});
						break;
					}
				}
				await onFinish();
				setIsLoading(false);
			} catch (e) {
				platformToast({
					children: t("SETTING_PANEL.CREATE_CUSTOMER_ERROR"),
					severity: "error",
					icon: "Top-menu-user",
				});
				throw e;
			} finally {
				setIsLoading(false);
			}
		})();
	};

	const onTestSyncProtocol = React.useCallback(async () => {
		try {
			setPortfolioSyncStaus("success");
			await protocolsApi.ftpCheck({
				host: watch("importUrl"),
				password: watch("importPassword"),
				port: watch("port"),
				username: watch("importUsername"),
			});
		} catch (error) {
			setPortfolioSyncStaus("failed");
			console.log(error);
		}
	}, [protocolsApi, watch]);

	return (
		<>
			<Form noValidate onSubmitAsync={() => onSubmit()} classList="space-y-8">
				<TabGroup tabIndex={currentTab} onTabChange={setCurrentTab}>
					<Tab
						classList="rounded bg-white overflow-hidden"
						key={0}
						title={(titleProps) => (
							<DefaultTabTitle
								{...titleProps}
								style={{
									color:
										formState.errors.name ||
										formState.errors.theme ||
										formState.errors.maxUsers ||
										formState.errors.maxPortfolios ||
										formState.errors.tokenDuration ||
										formState.errors.enabled
											? themeCSSVars.palette_D500
											: undefined,
								}}
							>
								{TABS[0]}
							</DefaultTabTitle>
						)}
					>
						{!customerSyncData ? (
							<IconWalls.ErrorData />
						) : (
							<WallOverlay showOverlay={isLoading} overlay={<IconWalls.LoadingData opaque={false} />}>
								<div className="grid gap-2">
									<div className="grid grid-cols-2 gap-4">
										<FormFields.Text
											control={control}
											formState={formState}
											name="name"
											label={t("SETTING_PANEL.NAME")}
											placeholder={t("SETTING_PANEL.NAME")}
										/>
										<FormFields.Text
											control={control}
											formState={formState}
											name="theme"
											label={t("SETTING_PANEL.THEME")}
											placeholder={t("SETTING_PANEL.THEME")}
										/>
									</div>
									<div className="grid grid-cols-2 gap-4">
										<FormFields.NullableNumber
											control={control}
											formState={formState}
											name="maxUsers"
											label={t("SETTING_PANEL.MAX_USERS")}
											placeholder={t("SETTING_PANEL.MAX_USERS")}
										/>
										<FormFields.NullableNumber
											control={control}
											formState={formState}
											name="maxPortfolios"
											label={t("SETTING_PANEL.MAX_PORTFOLIOS")}
											placeholder={t("SETTING_PANEL.MAX_PORTFOLIOS")}
										/>
									</div>
									<div className="grid grid-cols-2 gap-4">
										{/* <FormFields.NullableNumber
											control={control}
											formState={formState}
											// TODO: uncomment once the API is ready
											// name="commentsPerMonth"
											name="maxPortfolios"
											label={t("SETTING_PANEL.COMMENTS_PER_MONTH")}
											placeholder={t("SETTING_PANEL.COMMENTS_PER_MONTH")} //FIX-ME: wait for back office api to introduce threshold
										/> */}
										<FormFields.NullableNumber
											control={control}
											formState={formState}
											name="tokenDuration"
											label={t("SETTING_PANEL.EXPIRATION")}
											placeholder={t("SETTING_PANEL.EXPIRATION")}
										/>
									</div>
									<div className="flex flex-col">
										<p className="text-[12px] leading-[16px] text-[#616161] font-semibold">Profile photo</p>
										{watch("profilePhoto") ? (
											<div
												className={toClassName({
													[`relative rounded flex flex-1 gap-4 p-2 justify-between items-center border-2 `]: true,
													[`bg-[color:${themeCSSVars.palette_N0}] border-[color:${themeCSSVars.palette_P600}]`]: true,
												})}
											>
												<div className="flex items-center gap-2">
													<SuccessImportBox />
													<p className="font-semibold">File Uploaded</p>
												</div>
												<Button palette="secondary" onClick={() => setValue("profilePhoto", undefined)}>
													<Icon icon="Delete" />
												</Button>
											</div>
										) : (
											<LoadCustomImage onChange={(url) => setValue("profilePhoto", url)} />
										)}
									</div>

									<FormFields.Checkbox
										control={control}
										formState={formState}
										name="enabled"
										label={t("SETTING_PANEL.CUSTOMER_ENABLED")}
									>
										{t("SETTING_PANEL.CUSTOMER_ENABLED")}
									</FormFields.Checkbox>
								</div>
							</WallOverlay>
						)}
					</Tab>
					<Tab
						classList="rounded bg-white overflow-hidden"
						key={1}
						title={(titleProps) => (
							<DefaultTabTitle
								{...titleProps}
								style={{
									color:
										!(
											formState.errors.name ||
											formState.errors.theme ||
											formState.errors.maxUsers ||
											formState.errors.maxPortfolios ||
											formState.errors.tokenDuration ||
											formState.errors.enabled
										) && Object.values(formState.errors).some(Boolean)
											? themeCSSVars.palette_D500
											: undefined,
								}}
							>
								{TABS[1]}
							</DefaultTabTitle>
						)}
					>
						<div className="grid gap-4">
							<Collapsible
								header={(headerPros) => (
									<DefaultCollapsibleHeader {...headerPros}>
										<div className="flex gap-4 items-center">
											<p>Auto-Sync</p>
											<FormFields.Checkbox
												control={control}
												formState={formState}
												switchType="switch"
												name="importEnabled"
											/>
										</div>
									</DefaultCollapsibleHeader>
								)}
							>
								{({ expand }) => (
									<DefaultCollapsibleContent expand={expand}>
										<div className="grid grid-cols-4 gap-4">
											<FormFields.Text
												control={control}
												formState={formState}
												name="importUsername"
												label={t("SETTING_PANEL.NAME")}
												placeholder={t("SETTING_PANEL.NAME")}
											/>
											{!watch("importPassword") || formState.dirtyFields.importPassword ? (
												<FormFields.Password
													control={control}
													formState={formState}
													name="importPassword"
													label={t("SETTING_PANEL.PASSWORD")}
													placeholder={t("SETTING_PANEL.PASSWORD")}
													autoComplete="off"
												/>
											) : (
												<div>
													<Label htmlFor="dummy">Password</Label>
													<Button
														palette="neutralOutline"
														classList="w-full"
														size="small"
														onClick={() => setValue("importPassword", "")}
													>
														<div className="flex justify-between items-center w-full">
															<p>Change Password</p>
															<Icon icon="Top-menu-password" size={16} />
														</div>
													</Button>
												</div>
											)}

											<FormFields.Text
												control={control}
												formState={formState}
												name="importUrl"
												label={t("SETTING_PANEL.URL")}
												placeholder={t("SETTING_PANEL.URL")}
											/>

											<FormFields.NullableNumber
												control={control}
												formState={formState}
												name="importPollingFrequencyInMinutes"
												label={t("SETTING_PANEL.FREQUENCY")}
												placeholder={t("SETTING_PANEL.FREQUENCY")}
											/>

											<div className="col-span-2">
												<FormFields.Text
													control={control}
													formState={formState}
													name="importDirectory"
													label={t("SETTING_PANEL.DIRECTORY")}
													placeholder={t("SETTING_PANEL.DIRECTORY")}
												/>
											</div>
											<div className="col-span-2">
												<FormFields.NullableNumber
													control={control}
													formState={formState}
													name="port"
													label={t("SETTING_PANEL.PORT")}
													placeholder={t("SETTING_PANEL.PORT")}
												/>{" "}
											</div>

											<FormFields.Select
												control={control}
												formState={formState}
												name="userWhoReceivePtfFromImport"
												label={t("SETTING_PANEL.USER")}
												enableSearch
												listboxAppearance={{ classList: "!max-h-[220px]" }}
												options={
													usersLinkToCustomers.data?.flatMap((user) => {
														if (user.email && user.id) {
															return [{ label: user.email, value: user.id }];
														}

														return [];
													}) ?? []
												}
											/>

											<FormFields.Select
												control={control}
												formState={formState}
												name="importServiceType"
												label={t("SETTING_PANEL.CONVERTER_SERVICES")}
												options={importServices.data?.map((service) => ({ label: service, value: service })) ?? []}
											/>

											<div className="col-span-2">
												<FormFields.Select
													control={control}
													formState={formState}
													name="importConnectionType"
													label={t("SETTING_PANEL.CONNECTOR_SERVICES")}
													options={[
														{
															label: ImportDtoImportConnectionTypeEnum.FileCloud,
															value: ImportDtoImportConnectionTypeEnum.FileCloud,
														},
														{
															label: ImportDtoImportConnectionTypeEnum.Sftp,
															value: ImportDtoImportConnectionTypeEnum.Sftp,
														},
													]}
												/>
											</div>
											<div className="col-span-4">
												<AsyncButton
													palette="primary"
													size="small"
													classList="w-full justify-center"
													onClickAsync={onTestSyncProtocol}
												>
													Test connection
												</AsyncButton>
												<p
													className={toClassName({
														"text-center font-semibold": true,
														[`text-[color:${themeCSSVars.palette_D400}]`]: portfolioSyncStaus === "failed",
													})}
												>
													<Switch
														case={portfolioSyncStaus}
														match={{
															deafult: () => "",
															failed: () => "Your connect failed, please update your settings",
															success: () => "You successfully establish a connection",
														}}
													/>
												</p>
											</div>
										</div>
									</DefaultCollapsibleContent>
								)}
							</Collapsible>
							<Collapsible
								header={(headerProps) => (
									<DefaultCollapsibleHeader {...headerProps}>
										<div className="flex gap-4 items-center">
											<p>Custom market views with commentaries</p>
											<FormFields.Checkbox
												control={control}
												formState={formState}
												switchType="switch"
												name="marketViewSettings.commentaryEditorVisible"
											/>
										</div>
									</DefaultCollapsibleHeader>
								)}
							>
								{() => <></>}
							</Collapsible>
							<Collapsible
								header={(headerProps) => (
									<DefaultCollapsibleHeader {...headerProps}>
										<div className="flex gap-4 items-center">
											<p>Customize portfolio pdf</p>
											{customerData?.id && (
												<span onClick={(e) => e.stopPropagation()}>
													<Button
														palette="secondary"
														size="x-small"
														onClick={() =>
															window.open(typedUrlForRoute("Report/Editor", { customerId: customerData.id! }), "_blank")
														}
													>
														Pro editor
													</Button>
												</span>
											)}
										</div>
									</DefaultCollapsibleHeader>
								)}
							>
								{({ expand }) => (
									<DefaultCollapsibleContent expand={expand}>
										<div className="grid gap-3 relative">
											<Text
												as="p"
												type="Body/L/Bold"
												classList=" border-b border-[color:#616161] border-solid  drop-shadow "
											>
												Cover
											</Text>
											<div className="grid grid-cols-2 gap-4">
												<div className="flex flex-col">
													<p className="text-[12px] leading-[16px] text-[#616161] font-semibold">logo</p>
													{watch("clientLogo") ? (
														<div
															className={toClassName({
																[`relative rounded flex flex-1 gap-4 p-2 justify-between items-center border-2 `]: true,
																[`bg-[color:${themeCSSVars.palette_N0}] border-[color:${themeCSSVars.palette_P600}]`]:
																	true,
															})}
														>
															<div className="flex items-center gap-2">
																<SuccessImportBox />
																<p className="font-semibold">File Uploaded</p>
															</div>
															<Button palette="secondary" onClick={() => setValue("clientLogo", undefined)}>
																<Icon icon="Delete" />
															</Button>
														</div>
													) : (
														<LoadCustomImage onChange={(url) => setValue("clientLogo", url)} />
													)}
												</div>
												<div className="flex flex-col">
													<p className="text-[12px] leading-[16px] text-[#616161] font-semibold">background</p>
													{watch("backgroundImage") ? (
														<div
															className={toClassName({
																[`relative rounded flex flex-1 gap-4 p-2 justify-between items-center border-2 `]: true,
																[`bg-[color:${themeCSSVars.palette_N0}] border-[color:${themeCSSVars.palette_P600}]`]:
																	true,
															})}
														>
															<div className="flex items-center gap-2">
																<SuccessImportBox />
																<p className="font-semibold">File Uploaded</p>
															</div>
															<Button palette="secondary" onClick={() => setValue("backgroundImage", undefined)}>
																<Icon icon="Delete" />
															</Button>
														</div>
													) : (
														<LoadCustomImage onChange={(url) => setValue("backgroundImage", url)} />
													)}
												</div>
											</div>

											<Text
												as="p"
												type="Body/L/Bold"
												classList="border-b border-[color:#616161] border-solid  drop-shadow "
											>
												Page Content
											</Text>

											<div className="grid grid-cols-2 gap-2">
												<div className="flex flex-col">
													<p className="text-[12px] leading-[16px] text-[#616161] font-semibold">logo</p>
													{watch("footerImage") ? (
														<div
															className={toClassName({
																[`relative rounded flex flex-1 gap-4 p-2 justify-between items-center border-2 `]: true,
																[`bg-[color:${themeCSSVars.palette_N0}] border-[color:${themeCSSVars.palette_P600}]`]:
																	true,
															})}
														>
															<div className="flex items-center gap-2">
																<SuccessImportBox />
																<p className="font-semibold">File Uploaded</p>
															</div>
															<Button palette="secondary" onClick={() => setValue("footerImage", undefined)}>
																<Icon icon="Delete" />
															</Button>
														</div>
													) : (
														<LoadCustomImage onChange={(url) => setValue("footerImage", url)} />
													)}
												</div>
												<FormFields.TextArea
													control={control}
													formState={formState}
													label="description"
													name="footerDescription"
													rows={4}
												/>
											</div>

											<Text
												as="p"
												type="Body/L/Bold"
												classList="border-b border-[color:#616161] border-solid  drop-shadow "
											>
												Back cover
											</Text>
											<div className="grid grid-cols-2 gap-2">
												<FormFields.TextArea
													control={control}
													formState={formState}
													label="disclaimer"
													name="disclaimer"
													rows={4}
												/>
												<FormFields.TextArea
													control={control}
													formState={formState}
													label="address"
													name="address"
													rows={4}
												/>
												<FormFields.TextArea
													control={control}
													formState={formState}
													label="email"
													name="email"
													rows={4}
												/>
												<FormFields.TextArea
													control={control}
													formState={formState}
													label="website"
													name="website"
													rows={4}
												/>
											</div>
										</div>
									</DefaultCollapsibleContent>
								)}
							</Collapsible>

							<Collapsible
								header={(headerProps) => (
									<DefaultCollapsibleHeader {...headerProps}>
										<div className="flex gap-4 items-center">
											<p>Risk profile</p>
										</div>
									</DefaultCollapsibleHeader>
								)}
							>
								{({ expand }) => (
									<DefaultCollapsibleContent expand={expand}>
										<div className="grid grid-cols-3 gap-3">
											<div>
												<div className="font-semibold mb-0.5">Low</div>
												<FormFields.NullableNumber
													control={control}
													formState={formState}
													label="max"
													name="lowRiskProfile"
													classList="flex-1"
												/>
											</div>
											<div>
												<div className="font-semibold mb-0.5">Mid</div>
												<FormFields.NullableNumber
													control={control}
													formState={formState}
													label="max"
													name="midRiskProfile"
													classList="flex-1"
												/>
											</div>
											<div>
												<div className="font-semibold mb-0.5">High</div>
												<FormFields.NullableNumber
													control={control}
													formState={formState}
													label="max"
													name="highRiskProfile"
													classList="flex-1"
												/>
											</div>
										</div>
									</DefaultCollapsibleContent>
								)}
							</Collapsible>
						</div>
					</Tab>
				</TabGroup>
				<div
					style={{
						display: "flex",
						justifyContent: "flex-end",
					}}
				>
					<SubmitButton>
						{mode === "edit" ? t("SETTING_PANEL.UPDATE_CUSTOMER") : t("SETTING_PANEL.NEW_CUSTOMER")}
					</SubmitButton>
				</div>
			</Form>
		</>
	);
};

const FeedbackModalBox = (props: { userId: string; userName: string }) => {
	const { userId, userName } = props;
	const [feedbackModal, setFeedbackModal] = useState(false);
	const feedbacksV2Api = useApiGen(FeedbacksControllerApiFactory);

	const columns = useMemo<Array<TableColumn<FeedbackResponse>>>(
		() => [
			{
				sortFn: builtInSortFnFor("dateTime"),
				name: "dateTime",
				header: "Date",
				content: ({ dateTime }) => dateTime,
				relativeWidth: 1,
			},
			{
				sortFn: builtInSortFnFor("referenceMessage"),
				name: "referenceMessage",
				header: "Reference Message",
				content: ({ referenceMessage }) => referenceMessage,
				relativeWidth: 4,
				cellClassList: "!overflow-auto !break-normal !whitespace-normal !max-h-[100px]",
			},
			{
				header: "Feedback message",
				content: ({ message }) => message,
				relativeWidth: 4,
				cellClassList: "!overflow-auto !break-normal !whitespace-normal !max-h-[100px]",
			},
			{
				sortFn: (a, b) => builtInSort(a.rating, b.rating),
				header: "rating",
				name: "rating",
				align: "end",
				cellClassList: "tabular-nums",
				content: ({ rating }) => rating,
				relativeWidth: 1,
			},
		],
		[],
	);

	const { data: feedbackData } = useQueryNoRefetch({
		queryKey: ["feedback-query", userId],
		enabled: feedbackModal && Boolean(userId),
		queryFn: async () => {
			const feedback = await feedbacksV2Api.listFeedback(userId);
			return feedback.data;
		},
	});

	return (
		<>
			<Dialog
				size="xxlarge"
				show={feedbackModal}
				onClose={() => setFeedbackModal(false)}
				header={`${userName} feedbacks`}
			>
				<Table
					columns={columns}
					rows={feedbackData ?? []}
					noDataText="No Feedbacks"
					rowClassList="!h-full p-2"
					visibleRows={8}
					classList="max-h-screen overflow-scroll"
				/>
			</Dialog>
			<button
				type="button"
				onClick={() => {
					setFeedbackModal(true);
				}}
				style={{ cursor: "pointer" }}
			>
				<Icon icon="Evaluate" size={22} color={PaletteColors.GREENY_BLUE} />
			</button>
		</>
	);
};

const defaultUserListQuery = { text: "", roles: [] as UserRole[], customers: [] as string[] };

const UsersList = (props: {
	roles: TRole;
	userRole: number;
	currentUserEmail: string;
	users: UserModel[];
	customers: CustomerModel[];
	onFinish: (callback: React.Dispatch<React.SetStateAction<boolean>>) => Promise<void>;
}) => {
	const { users, customers, onFinish, roles, userRole, currentUserEmail } = props;
	const { t } = useTranslation();
	const userApi = useApiGen(UserControllerApiFactory);
	const [modal, setModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedUser, setSelectedUser] = useState<MaybeArray<UserModel> | undefined>();

	const { query, filtered, setQuery } = useSearchable({
		query: defaultUserListQuery,
		collection: users,
		matchFn: (row, q) => {
			const stringifiedRow = JSON.stringify(row).toLowerCase();
			return Boolean(
				stringifiedRow.includes(q.text.toLowerCase()) &&
					(q.roles.length === 0 ||
						q.roles.some((r) => row.roles?.some((r2) => r === r2)) ||
						q.roles.some((r) => row.role === r)) &&
					(q.customers.length === 0 || q.customers.some((c) => row.customerId === c)),
			);
		},
	});

	const setQueryText = (text: string) => setQuery({ ...query, text });
	const setQueryRoles = (r: UserRole[]) => setQuery({ ...query, roles: r });
	const setQueryCustomers = (c: string[]) => setQuery({ ...query, customers: c });
	const roleOptions = useMemo(() => t("SETTING_PANEL.ROLES_OPTIONS", { returnObjects: true }), [t]);

	const customerOptions = useMemo(
		() =>
			customers.map((c) => ({
				label: `${c.name ?? "-"} (#${c.id!})`,
				value: c.id ?? "",
			})),
		[customers],
	);

	const enableUser = React.useCallback(
		async (userData: UserModel) => {
			setIsLoading(true);
			try {
				await userApi.updateUser(userData.id!, {
					...userData,
					enabled: !userData.enabled,
				});
				await onFinish(setModal);
				setIsLoading(false);
			} catch (e) {
				platformToast({
					children: t("SETTING_PANEL.CREATE_USER_ERROR"),
					severity: "error",
					icon: "Top-menu-user",
				});
			}
		},
		[t, onFinish, setIsLoading, userApi],
	);

	const { multiSelectCtx, column, rowClassList, toggle } = useSelectableTableColumn({
		filteredRows: filtered,
		rows: users,
		selectBy: (r) => r.id ?? "",
		selectableRowIds: filterMap(users, (u) =>
			u.role === UserRole.ROOT || u.roles?.includes(UserRole.ROOT) ? null : { value: u.id ?? "" },
		),
	});

	const rolesOptions = t("SETTING_PANEL.ROLES_OPTIONS", { returnObjects: true });

	const columns = useMemo<Array<TableColumn<UserModel>>>(
		() => [
			column,
			{
				sortFn: builtInSortFnFor("id"),
				name: "id",
				header: "ID",
				cellStyle: ({ email }) => ({
					color: email === currentUserEmail ? themeCSSVars.global_palette_primary_600 : undefined,
					textDecorationColor: email === currentUserEmail ? themeCSSVars.global_palette_primary_600 : undefined,
				}),
				cellClassList: ({ email }) => (email === currentUserEmail ? "underline" : undefined),
				content: ({ id }) => id,
			},
			{
				sortFn: builtInSortFnFor("email"),
				name: "email",
				header: "Email",
				cellStyle: ({ email }) => ({
					color: email === currentUserEmail ? themeCSSVars.global_palette_primary_600 : undefined,
				}),
				content: ({ email }) => email ?? "-",
			},
			{
				sortFn: (a, b) => builtInSort(a.name?.toLowerCase(), b.name?.toLowerCase()),
				header: "Name",
				name: "Name",
				cellStyle: ({ email }) => ({
					color: email === currentUserEmail ? themeCSSVars.global_palette_primary_600 : undefined,
				}),
				content: ({ name }) => name ?? "-",
			},
			{
				sortFn: (a, b) => builtInSort(a.surname?.toLowerCase(), b.surname?.toLowerCase()),
				header: "Surname",
				name: "surname",
				cellStyle: ({ email }) => ({
					color: email === currentUserEmail ? themeCSSVars.global_palette_primary_600 : undefined,
				}),
				content: ({ surname }) => surname ?? "-",
			},
			{
				sortFn: builtInSortFnFor("role"),
				name: "role",
				header: "Role",
				cellStyle: ({ email }) => ({
					color: email === currentUserEmail ? themeCSSVars.global_palette_primary_600 : undefined,
				}),
				content: ({ role }) => rolesOptions.find((r) => r.value === role)?.label ?? role ?? "-",
			},

			{
				sortFn: builtInSortFnFor("creation_time"),
				name: "creation_time",
				header: "Creation Time",
				cellStyle: ({ email }) => ({
					color: email === currentUserEmail ? themeCSSVars.global_palette_primary_600 : undefined,
				}),

				content: ({ email, creation_time }) => creation_time?.substring(0, 10) ?? "-",
			},
			{
				sortFn: builtInSortFnFor("customerId"),
				name: "customerId",
				header: "Customer",
				cellStyle: ({ email }) => ({
					color: email === currentUserEmail ? themeCSSVars.global_palette_primary_600 : undefined,
				}),
				content: ({ email, customerId }) => {
					const customer = customers.filter((c) => c.id === customerId)[0];
					return customer?.name ?? "-";
				},
			},
			{
				header: "Services",
				content: ({ services }) => {
					return (
						<AutoTooltip
							severity="info"
							overrideColor={themeCSSVars.palette_N200}
							trigger={({ innerRef }) => (
								<div ref={innerRef} className="flex space-x-2 w-full">
									<p className="font-semibold">Enabled</p>
									<div
										className={`flex justify-center items-center w-5 h-5 rounded-full bg-[color:${themeCSSVars.palette_P400}] text-white font-sm`}
									>
										{services?.length}
									</div>
								</div>
							)}
						>
							<TooltipContent>
								{(services ?? []).map((service, i) => (
									<p key={`${service}-${i}`}>{t(`SERVICES.${service}`)}</p>
								))}
							</TooltipContent>
						</AutoTooltip>
					);
				},
			},
			{
				sortFn: builtInSortFnFor("enabled"),
				name: "enabled",
				header: "Enabled",
				content: (row) => {
					const { enabled, email, role } = row;
					const isUserDisabled = userRole <= roles[role!];
					const isCurrentUser = currentUserEmail === email;
					return !isCurrentUser ? (
						<Checkbox
							onClick={(e) => e.stopPropagation()}
							switchType="switch"
							disabled={isLoading || isUserDisabled}
							checked={enabled ?? false}
							onChange={unpromisify(() => enableUser(row))}
						/>
					) : (
						<></>
					);
				},
				relativeWidth: 0.4,
			},
			{
				header: "Actions",
				content: (row) => {
					const { email, role, id } = row;
					const isUserDisabled = userRole <= roles[role!];
					const isCurrentUser = currentUserEmail === email;
					return !isCurrentUser ? (
						<div className="flex items-center space-x-4" onClick={(e) => e.stopPropagation()}>
							<button
								type="button"
								className="border-transparent bg-transparent !min-w-0"
								style={{ cursor: isLoading || isUserDisabled ? undefined : "pointer" }}
								disabled={isLoading || isUserDisabled}
								onClick={() => {
									setSelectedUser(row);
									setModal(true);
								}}
							>
								<ManageAccountsIcon disabled={isUserDisabled} />
							</button>
							<AuthorizationGuard requiredRole="ROOT">
								{() => (
									<>
										<ImpersonateBox mode="Button" email={email ?? ""} />
										<FeedbackModalBox userId={id ?? ""} userName={email ?? ""} />
									</>
								)}
							</AuthorizationGuard>
						</div>
					) : (
						<div className="flex items-center" onClick={(e) => e.stopPropagation()}>
							<AuthorizationGuard requiredRole="ROOT">
								{() => (
									<>
										<FeedbackModalBox userId={id ?? ""} userName={email ?? ""} />
									</>
								)}
							</AuthorizationGuard>
						</div>
					);
				},
			},
		],
		[column, currentUserEmail, rolesOptions, customers, t, userRole, roles, isLoading, enableUser],
	);
	return (
		<>
			{!users ? (
				<IconWalls.ErrorData />
			) : (
				<WallOverlay showOverlay={isLoading} overlay={<IconWalls.LoadingData opaque={false} />}>
					<Dialog
						size="xxlarge"
						show={modal}
						onClose={() => setModal(false)}
						header={`Update user ${
							alwaysArray(selectedUser ?? []).length === 1
								? alwaysArray(selectedUser ?? [])?.[0]?.name ?? alwaysArray(selectedUser ?? [])?.[0]?.email
								: ""
						}`}
					>
						{selectedUser ? (
							<UserBox
								onFinish={() => onFinish(setModal)}
								userData={selectedUser}
								customers={customers}
								isCurrentUser={alwaysArray(selectedUser).some(({ email }) => currentUserEmail === email)}
							/>
						) : (
							<>No User Selected</>
						)}
					</Dialog>

					<div className="mb-4 flex gap-4">
						<FormField label={<>&nbsp;</>}>
							<TextInput
								leftContent={<Icon icon="Search" />}
								value={query.text}
								onChangeText={setQueryText}
								placeholder="Search user info"
								classList="max-w-[384px]"
							/>
						</FormField>
						<FormField label="Filter by role:">
							<Select
								enableSearch
								classList="flex-1"
								options={roleOptions}
								value={query.roles}
								multi
								i18n={{ triggerPlaceholder: () => "Roles" }}
								onChange={setQueryRoles}
								disabled={roleOptions.length === 0}
							/>
						</FormField>
						<FormField label="Filter by customer:">
							<Select
								classList="flex-1"
								options={customerOptions}
								value={query.customers}
								enableSearch
								multi
								i18n={{ triggerPlaceholder: () => "Customers" }}
								onChange={setQueryCustomers}
								disabled={customerOptions.length === 0}
							/>
						</FormField>
					</div>
					<div className="mb-4">
						<BatchActions
							total={filtered.length}
							selected={multiSelectCtx.data.selection.size}
							actions={[
								{
									label: "Edit services",
									onClick: () => {
										setSelectedUser(users.filter((u) => multiSelectCtx.data.selection.has(u.id ?? "")));
										setModal(true);
									},
								},
							]}
						/>
					</div>
					<Table
						orderBy={defaultUsersTableOrderBy}
						columns={columns}
						onRowClick={(row) => toggle(row.id ?? "")}
						rows={filtered}
						visibleRows={Math.min(15, users.length)}
						noDataText={t("SETTING_PANEL.USERS_EMPTY")}
						rowClassList={rowClassList}
						rowKey={(x) => x.id ?? ""}
						enableVirtualScroll
					/>
				</WallOverlay>
			)}
		</>
	);
};

const defaultUsersTableOrderBy: OrderBy[] = [{ columnName: "email", direction: "asc" }];

const CustomersList = (props: {
	customers: CustomerModel[];
	onFinish: (callback: React.Dispatch<React.SetStateAction<boolean>>) => Promise<void>;
}) => {
	const { customers, onFinish } = props;
	const { t } = useTranslation();
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [customer, setCustomer] = useState<CustomerModel | undefined>(undefined);
	const customerApi = useApiGen(CustomerControllerApiFactory);

	const { query, filtered, setQuery } = useSearchable({
		collection: customers,
		matchFn: (row, q) => {
			const stringifiedRow = JSON.stringify(row).toLowerCase();
			return stringifiedRow.includes(q.toLowerCase());
		},
	});

	const onClickModal = (c: CustomerModel) => {
		setCustomer(c);
		setIsModalOpen(true);
	};

	const changeCustomerStatus = React.useCallback(
		async (c: CustomerModel): Promise<void> => {
			setIsLoading(true);
			try {
				await customerApi.updateCustomer1(c);
				await onFinish(setIsModalOpen);
				setIsLoading(false);
			} catch (error) {
				reportPlatformError(error, "ERROR", "advanced-settings", `to update customer "${c.id}"`);
				platformToast({
					children: t("SETTING_PANEL.CREATE_CUSTOMER_ERROR"),
					severity: "error",
					icon: "Top-menu-user",
				});
			}
		},
		[customerApi, t, onFinish],
	);

	const columns = useMemo<Array<TableColumn<CustomerModel>>>(
		() => [
			{
				sortFn: builtInSortFnFor("id"),
				name: "id",
				header: "ID",
				content: (row) => row.id,
			},
			{
				sortFn: (a, b) => builtInSort(a.name?.toLowerCase(), b.name?.toLowerCase()),
				name: "name",
				header: "Name",
				content: (row) => row.name ?? "-",
			},
			{
				sortFn: builtInSortFnFor("maxUsers"),
				name: "maxUsers",
				header: "Max Users",
				cellClassList: "tabular-nums",
				content: (row) => row.maxUsers,
			},
			{
				sortFn: builtInSortFnFor("maxPortfolios"),
				name: "maxPortfolios",
				header: "Max Portfolios",
				cellClassList: "tabular-nums",
				content: (row) => row.maxPortfolios,
			},
			{
				// TODO: uncomment once the API is ready
				// sortFn: builtInSortFnFor("commentsPerMonth"),
				name: "commentsPerMonth",
				header: t("SETTING_PANEL.COMMENTS_PER_MONTH"),
				cellClassList: "tabular-nums",
				// TODO: uncomment once the API is ready
				// content: (row) => row.commentsPerMonth,
				content: () => 864,
			},
			{
				sortFn: builtInSortFnFor("tokenDuration"),
				name: "tokenDuration",
				header: "expirations",
				cellClassList: "tabular-nums",
				content: (row) => row.tokenDuration,
			},
			{
				sortFn: builtInSortFnFor("enabled"),
				name: "enabled",
				header: "Enabled",
				content: (row) => {
					return (
						<Checkbox
							switchType="switch"
							checked={row.enabled ?? false}
							onChange={unpromisify(() =>
								changeCustomerStatus({
									...row,
									enabled: !row.enabled,
								} as CustomerModel),
							)}
						/>
					);
				},
			},
			{
				header: "Actions",
				content: (row) => {
					return (
						<button type="button" onClick={() => onClickModal(row as CustomerModel)}>
							<ManageAccountsIcon />
						</button>
					);
				},
			},
		],
		[changeCustomerStatus, t],
	);

	return (
		<>
			{!customers ? (
				<IconWalls.ErrorData />
			) : (
				<WallOverlay showOverlay={isLoading} overlay={<IconWalls.LoadingData opaque={false} />}>
					<Dialog
						size="xxlarge"
						show={isModalOpen}
						onClose={() => setIsModalOpen(false)}
						header={`Customer update ${customer?.name ?? customer?.email ?? ""}`}
					>
						{customer ? (
							<CustomerBox onFinish={() => onFinish(setIsModalOpen)} customerData={customer} />
						) : (
							<>No User Selected</>
						)}
					</Dialog>
					<div className="mb-4">
						<TextInput
							leftContent={<Icon icon="Search" />}
							value={query}
							onChangeText={setQuery}
							placeholder="Search customer info"
							classList="max-w-[384px]"
						/>
					</div>
					<Table
						orderBy={defaultCustomerListOrderBy}
						columns={columns}
						rows={filtered}
						noDataText={t("SETTING_PANEL.CUSTOMERS_EMPTY")}
						visibleRows={Math.min(15, filtered.length)}
						rowKey={(x) => x.id ?? ""}
						enableVirtualScroll
					/>
				</WallOverlay>
			)}
		</>
	);
};

const defaultCustomerListOrderBy: OrderBy[] = [{ columnName: "id", direction: "asc" }];

function UserSettingsPanel(): JSX.Element {
	const { t } = useTranslation();
	const userData = useUserValue(); //TODO Verify if we need this
	const userApi = useApiGen(UserControllerApiFactory);
	const feedbacksV2Api = useApiGen(FeedbacksControllerApiFactory);
	const [tabIndex, setTabIndex] = useState(0);
	const [modalUser, setModalUser] = useState(false);
	const [modalUsers, setModalUsers] = useState(false);
	const [modalCustomers, setModalCustomers] = useState(false);
	const [modalExport, setModalExport] = useState(false);
	const [disablePanelLoader, setPanelLoader] = useState(true);

	const ROLES: TRole = {
		GUEST: 1,
		MANAGER: 2,
		ADMIN: 3,
		ROOT: 4,
	};

	const sanitaizeUserRole = useMemo(() => {
		return userData.roles?.[0] ?? "GUEST";
	}, [userData]);

	const userRole = ROLES[sanitaizeUserRole.replace(/[[\]']+/g, "")];

	// TODO: look or better implementation for
	// 1. handle setPanelLoader calls
	// 2. add z-index loader that doesn't replace table content
	// Custom component already exist for loading overlay
	const {
		isLoading,
		isError,
		isFetching,
		refetch,
		data: UserPanelData,
	} = useQueryNoRefetch(["queryUserPanelData"], {
		queryFn: async () => {
			const [listAllUsers, listAllCustomer, currentUser] = await Promise.all([
				userApi.listAllUsers(),
				userApi.listAllCustomer(),
				userApi.infoUser(),
			]);

			return { listAllUsers, listAllCustomer, currentUser };
		},
		onSuccess(_data) {
			if (disablePanelLoader) {
				setPanelLoader(false);
			}
		},
		onError(_err) {
			if (!disablePanelLoader) {
				setPanelLoader(true);
			}
		},
	});

	const onFinish = React.useCallback(
		async (callback: React.Dispatch<React.SetStateAction<boolean>>) => {
			await refetch({ throwOnError: true });
			callback(false);
		},
		[refetch],
	);

	const { currentUser, listAllCustomer, listAllUsers } = UserPanelData ?? {};

	const memoCurrentUser = useMemo(() => currentUser, [currentUser]);

	const memoListAllCustomer = useMemo(() => listAllCustomer, [listAllCustomer]);

	const memoListAllUsers = useMemo(() => listAllUsers, [listAllUsers]);

	const { control, formState, handleSubmit, reset } = useForm({
		defaultValues: { fromDate: "", toDate: "" },
		resolver: zodResolver(
			z.object({
				fromDate: z.string().nonempty(t("REQUIRED_FIELD")),
				toDate: z.string().nonempty(t("REQUIRED_FIELD")),
			}),
		),
	});

	const handleFeedbackDownload = React.useCallback(
		async ({ fromDate, toDate, userId }: { fromDate: string; toDate: string; userId?: string }) => {
			try {
				const formattedDates = { fromDate: String(new Date(fromDate)), toDate: String(new Date(toDate)) };
				const response = await feedbacksV2Api.exportFeedbacks(formattedDates.fromDate, formattedDates.toDate, userId, {
					responseType: "blob",
				});

				downloadContentDisposition(response);
			} catch (error) {
				platformToast({
					children: "Failed to download",
					severity: "error",
					icon: "Dowload",
				});
				console.error(error);
			} finally {
				setModalExport(false);
				reset();
			}
		},
		[feedbacksV2Api, reset],
	);

	return (
		<>
			<PageHeader
				title="Global Settings" // TODO: translate
			/>
			<AuthorizationGuard requiredRole="ROOT" placeholder={<>{t("NOT_AUTHORIZED")}</>}>
				{() => (
					<>
						{disablePanelLoader && (isLoading || isFetching || isError || !UserPanelData) ? (
							isError ? (
								<IconWalls.ErrorData />
							) : (
								<IconWalls.Loader />
							)
						) : (
							<>
								<AuthorizationGuard requiredRole="ROOT">
									{() => <ImpersonateBox mode="Box" users={listAllUsers?.data} />}
								</AuthorizationGuard>
								{/* Modals */}
								<Dialog
									size="xxlarge"
									show={modalUser}
									onClose={() => setModalUser(false)}
									header={`User update ${userData.name ?? userData.email}`}
								>
									<UserBox
										onFinish={() => onFinish(setModalUser)}
										userData={memoCurrentUser?.data ?? {}}
										customers={memoListAllCustomer?.data ?? []}
										isCurrentUser={userData.email === memoCurrentUser?.data.email}
									/>
								</Dialog>
								<Dialog size="xxlarge" show={modalUsers} onClose={() => setModalUsers(false)} header="Create User">
									<UserBox onFinish={() => onFinish(setModalUsers)} customers={memoListAllCustomer?.data ?? []} />
								</Dialog>
								<Dialog
									size="xxlarge"
									show={modalCustomers}
									onClose={() => setModalCustomers(false)}
									header="Create Customer"
								>
									<CustomerBox onFinish={() => onFinish(setModalCustomers)} />
								</Dialog>

								<Dialog
									size="xxlarge"
									show={modalExport}
									onClose={() => setModalExport(false)}
									header="Feedback Export"
								>
									<Form
										noValidate
										onSubmitAsync={() =>
											handleSubmit((x) =>
												handleFeedbackDownload({
													fromDate: x.fromDate,
													toDate: x.toDate,
													userId: memoCurrentUser?.data.id,
												}),
											)()
										}
										classList="space-y-4 w-full"
									>
										<div className="grid grid-cols-2 gap-4">
											<FormField label="From" classList="w-full">
												{({ id, invalid }) => (
													<FormController
														name="fromDate"
														control={control}
														render={({ field: { onChange, ref, ...controllerProps } }) => (
															<TextInput
																required
																placeholder="From"
																type="date"
																id={id}
																invalid={invalid}
																onChangeText={onChange}
																innerRef={ref}
																{...controllerProps}
															/>
														)}
													/>
												)}
											</FormField>
											<FormField label="To" classList="w-full">
												{({ id, invalid }) => (
													<FormController
														name="toDate"
														control={control}
														render={({ field: { onChange, ref, ...controllerProps } }) => (
															<TextInput
																required
																placeholder="To"
																type="date"
																id={id}
																invalid={invalid}
																onChangeText={onChange}
																innerRef={ref}
																{...controllerProps}
															/>
														)}
													/>
												)}
											</FormField>
										</div>
										<div className="flex justify-end">
											<SubmitButton disabled={!formState.isValid}>Download</SubmitButton>
										</div>
									</Form>
								</Dialog>
								{/* Modals */}
								<div className="grid grid-cols-3 gap-4">
									<div
										className="flex flex-col justify-between"
										style={{
											borderLeft: "8px solid #4cb09c",
											background: "white",
											padding: 16,
											marginBottom: 16,
											borderRadius: "0 6px 6px 0",
										}}
									>
										<div>
											<div className="flex items-center mb-2">
												<Icon icon="Top-menu-user" size={30} />
												&nbsp;
												<Text type="Body/XL/Bold">{t("SETTING_PANEL.USER")}</Text>
											</div>

											<Text type="Title/S" style={{ display: "block", marginBottom: "24px", fontSize: "24px" }}>
												Welcome {userData.email}
											</Text>
										</div>

										<div>
											<Button palette="primary" onClick={() => setModalUser(true)}>
												{t("SETTING_PANEL.UPDATE_USER")}
											</Button>
										</div>
									</div>
									<div
										className="flex flex-col justify-between"
										style={{
											borderLeft: "8px solid #4cb09c",
											background: "white",
											padding: 16,
											marginBottom: 16,
											borderRadius: "0 6px 6px 0",
										}}
									>
										<div>
											<div className="flex items-center mb-2">
												<div className="relative w-[20px] h-[20px]">
													<Icon icon="Top-menu-user" size={12} classList="absolute -left-1 top-0" />
													<Icon icon="Top-menu-user" size={12} classList="absolute left-1 top-2" />
												</div>
												&nbsp;
												<Text type="Body/XL/Bold">{t("SETTING_PANEL.USERS")}</Text>
											</div>

											<Text type="Title/S" style={{ display: "block", marginBottom: "24px", fontSize: "30px" }}>
												{memoListAllUsers?.data.length ?? 0}
											</Text>
										</div>

										<div>
											<Button palette="primary" onClick={() => setModalUsers(true)}>
												{t("SETTING_PANEL.NEW_USER")}
											</Button>
										</div>
									</div>
									<div
										className="flex flex-col justify-between"
										style={{
											borderLeft: "8px solid #4cb09c",
											background: "white",
											padding: 16,
											marginBottom: 16,
											borderRadius: "0 6px 6px 0",
										}}
									>
										<div>
											<div className="flex items-center mb-2">
												<div className="relative w-[20px] h-[20px]">
													<Icon icon="Top-menu-user" size={12} classList="absolute left-0 top-0" />
													<Icon icon="Top-menu-user" size={12} classList="absolute left-1 top-2" />
													<Icon icon="Top-menu-user" size={12} classList="absolute -left-1 top-2" />
												</div>
												&nbsp;
												<Text type="Body/XL/Bold">{t("SETTING_PANEL.CUSTOMERS")}</Text>
											</div>

											<Text type="Title/S" style={{ display: "block", marginBottom: "24px", fontSize: "30px" }}>
												{memoListAllCustomer?.data.length ?? 0}
											</Text>
										</div>

										<div>
											<Button palette="primary" onClick={() => setModalCustomers(true)}>
												{t("SETTING_PANEL.NEW_CUSTOMER")}
											</Button>
										</div>
									</div>
								</div>

								<div
									style={{
										position: "relative",
										borderLeft: "8px solid #4cb09c",
										background: "white",
										padding: 16,
										marginBottom: 16,
										borderRadius: "0 6px 6px 0",
									}}
								>
									<TabGroup palette="primary" tabIndex={tabIndex} onTabChange={setTabIndex}>
										<Tab title="User List">
											<UsersList
												roles={ROLES}
												userRole={userRole}
												users={memoListAllUsers?.data ?? []}
												customers={memoListAllCustomer?.data ?? []}
												currentUserEmail={userData.email}
												onFinish={onFinish}
											/>
										</Tab>
										<Tab title="Customer List">
											<CustomersList customers={memoListAllCustomer?.data ?? []} onFinish={onFinish} />
										</Tab>
									</TabGroup>
									<div className="absolute right-4 top-4">
										<DropdownMenu
											strategy="fixed"
											trigger={({ innerRef, open, ...forwardProps }) => (
												<button
													className={`flex items-center text-[${themeCSSVars.Button_bg_primary}]`}
													ref={innerRef}
													type="button"
													aria-expanded={open}
													{...forwardProps}
												>
													<Icon icon="Dowload" size={24} />
												</button>
											)}
											actions={[
												{
													group: "Exports",
													children: ({ onClose }) => (
														<DropdownMenuActionButton
															classList="whitespace-nowrap"
															icon="Dowload"
															onClick={() => {
																onClose();
																setModalExport(true);
															}}
														>
															Download Feedbacks
														</DropdownMenuActionButton>
													),
												},
											]}
										/>
									</div>
								</div>
							</>
						)}
					</>
				)}
			</AuthorizationGuard>
		</>
	);
}

export default UserSettingsPanel;

function ManageAccountsIcon({ disabled }: { disabled?: boolean }) {
	return (
		<div className="relative z-0">
			<Icon
				size={24}
				icon="Top-menu-user"
				classList="relative z-0"
				color={disabled ? PaletteColors.LIGHT_GREY : PaletteColors.GREENY_BLUE}
			/>
			<Icon
				size={11}
				icon="Settings"
				classList="absolute z-10 bottom-0 right-0 bg-white rounded-full"
				color={disabled ? PaletteColors.LIGHT_GREY : PaletteColors.GREENY_BLUE}
			/>
		</div>
	);
}

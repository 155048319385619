import type { InvestmentExposureEntry, InvestmentExposureResponseExposureTypeEnum } from "$root/api/api-gen";
import type { ExposureChartData } from "$root/widgets-architecture/widgets/ExposureEvolve";
import { ExposureChart, exposureCategoryInfo } from "$root/widgets-architecture/widgets/ExposureEvolve";
import { ForEach, toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { groupBy, objectMap } from "@mdotm/mdotui/utils";
import { sumArrayLike } from "$root/utils/collections";
import { type PrintableProps } from "$root/components/EvolvedPrint/configuration";
import {
	firstPageHeaderHeight,
	otherPagesHeaderHeight,
	pageFooterHeight,
} from "$root/components/EvolvedPrint/configuration/shared";
import { Card } from "$root/components/EvolvedPrint/components/Card";
import { stableColorGenerator } from "$root/utils/chart/colorGenerator";
import { useLocaleFormatters } from "$root/localization/hooks";
import { useMemo } from "react";
import { Text } from "@mdotm/mdotui/components";
import type { Languages } from "$root/localization/i18n";
import { useTranslation } from "react-i18next";

export type ExposureListItem =
	| {
			type: "title";
			label: string;
			groupName: string;
	  }
	| {
			type: "entry";
			label: string;
			weight: number;
			groupName: string;
			netLong: boolean;
	  }
	| {
			type: "footer";
			label: string;
			weight: number;
			groupName: string;
	  };

export function exposureEntriesToSplittableProps(composition: InvestmentExposureEntry[]): Array<ExposureListItem> {
	const groups: string[] = [];
	for (const { firstQualityLevel } of composition) {
		if (!groups.includes(firstQualityLevel!)) {
			groups.push(firstQualityLevel!);
		}
	}

	return composition.length === 0
		? []
		: Array.from(
				Object.values(
					objectMap(
						groupBy(composition, (entry) => entry.firstQualityLevel!),
						(entries) => [
							{
								type: "title",
								label: entries?.at(0)?.firstQualityLevel ?? "",
								groupName: entries?.at(0)?.firstQualityLevel ?? "",
							} as const,
							...(entries ?? []).map(
								(x) =>
									({
										type: "entry",
										label: x.secondQualityLevel ?? "",
										weight: x.weight!,
										netLong: x.netLong ?? true,
										groupName: entries?.at(0)?.firstQualityLevel ?? "",
									}) as const,
							),
							{
								type: "footer",
								label: "",
								weight: sumArrayLike(entries ?? [], (x) => x.weight!),
								groupName: entries?.at(0)?.firstQualityLevel ?? "",
							} as const,
						],
					),
				),
		  ).flat();
}

export function Exposure(
	props: PrintableProps<
		{
			comparison: Extract<
				InvestmentExposureResponseExposureTypeEnum,
				| "MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS"
				| "MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY"
				| "MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY"
				| "MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY"
				| "MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY"
				| "MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY"
				| "TAG"
				| "CURRENCY"
			>;
			composition: Array<InvestmentExposureEntry>;
			language?: Languages;
		},
		Array<ExposureListItem>
	>,
): JSX.Element {
	const { language = "en" } = props;
	const { t } = useTranslation(undefined, { lng: language });

	const chartData = useMemo(() => {
		const data = props.composition.reduce(
			(acc, cur) => {
				if (!acc[cur.firstQualityLevel!]) {
					acc[cur.firstQualityLevel!] = {
						label: cur.firstQualityLevel!,
						weight: 0,
						drillDown: [],
						groupName: cur.firstQualityLevel!,
					};
				}
				acc[cur.firstQualityLevel!]!.weight += cur.weight!;

				acc[cur.firstQualityLevel!]!.drillDown.push({
					label: cur.secondQualityLevel!,
					weight: cur.weight!,
					netLong: cur.netLong ?? true,
				});
				return acc;
			},
			{} as Record<string, ExposureChartData[number]>,
		);

		return Object.values(data);
	}, [props.composition]);

	if (props.list.length === 0) {
		return <></>;
	}
	return (
		<>
			{props.firstRender && (
				<Card
					style={{ marginBottom: 0 }}
					title={`${t("REPORT_BUILDER.EXPOSURE.TITLE")} - ${t(`REPORT_BUILDER.EXPOSURE.OPTIONS.${props.comparison}`)}`}
				>
					<div className="border-t border-b-2 pt-5 mb-4" style={{ borderColor: themeCSSVars.palette_N300 }}>
						<ExposureChart animated={false} customHeight={`${exposureChartHeight}px`} data={chartData} />
					</div>
				</Card>
			)}
			<ForEach collection={props.list}>
				{({ item: subList }) => (
					<CompositionList
						availableHeight={exposureAvailableHeightForItemSlotsByChartHeight(
							props.firstRender ? exposureChartSectionHeight(exposureChartHeight) : 0,
							"portrait",
						)}
						list={subList}
					/>
				)}
			</ForEach>
		</>
	);
}

export function CompositionList({
	availableHeight,
	list,
}: {
	availableHeight: number;
	list: Array<ExposureListItem>;
}): JSX.Element {
	const { formatNumber } = useLocaleFormatters();

	return (
		<div
			className="columns-3 px-12"
			style={{
				columnFill: "auto",
				height: availableHeight, // Math.ceil(list.length / exposureCompositionListItemsPerRow) * exposureCompositionListRowHeight,
			}}
		>
			{list.map((row, i) => (
				<div
					className="flex"
					style={{
						marginBottom: row.type === "footer" ? exposureCompositionListFooterRowMarginBottom : undefined,
					}}
					key={`exposure-singleColumn-item-key-${i}`}
				>
					<div className="pr-2 rounded-lg shrink-0">
						<div
							className={toClassName({
								"w-1 h-full": true,
								"rounded-t-full": row.type === "title",
								"rounded-b-full": row.type === "footer",
							})}
							style={{ backgroundColor: stableColorGenerator(row.groupName) }}
						/>
					</div>

					<div
						className={toClassName({
							"flex items-center justify-between flex-1 gap-2 overflow-hidden": true,
							"border-b": row.type !== "footer",
							uppercase: row.type === "title" || row.type === "footer",
						})}
						style={{ height: exposureCompositionListRowHeight, borderColor: themeCSSVars.palette_N200 }}
					>
						<Text
							as="div"
							classList="line-clamp-2"
							color={row.type === "title" ? themeCSSVars.palette_N500 : undefined}
							type={row.type === "title" ? "Body/S/Bold" : "Body/S/Book"}
						>
							{row.label}
						</Text>

						<div>
							{row.type === "entry" && row.netLong === false && (
								<>
									&nbsp;-&nbsp;[&nbsp;
									<svg
										className="inline w-[8px] mb-1"
										width="8"
										height="8"
										viewBox="0 0 8 8"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<rect opacity="0.3" width="8" height="8" rx="4" fill={stableColorGenerator(row.groupName)} />
									</svg>
									&nbsp;
									<span className="truncate">Net Short</span>&nbsp;]
								</>
							)}
							{(row.type === "entry" || row.type === "footer") && (
								<Text type={row.type === "footer" ? "Body/S/Bold" : "Body/S/Book"}>{formatNumber(row.weight)}%</Text>
							)}
						</div>
					</div>
				</div>
			))}
		</div>
	);
}

export const exposureCompositionListRowHeight = 40;
export const exposureCompositionListFooterRowMarginBottom = 16;
export const exposureCompositionListItemsPerRow = 3;
export const exposureChartHeight = 242;
export const exposureChartDecorationHeight = 119;
export const exposureChartSectionHeight = (baseChartHeight: number): number =>
	baseChartHeight + exposureChartDecorationHeight;
const exposurePortraitPageHeight = 1122 - Math.max(firstPageHeaderHeight, otherPagesHeaderHeight) - pageFooterHeight;
const exposureLandscapePageHeight = 320; // TODO: check

export function chunkExposureListItems(
	cells: Array<ExposureListItem>,
	baseChartHeight: number,
): Array<Array<ExposureListItem>> {
	if (cells.length === 0) {
		return [];
	}

	const chunks: Array<typeof cells> = [[]];

	let i = 0;
	let c = 0;
	let remainingHeight = exposureAvailableHeightForItemSlotsByChartHeight(
		exposureChartSectionHeight(baseChartHeight),
		"portrait",
	);
	let isFirstPage = true;
	while (i < cells.length) {
		const cell = cells[i];
		remainingHeight -= exposureCompositionListRowHeight;
		if (cell.type === "footer") {
			remainingHeight -= exposureCompositionListFooterRowMarginBottom;
		}
		if (remainingHeight < 0) {
			remainingHeight = exposureAvailableHeightForItemSlotsByChartHeight(
				isFirstPage ? exposureChartSectionHeight(baseChartHeight) : 0,
				"portrait",
			);
			c++;
			if (c === exposureCompositionListItemsPerRow) {
				isFirstPage = false;
				chunks.push([]);
				c = 0;
			}
		} else {
			chunks[chunks.length - 1].push(cell);
			i++;
		}
	}
	return chunks;
}

export function exposureAvailableHeightForItemSlotsByChartHeight(
	chartSectionHeight: number,
	layout: "portrait" | "landscape",
): number {
	return Math.max(
		0,
		(layout === "portrait" ? exposurePortraitPageHeight : exposureLandscapePageHeight) - chartSectionHeight,
	);
}
